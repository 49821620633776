import React from 'react'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Marquee from 'react-marquee-master';
const CurrencyConverter = () => {
    var mArray2: any = [];
    var item: any = ["Verified sellers ","Made in Turkey ","Fast Shipping ","Wholesale ","Unique Products ","Meet Manufacturers ","High-Margin Products "];
    item.map((key, i) => (
        mArray2.push(<>
            <div className="currencyitems">
                <div className="currencyItem"> <span className={(i+1) % 3 === 1 ? "currency_count" :(i+1) % 3 === 2 ?  "currency_count  light_pink":"currency_count light_yelw"}>{key}</span></div>
            </div></>)
    ))
    return (
        <>
            <div className="currencyStrip">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="currencyStrip_inner">
                                <Marquee marqueeItems={mArray2} direction="left"  />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CurrencyConverter;