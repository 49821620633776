import styled from "styled-components";

type ContainerProps = {
    width: string,
    height: string,
    mt?: string,
    mb?: string,
    ml?: string,
    mr?: string,
}

export const Container = styled.div<ContainerProps>`
    width: ${props => props.width};
    height: ${props => props.height};
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    `
    
    // margin-top : ${props => props.mt ? props.mt : '100px'};
    // margin-bottom : ${props => props.mb ? props.mb : '100px'};