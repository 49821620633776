import { Button, Card, CircularProgress } from "@material-ui/core";
import React from "react";
import { useQuery, gql } from '@apollo/client';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
const PRODUCTS = gql`
query {
    successStories(first: 40) {
      edges {
        node {
          id
          title
          description
          name
          image{
            url
            alt
          }
          createdAt
          companyName
          location
          slug
          tags
        }
      }
    }                       
}
`
const SuccessStory = (props: any) => {
    const handleDragStart = (e: { preventDefault: () => any; }) => e.preventDefault();
    const { loading, error, data } = useQuery(PRODUCTS);
    if (loading) return <div className="loading_seccestion"><CircularProgress /></div>
    if (error) return <p>Error: Something is wrong! </p>
    const values = data?.successStories?.edges;
    const options = {
        autoplay: true,
        responsiveClass: true,
        dots: false,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 3,
            },
            1200: {
                items: 4,

            }
        },
    };
    return (
        <>
            <section>
                <div className="read_more_story sec_space">
                    <div className="container">
                        <div className="section_heading">
                            <h2>People of action</h2>
                        </div>
                        <div className="row p-0">
                            <div className="col-md-12 p-0">
                                <div className="strory_slider">
                                    <OwlCarousel className='owl-theme' {...options} loop margin={10} nav >
                                        {values?.map((res: any, index: any) =>
                                            <div className="carousel-inner row w-100 mx-auto" role="listbox">
                                                <div className="carousel-item">
                                                    <div className="inner">
                                                        {index % 2 === 0 &&
                                                            <div className="storie_contant second">
                                                                <p>{values[index]?.node?.title}</p>
                                                                <a href="javascript:void(0)"><i className="fa fa-arrow-right" aria-hidden="true"></i></a>
                                                            </div>
                                                        }
                                                        {index % 2 != 0 &&
                                                            <div className="storie_btn">
                                                                {values[index]?.node?.tags?.map((tag: any, index: any) =>
                                                                    <a href="javascript:void(0)">{tag} </a>
                                                                )}
                                                            </div>
                                                        }
                                                        <div className='images_hadoweffect' style={{ margin: "15px 0" }}>
                                                            <img src={values[index]?.node?.image?.url} className="w-100" />
                                                        </div>
                                                        {index % 2 === 0 &&
                                                            <div className="storie_btn">
                                                                {values[index]?.node?.tags?.map((tag: any, index: any) =>
                                                                    <a href="javascript:void(0)">{tag} </a>
                                                                )}
                                                            </div>
                                                        }
                                                        {index % 2 != 0 &&
                                                            <div className="storie_contant second bottom_tx">
                                                                <p>{values[index]?.node?.title}</p>
                                                                <a href="javascript:void(0)"><i className="fa fa-arrow-right" aria-hidden="true"></i></a>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default SuccessStory;