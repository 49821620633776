import { Container } from '@material-ui/core';
import React, { useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import SwiperCore, {
    Mousewheel, Pagination, Autoplay,
} from 'swiper/core';
SwiperCore.use([Mousewheel, Pagination, Autoplay]);
const WhyKoytola = () => {
    return (
        <>
            <section className="micro_trade micro_trade-mobile d-sm-none d-xs-block">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="micro_trade_contnt">
                                <h3>KOYTOLA IS THE FIRST <span>MICRO-TRADE ORIENTED WHOLESALE MARKETPLACE</span></h3>
                                <p><strong>What is Micro-Trade?</strong> When your total  purchase is under <strong>15.000</strong> Euro and under <strong>300</strong> KG we call that micro! </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="why_koytola sec_space">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                        <div className="why_heading d-sm-none d-xs-block">
                                <a href="javascript:void(0)"><b>WHY TURKEY?</b></a>
                            </div>
                            <div className="why_heading d-xs-none">
                                <a href="javascript:void(0)" className="multi_textbtn"><b>WHY</b><span> TURKEY <br /> KOYTOLA</span></a>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                       <div className="col-xs-4">
                           <div className="inner_headi">
                               <h5 className='d-xs-none'>Buyers’ dream came true.. </h5>
                               <h2><span>HIGH QUALITY </span> <br />
                                products<br />
                                <span>low</span><br />
                                prices  
                               </h2>
                           </div>
                       </div>
                       <div className="col-md-2 d-xs-none"></div>
                       <div className="col-md-6 col-xs-8">
                            <h5 className="trky_koytola">TURKEY</h5>
                           <div className="row dream_main">
                               <div className="col-md-5 col-xs-6">
                                   <div className="dream_box">
                                        <h5>Manufacturing Gem For <span>Top Brands</span></h5>
                                   </div>
                               </div> 
                               <div className="col-md-2 d-xs-none"></div>
                               <div className="col-md-5 col-xs-6">
                                   <div className="dream_box">
                                        <h5><span>Government</span> support on exporters </h5>
                                   </div>
                               </div> 
                               <div className="col-md-5 col-xs-6">
                                   <div className="dream_box m-0">
                                        <h5>Location, location and <span>location</span></h5>
                                   </div>
                               </div>
                               <div className="col-md-2 d-xs-none"></div> 
                               <div className="col-md-5 col-xs-6">
                                   <div className="dream_box m-0">
                                        <h5><span>+200 Billion USD</span> export value</h5>
                                   </div>
                               </div>   
                           </div>
                       </div>
                    </div>
                </div>
            </section>
            <section className="micro_trade d-xs-none">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="micro_trade_contnt">
                                <h3>KOYTOLA IS THE FIRST <span>MICRO-TRADE</span> ORIENTED WHOLESALE MARKETPLACE</h3>
                                <p>What is Micro-Trade? When your total  purchase is under 15.000 Euro and under 300 KG we call that micro! </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default WhyKoytola;