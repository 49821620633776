import SuccessStory from './SuccessStory';
import React, { useState } from 'react';
const JoinKoytola = () => {
    const [career, setcareer] = useState(false);
    const [read, setRead] = useState(false);
    const [media, setmedia] = useState(true);
    const [investor, setinvestor] = useState(false);
    const openMedia = () => {
        setmedia(true);
        setcareer(false);
        setinvestor(false);
    };
    const openCareer = () => {
        setcareer(true);
        setmedia(false);
        setinvestor(false);
    };
    const openInvestor = () => {
        setinvestor(true);
        setmedia(false);
        setcareer(false);
    };
    return (
        <>
            <section className="sustainability" >
                <div className="container">
                    <div className="row" >
                        <div className="col-md-12">
                            <h2> Koytola - Online Wholesale Marketplace </h2>
                            <p>
                                Koytola.com is Turkey's first micro export-oriented platform in other words it's an online wholesale platform.
                                Here, we support direct trade between small manufacturers in Turkey and retailers around the globe. There are lots of hand-made items which are unlabelled and ready for your unique brands. {!read && <span onClick={() => {
                                    setRead(true)
                                }}>Read More</span>}
                            </p>
                            {read &&
                                <>
                                    <p>
                                        If you have a ready design, you can directly contact any of the manufacturers on Koytola.com and they can create that custom product only for you. Due to the current devaluation of the Turkish currency, it's easy to find products under 1 dollar and most of them have great quality. If you are thinking of creating your clothing brands such as a T-shirt line or sweatshirt brand, here is a great platform where you can find the small textile manufacturers, and always as a buyer, you can ask if they can do private label products or white label products for your brand.
                                    </p>
                                    <i><b>Koytola is the best online wholesale marketplace connecting independent retailers and brands around the world.</b></i>
                                    <p>
                                        One of the advantages of working with small manufacturers is that they do not ask you to order big amounts of products. On the platform, you will meet lots of experienced wholesalers as well. Sometimes ordering 1-10 custom products will be workable according to manufacturers. Minimum order quantity is always a struggle for new buyers since it's a new business, entrepreneurs do not want to put all their capital into products because they know that there will be marketing expenses and there should be a budget for that. Finding the right product to sell is the most important thing in commerce. Here on Koytola under the gift category, there are niche items that can not be easily found on any other platform. If you have a grocery store you can directly buy bulk Turkish apricot,  Turkish coffee, carob pods, baklava, lokum (Turkish delight) direct from local producers. Also, you can check different varieties of local food items, handmade recipes from women-owned local shops in different regions of Turkey which are offering organic, vegan, heirloom, gluten-free products. If you have a restaurant, Koytola can be a great product sourcing platform to find and buy wholesale items directly to your kitchen. And buy bulk save more.
                                    </p>
                                    <p>
                                        Top product categories are beauty and cosmetics products, baby clothes, home textile products, jewelry and accessories, women and men clothes, uniform workwear, bag and suitcases, shoes. You can find authentic products which belong to middle eastern culture, some handmade authentic rugs, outdoor rugs, contemporary rugs, carpets, kilims, ottoman clothes, Turkish coffee brewers, souvenirs  If you are looking for products to re-sell on other online marketplaces you can easily find profitable items.
                                    </p>
                                    <p>
                                        Koytola offers free sourcing if the demanded product is not listed on the platform. Buying bulk always comes with advantages like having a competitive price is very crucial but for that, you need to have a reliable supplier or manufacturer who can ship you top quality products on time. Another important part is international cargo rates must be competitive. We all know that E-commerce is booming all around the world but to become successful in selling on marketplaces you need to have a good courier partner. Tailor-made products, customizable products can keep your profile on high rankings.
                                        Koytola.com is Turkey's first micro export-oriented platform. Here, we support direct trade between small manufacturers, suppliers, and wholesalers in Turkey and retailers around the globe.
                                    </p>
                                    <p>Affordable Wedding dresses, prom dresses, decors, and other detail-oriented products are available on koytola.
                                    </p>
                                    <p>
                                        How to pay for items on the wholesale marketplace virtual enterprise?
                                    </p>
                                    <p>
                                        You need to trust when you are paying overseas suppliers, some marketplaces solve this by verifying sellers. If you like a product on a wholesale marketplace like Koytola, you need to consider that Paypal is not working in Turkey. However, you can transfer the amount by Western union or direct wire transfer.
                                    </p>
                                    <p>
                                        What is marketplace insurance?
                                    </p>
                                    <p>
                                        Marketplace insurance is highly important when you buy from overseas. Since you do not know the seller, there is a risk of not receiving the cargo,  getting an empty box, or full of wrong items. In that case, many marketplaces are offering insurance to buyers at very affordable amounts. If you are a wholesale buyer, you should consider buying it.
                                    </p>
                                    <p>
                                        How to sell on the marketplace?
                                    </p>
                                    <p>
                                        Selling on marketplaces can be very profitable if you follow some rules. Take a look at 4P's  Product, Price, Place, Promotion. Your product should be at the right place at the right price so that you can beat the competition. For selling a product at a profitable margin, your buying price should be very competitive, try to negotiate with your supplier.
                                        <span onClick={() => {
                                            setRead(false)
                                        }}> Read Less</span>
                                    </p>
                                </>}
                        </div>
                    </div>
                </div>
            </section>
            <SuccessStory />
            <section className="interested">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xs-6">
                            <div className="list_menu">
                                <ul>
                                    <li><a onClick={openMedia} className={media ? "font-effect-outline inmecar active" : "font-effect-outline inmecar"}>media</a></li>
                                    <li><a onClick={openCareer} className={career ? "font-effect-outline inmecar active" : "font-effect-outline inmecar"}>career</a></li>
                                    <li><a onClick={openInvestor} className={investor ? "font-effect-outline inmecar active" : "font-effect-outline inmecar"} >investors</a></li>
                                </ul>
                            </div>
                        </div>
                        {investor &&
                            <div className="col-xs-6">
                                <div className="interested_contant">
                                    <h4>Grow</h4>
                                    <span> You can send an email to export@koytola.com for getting more information on  investment opportunities. </span>
                                </div>
                            </div>}
                        {career &&
                            <div className="col-xs-6">
                                <div className="interested_contant">
                                    <h4>  TeamMate </h4>
                                    <span>Koytola is a fast-growing start-up, We always require talented team players.
                                        kubray@koytola.com is waiting for your CV.</span>
                                </div>
                            </div>}
                        {media &&
                            <div className="col-xs-6">
                                <div className="interested_contant">
                                    <h4>Collabs </h4>
                                    <span>Sure we’d like that! Feel free to send an email to export@koytola.com</span>
                                </div>
                            </div>}
                    </div>
                </div>
            </section>
        </>
    )
}
export default JoinKoytola;