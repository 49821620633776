import React from 'react'
const BlogShow = () => {
    const oldurl = window.location.href;
    const redirect = (value) => {
        window.location.href = value
    }
    if (oldurl) {
        if (oldurl === "https://koytola.com/blog-details/nis-urun-nedir-nasil-bulunur") {
            redirect("https://blog.koytola.com/nis-urun-nedir-nasil-bulunur/")
        } else if (oldurl === "https://koytola.com/blog-details/international-marketplaces-and-cargo-shipments") {
            redirect("https://blog.koytola.com/international-marketplaces-and-cargo-shipments/")
        } else if (oldurl === "https://koytola.com/blog-details/Yurt-disi-pazaryerleri-ve-kargo-gonderimleri") {
            redirect("https://blog.koytola.com/yurt-disi-pazaryerleri-ve-kargo-gonderimleri/")
        } else if (oldurl === "https://koytola.com/blog-details/encontrar-fabricanet-de-turquia") {
            redirect("https://blog.koytola.com/pregunta-como-encontrar-fabricantes-de-turquia/")
        } else if (oldurl === "https://koytola.com/blog-details/vergi-numarasi-ile-nasil-ihracat-yapilir") {
            redirect("https://blog.koytola.com/vergi-numarasi-ile-nasil-ihracat-yapilir/")
        } else if (oldurl === "https://koytola.com/blog-details/yurt-disi-distributor-bayi-nasil-bulunur") {
            redirect("https://blog.koytola.com/yurt-disinda-distributor-bayi-nasil-bulunur/")
        } else if (oldurl === "https://koytola.com/blog-details/abd-mikro-ihracat-nasil-yapilir") {
            redirect("https://blog.koytola.com/amerika-birlesik-devletlerine-mikro-ihracat-nasil-yapilir/")
        } else if (oldurl === "https://koytola.com/blog-details/mikro-ihracat-kdv-iadesi-nasil-alinir") {
            redirect("https://blog.koytola.com/mikro-ihracat-faaliyetlerinde-kdv-iadesi-nasil-alinir/")
        } else if (oldurl === "https://koytola.com/blog-details/manufacturers-in-turkey") {
            redirect("https://blog.koytola.com/manufacturers-in-turkey/")
        } else if (oldurl === "https://koytola.com/blog-details/how-to-find-international-suppliers-online") {
            redirect("https://blog.koytola.com/how-to-find-international-suppliers-online/")
        } else if (oldurl === "https://koytola.com/blog-details/toptan-mikro-ihracat-nasil-yapilir") {
            redirect("https://blog.koytola.com/toptan-mikro-ihracat-nasil-yapilir/")
        } else if (oldurl === "https://koytola.com/blog-details/mini-import-from-turkey") {
            redirect("https://blog.koytola.com/mini-importation-from-turkey/")
        } else if (oldurl === "https://koytola.com/blog-details/how-to-find-reliable-supplier") {
            redirect("https://blog.koytola.com/how-to-find-a-reliable-supplier/")
        } else if (oldurl === "https://koytola.com/blog-details/how-to-create-t-shirt-brand") {
            redirect("https://blog.koytola.com/how-to-create-a-t-shirt-brand/")
        } else if (oldurl === "https://koytola.com/blog-details/how-to-find-suppliers-from-turkey") {
            redirect("https://blog.koytola.com/how-to-find-suppliers-from-turkey/")
        } else if (oldurl === "https://koytola.com/blog-details/buying-bulk-turkish-apricots") {
            redirect("https://blog.koytola.com/buying-bulk-turkish-apricots/")
        } else if (oldurl === "https://koytola.com/blog-details/buying-turkish-coffee-direct-from-manufacturer") {
            redirect("https://blog.koytola.com/buying-turkish-coffee-direct-from-manufacturer/")
        } else if (oldurl === "https://koytola.com/blog-details/elektronik-ticaret-gumruk-beyannamesi-etgb") {
            redirect("https://blog.koytola.com/etgb-elektronik-ticaret-gumruk-beyannamesi-nedir-nasil-alinir/")
        } else if (oldurl === "https://koytola.com/blog-details/sirketsiz-ihracat") {
            redirect("https://blog.koytola.com/esnaf-muafiyet-belgesi-nedir-sirketsiz-ihracat-yapilir-mi/")
        } else if (oldurl === "https://koytola.com/blog-details/mikro-ihracat-fatura-nasil-kesilir") {
            redirect("https://blog.koytola.com/mikro-ihracat-faturasi-nasil-kesilir/")
        } else if (oldurl === "https://koytola.com/blog-details/what-to-import-from-turkey") {
            redirect("https://blog.koytola.com/what-to-import-from-turkey-top-product-categories-to-purchase-in-bulk-from-turkey/")
        } else if (oldurl === "https://koytola.com/blog-details/what-turkey-produce-export") {
            redirect("https://blog.koytola.com/what-does-turkey-produce-and-export-top-10-products/")
        } else if (oldurl === "https://koytola.com/blog-details/mikro-ihracat-vs-ihracat") {
            redirect("https://blog.koytola.com/mikro-ihracat-vs-ihracat/")
        } else if (oldurl === "https://koytola.com/blog-details/mikro-ihracat-nasil-yapilir") {
            redirect("https://blog.koytola.com/mikro-ihracat/")
        } else if (oldurl === "https://koytola.com/blog-details/suez-canal-obstruction-and-fragility-of-global-supply-chain") {
            redirect("https://blog.koytola.com/suez-canal-obstruction-and-fragility-of-global-supply-chain/")
        } else if (oldurl === "https://koytola.com/blog-details/why-is-right-corporate-culture-indispensable-for-survival") {
            redirect("https://blog.koytola.com/why-is-right-corporate-culture-indispensable-for-survival/")
        } else if (oldurl === "https://koytola.com/blog-details/international-trade-opportunities-in-turkey-amid-covid-19-pandemic") {
            redirect("https://blog.koytola.com/international-trade-opportunities-in-turkey-amid-covid-19-pandemic/")
        } else if (oldurl === "https://koytola.com/blog-details/understanding-incoterms-2020-and-why-are-they-crucial-for-global-trade") {
            redirect("https://blog.koytola.com/understanding-incoterms-2020-and-why-are-they-crucial-for-global-trade/")
        } else if (oldurl === "https://koytola.com/blog-details/what-is-micro-exporting-and-why-to-go-for-this") {
            redirect("https://blog.koytola.com/what-is-micro-exporting-and-why-to-go-for-this/")
        } else if (oldurl === "https://koytola.com/blog-details/how-joining-online-trade-platforms-can-help-smes-to-mitigate-the-risks-involved") {
            redirect("https://blog.koytola.com/how-joining-online-trade-platforms-can-help-smes-to-mitigate-the-risks-involved/")
        } else if (oldurl === "https://koytola.com/blog-details/turkey-as-new-global-manufacturing-hub") {
            redirect("https://blog.koytola.com/turkey-as-new-global-manufacturing-hub/")
        } else if (oldurl === "https://koytola.com/blog-details/nis-urun-nedir-nasil-bulunur/") {
            redirect("https://blog.koytola.com/nis-urun-nedir-nasil-bulunur/")
        } else if (oldurl === "https://koytola.com/blog-details/international-marketplaces-and-cargo-shipments/") {
            redirect("https://blog.koytola.com/international-marketplaces-and-cargo-shipments/")
        } else if (oldurl === "https://koytola.com/blog-details/Yurt-disi-pazaryerleri-ve-kargo-gonderimleri/") {
            redirect("https://blog.koytola.com/yurt-disi-pazaryerleri-ve-kargo-gonderimleri/")
        } else if (oldurl === "https://koytola.com/blog-details/encontrar-fabricanet-de-turquia/") {
            redirect("https://blog.koytola.com/pregunta-como-encontrar-fabricantes-de-turquia/")
        } else if (oldurl === "https://koytola.com/blog-details/vergi-numarasi-ile-nasil-ihracat-yapilir/") {
            redirect("https://blog.koytola.com/vergi-numarasi-ile-nasil-ihracat-yapilir/")
        } else if (oldurl === "https://koytola.com/blog-details/yurt-disi-distributor-bayi-nasil-bulunur/") {
            redirect("https://blog.koytola.com/yurt-disinda-distributor-bayi-nasil-bulunur/")
        } else if (oldurl === "https://koytola.com/blog-details/abd-mikro-ihracat-nasil-yapilir/") {
            redirect("https://blog.koytola.com/amerika-birlesik-devletlerine-mikro-ihracat-nasil-yapilir/")
        } else if (oldurl === "https://koytola.com/blog-details/mikro-ihracat-kdv-iadesi-nasil-alinir/") {
            redirect("https://blog.koytola.com/mikro-ihracat-faaliyetlerinde-kdv-iadesi-nasil-alinir/")
        } else if (oldurl === "https://koytola.com/blog-details/manufacturers-in-turkey/") {
            redirect("https://blog.koytola.com/manufacturers-in-turkey/")
        } else if (oldurl === "https://koytola.com/blog-details/how-to-find-international-suppliers-online/") {
            redirect("https://blog.koytola.com/how-to-find-international-suppliers-online/")
        } else if (oldurl === "https://koytola.com/blog-details/toptan-mikro-ihracat-nasil-yapilir/") {
            redirect("https://blog.koytola.com/toptan-mikro-ihracat-nasil-yapilir/")
        } else if (oldurl === "https://koytola.com/blog-details/mini-import-from-turkey/") {
            redirect("https://blog.koytola.com/mini-importation-from-turkey/")
        } else if (oldurl === "https://koytola.com/blog-details/how-to-find-reliable-supplier/") {
            redirect("https://blog.koytola.com/how-to-find-a-reliable-supplier/")
        } else if (oldurl === "https://koytola.com/blog-details/how-to-create-t-shirt-brand/") {
            redirect("https://blog.koytola.com/how-to-create-a-t-shirt-brand/")
        } else if (oldurl === "https://koytola.com/blog-details/how-to-find-suppliers-from-turkey/") {
            redirect("https://blog.koytola.com/how-to-find-suppliers-from-turkey/")
        } else if (oldurl === "https://koytola.com/blog-details/buying-bulk-turkish-apricots/") {
            redirect("https://blog.koytola.com/buying-bulk-turkish-apricots/")
        } else if (oldurl === "https://koytola.com/blog-details/buying-turkish-coffee-direct-from-manufacturer/") {
            redirect("https://blog.koytola.com/buying-turkish-coffee-direct-from-manufacturer/")
        } else if (oldurl === "https://koytola.com/blog-details/elektronik-ticaret-gumruk-beyannamesi-etgb/") {
            redirect("https://blog.koytola.com/etgb-elektronik-ticaret-gumruk-beyannamesi-nedir-nasil-alinir/")
        } else if (oldurl === "https://koytola.com/blog-details/sirketsiz-ihracat/") {
            redirect("https://blog.koytola.com/esnaf-muafiyet-belgesi-nedir-sirketsiz-ihracat-yapilir-mi/")
        } else if (oldurl === "https://koytola.com/blog-details/mikro-ihracat-fatura-nasil-kesilir/") {
            redirect("https://blog.koytola.com/mikro-ihracat-faturasi-nasil-kesilir/")
        } else if (oldurl === "https://koytola.com/blog-details/what-to-import-from-turkey/") {
            redirect("https://blog.koytola.com/what-to-import-from-turkey-top-product-categories-to-purchase-in-bulk-from-turkey/")
        } else if (oldurl === "https://koytola.com/blog-details/what-turkey-produce-export/") {
            redirect("https://blog.koytola.com/what-does-turkey-produce-and-export-top-10-products/")
        } else if (oldurl === "https://koytola.com/blog-details/mikro-ihracat-vs-ihracat/") {
            redirect("https://blog.koytola.com/mikro-ihracat-vs-ihracat/")
        } else if (oldurl === "https://koytola.com/blog-details/mikro-ihracat-nasil-yapilir/") {
            redirect("https://blog.koytola.com/mikro-ihracat/")
        } else if (oldurl === "https://koytola.com/blog-details/suez-canal-obstruction-and-fragility-of-global-supply-chain/") {
            redirect("https://blog.koytola.com/suez-canal-obstruction-and-fragility-of-global-supply-chain/")
        } else if (oldurl === "https://koytola.com/blog-details/why-is-right-corporate-culture-indispensable-for-survival/") {
            redirect("https://blog.koytola.com/why-is-right-corporate-culture-indispensable-for-survival/")
        } else if (oldurl === "https://koytola.com/blog-details/international-trade-opportunities-in-turkey-amid-covid-19-pandemic/") {
            redirect("https://blog.koytola.com/international-trade-opportunities-in-turkey-amid-covid-19-pandemic/")
        } else if (oldurl === "https://koytola.com/blog-details/understanding-incoterms-2020-and-why-are-they-crucial-for-global-trade/") {
            redirect("https://blog.koytola.com/understanding-incoterms-2020-and-why-are-they-crucial-for-global-trade/")
        } else if (oldurl === "https://koytola.com/blog-details/what-is-micro-exporting-and-why-to-go-for-this/") {
            redirect("https://blog.koytola.com/what-is-micro-exporting-and-why-to-go-for-this/")
        } else if (oldurl === "https://koytola.com/blog-details/how-joining-online-trade-platforms-can-help-smes-to-mitigate-the-risks-involved/") {
            redirect("https://blog.koytola.com/how-joining-online-trade-platforms-can-help-smes-to-mitigate-the-risks-involved/")
        } else if (oldurl === "https://koytola.com/blog-details/turkey-as-new-global-manufacturing-hub/") {
            redirect("https://blog.koytola.com/turkey-as-new-global-manufacturing-hub/")
        } else {
            redirect("https://blog.koytola.com/")
        }
    }
    return (
        <>
        </>
    )
}
export default BlogShow;