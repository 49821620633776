import React from "react";
import styled from 'styled-components'
import { up, only, down, between } from "styled-breakpoints";
import Header from "./Header";
import Footer from "./footer/Footer";
import { NavLinks } from "../data/navLinks";
import { IProps } from "../shared/types/Interfaces";
const Grid = styled.div`
  display: grid;
  min-height: 100vh;
  grid-template-columns: auto;
  grid-template-rows: auto, auto, auto;
  grid-template-areas:
    "header"
    "main"
    "footer";

    [data-grid="header"] {
    grid-area: header;
    z-index: 20;

    ${down("sm", "landscape")} {
    }

    ${between("sm", "md")} {
    }
  }

  [data-grid="main"] {
    grid-area: main;
    display: flex;
    flex-direction: column;
    height: auto;

    ${only("lg")} {
    }

    /* ${between("lg", 'xl')} {
      max-width: 1000px;
    }

    ${up("xl")} {
      max-width: 1350px;
      margin: 0 auto;
    } */
  }

  [data-grid="footer"] {
    grid-area: footer;
    }
`;
export const Layout: React.FC<IProps> = ({ children }: IProps) => {
  return (
    <Grid>
      <header data-grid="header">
        <Header navLinks={NavLinks} />
      </header>
      <main data-grid="main">
        {children}
      </main>
      <footer data-grid="footer">
        <Footer />
      </footer>
    </Grid>
  );
};