import React, { useState, useEffect } from 'react'
import { createStyles, makeStyles, Theme, TextField, Select, MenuItem, GridList, GridListTile, Checkbox, Button, CircularProgress } from '@material-ui/core'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import 'react-step-progress/dist/index.css';
import { useMutation } from '@apollo/client';
import { gql, useQuery } from '@apollo/client';
import { useParams } from "react-router-dom";
import $ from 'jquery';
const registerBuyer = gql`
mutation($email:String! 
    $role:UserType!
    $password:String!
    $redirectUrl:String!){
    accountRegister(input: {
      email:$email
      role: $role
      password:$password
      redirectUrl:$redirectUrl
    }){
      user{
        id
        userId
        email
      }
      accountErrors{
        field
        message
        code
      }
    }
  }
  `;
const craeteToken = gql`
  mutation($email:String!
    $password:String!) {
    tokenCreate(email: $email
       password: $password) {
      user {
        id
        userId
        email
        dateJoined
        isStaff
        isActive
        isSeller
        firstName
        lastName
        lastLogin
        company{
          id
          name
        }
        avatar {
          url
          alt
        }
      }
      token
      csrfToken
      refreshToken
      accountErrors{
        field
        message
      }
    }
  }
  `;
const productQueryCreatedByBuyer = gql`
  mutation($productId:ID!
    $quantity:Int!
    $message:String!
    $name:String!
    $country: CountryCode){
      productQueryCreate(productId:$productId
      input:{quantity: $quantity,
      message: $message,
      name: $name,
      country:$country}
    ){
      productQueries {
        product{
          id
          name
        }
                
      }
      productErrors {
        field
        message
        code
        }
    }
  }
  `;

const ALLCATEGORY = gql`
  query($slug:String,$after: String){
        company(slug:$slug){
          name
          products(first:10,after:$after) {
              edges {
                  node {
                      id
                      name
                      unit
                      description
                      creationDate
                      hsCode
                      category{
                          parent{
                          parent{
                              name
                              id
                          }
                          name
                          id
                          }
                      }
                      images{
                          image{
                          url
                          }
                      }
                  }
              }
              pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
              }
          }
      }
      site {
        countries {
          country
          code
        }
      }
    }
    `;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input_filed: {
      marginBottom: "10px !important",
      marginTop: "20px !important",
      display: "block !important"
    },
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      width: 500,
      height: 450,
    },
    category: {
      left: '5%',
      marginTop: theme.spacing(2),
    },
  }))
export const MakeOrder = () => {
  const [productQueryCreate] = useMutation(productQueryCreatedByBuyer);
  const [accountRegister] = useMutation(registerBuyer);
  const [tokenCreate] = useMutation(craeteToken);
  const [EmailError, setEmailError] = useState(false);
  const [Step, setStep] = useState(1);
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCategoryId(event.target.value as string);
  }
  const [Country, setCountry] = useState('')
  const [CountryName, setCountryName] = useState('')
  const selectCountry = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCountry(event.target.value as string);
  }
  const [ProductList, setProductList] = useState([] as any);
  const [PasswordError, setPasswordError] = useState(false);
  const [idshow, setIdshow] = useState(null as any);
  const [PasswordValue, setPasswordValue] = useState('');
  const [NameValue, setNameValue] = useState('');
  const [Emailvalue, setEmailvalue] = useState('');
  const [Image, setImage] = useState('');
  const [Name, setName] = useState('');
  const [type, setType] = useState('');
  const [ProductId, setProductId] = useState('');
  const [CategoryId, setCategoryId] = useState('')
  const [AfterId, setAfterId] = useState(null);
  const companyId: any = useParams()
  const { loading, data, refetch } = useQuery(ALLCATEGORY, {
    variables: {
      slug: companyId.id,
      after: AfterId
    }
  });
  useEffect(() => {
    idshow && idshow.scrollIntoView()
  }, [idshow])
  const Loaddata = () => {
    if ((window.location).href.indexOf("#companycontact") > 0) {
      setIdshow(res => document.getElementById("companycontact"))
    }
    return (
      <>
      </>
    )
  }

  let auth = sessionStorage.getItem('auth');
  let csrf = sessionStorage.getItem('csrf');
  let username = sessionStorage.getItem('Name');
  const [OldAfterId, setOldAfterId] = React.useState(null as any);
  const [Message, setMessage] = useState('');
  const [unit, setUnit] = useState('');
  const [Value, setValue] = useState(0);
  const [LoginError, setLoginError] = useState([]);
  const [RegisterError, setRegisterError] = useState([]);
  const [ProductError, setProductError] = useState([]);
  Step === 6 && setTimeout(() => {
    setMessage('')
    setUnit('')
    setValue(0)
    setLoginError([])
    setRegisterError([])
    setAfterId(null)
    setProductError([])
    setType('')
    setNameValue('')
    setEmailvalue('')
    setPasswordValue('')
    setProductId('')
    setEmailError(false)
    setCountry('')
    setName('')
    setImage('')
    setCountryName('')
    setCategoryId('')
    setStep(1)
  }, 5000)

  const handleKeyPress2 = (event) => {
    setMessage(event.target.value);
  }
  const ValueChange = () => {
    return (
      <>
        {Value > 0 &&
          <div className="col-xs-6">
            <div className="purity_sec">
              <span>Selected Quantity</span>
              <p>{Value} {unit}</p>
            </div>
          </div>
        }
      </>
    )
  }
  useEffect(() => {
    ValueChange()
  }, [Value])
  const classes = useStyles();
  console.log(data)
  if (!data && ProductList.length === 0) return <div className="loading_seccestion"><CircularProgress /></div>
  const onFormSubmit = async () => {
    const response = await accountRegister({
      variables: {
        email: Emailvalue,
        password: PasswordValue,
        role: "BUYER",
        redirectUrl: "https://panel.koytola.com/account-verify/"
      }
    });
    if (response) {
      setRegisterError(response?.data?.accountRegister?.accountErrors);
      if (response?.data?.accountRegister?.accountErrors?.length === 0 || response?.data?.accountRegister?.accountErrors[0].code === "UNIQUE") {
        const login = await tokenCreate({
          variables: {
            email: Emailvalue,
            password: PasswordValue,
          }
        });
        if (login) {
          if (login?.data?.tokenCreate?.accountErrors?.length === 0) {
            const productquery = await productQueryCreate({
              context: {
                headers: {
                  "Authorization": "JWT " + login?.data?.tokenCreate?.token
                }
              },
              variables: {
                productId: ProductId,
                message: Message,
                quantity: Value,
                country: Country,
                name: NameValue
              }
            });
            if (productquery) {
              if (productquery?.data?.productQueryCreate?.productErrors?.length === 0) {
                setStep(6);
                sessionStorage.setItem("auth", login?.data?.tokenCreate?.token);
                sessionStorage.setItem("Name", NameValue);
                sessionStorage.setItem("csrf", login?.data?.tokenCreate?.csrfToken);
                window.open(
                  'https://panel.koytola.com?auth=' + login?.data?.tokenCreate?.token,
                  '_blank'
                );
                setProductError([])
              }
              else {
                setProductError(productquery?.data?.productQueryCreate?.productErrors);
              }
            }
            setLoginError([])
          }
          else {
            setLoginError(login?.data?.tokenCreate?.accountErrors);
          }
        }
      }
      else {
        setRegisterError(response?.data?.accountRegister?.accountErrors);
      }
    }
  }
  const submitproduct = async () => {
    const productquery = await productQueryCreate({
      context: {
        headers: {
          "Authorization": "JWT " + auth
        }
      },
      variables: {
        productId: ProductId,
        message: Message,
        quantity: Value,
        country: Country,
        name: username
      }
    });
    if (productquery) {
      if (productquery?.data?.productQueryCreate?.productErrors?.length === 0) {
        setStep(6);
        window.open(
          'https://panel.koytola.com/message',
          '_blank'
        );
        setProductError([])
      }
      else {
        setProductError(productquery?.data?.productQueryCreate?.productErrors);
      }
    }
  }
  const handleScroll = (e) => {
    if (e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight) < 10) {
      if (data?.company?.products?.pageInfo?.hasNextPage === true) {
        setAfterId(data?.company?.products?.pageInfo?.endCursor)
      }
    }
  }
  const onload = async () => {
    await setOldAfterId(AfterId)
    const rrr = await refetch()
    rrr && setProductList([...ProductList, ...rrr?.data?.company?.products?.edges])
  }
  if (data) {
    if (ProductList.length === 0) {
      setProductList(data?.company?.products?.edges)
    }
  }
  OldAfterId != AfterId && onload()
  return (
    <div className="wizardForm_wrap" id="companycontact">
      <Loaddata />
      <div className="profile_tab_panel">
        <div className="container">
          {Step != 6 &&
            <div className="profile_heading_maltipal make_ober">
              <h2>Interested? </h2>
              <h3>Lets’ contact supplier</h3>
            </div>
          }
          <div className="row">
            <div className="col-md-12">
              <div className="progressbar">
                <div className="row">
                  <div className="col-xs-10">
                    <div className="create_query">
                      {Step === 1 && <h3><span>01</span>Select a product you’d like to <b> learn more</b></h3>}
                      {Step === 2 && <h3><span>02</span>Any ideas on  Quantity ? </h3>}
                      {Step === 3 && <h3><span>03</span>Destination ?</h3>}
                      {Step === 4 && <h3><span>04</span>Write down your message</h3>}
                      {Step === 5 && <h3><span>05</span>Enter your email and create a password</h3>}
                    </div>
                  </div>
                  <div className="col-xs-2">
                    {Step < 6 &&
                      <CircularProgressbar
                        value={Step}
                        maxValue={auth ? 4 : 5}
                        text={`${Step} / ${auth ? 4 : 5}`}
                        styles={buildStyles({
                          textColor: "#6b6b6b",
                          pathColor: "#d2bbf2",
                          trailColor: "#f9f6fd"
                        })}
                      />}
                  </div>
                </div>
              </div>
              {Step === 1 &&
                <div className="col-md-7">
                  <div className="select_box_inner" onScroll={handleScroll}>
                    <div className="select_categiory_outer cths">
                      <Select className="select_categiory"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={CategoryId}
                        onChange={handleChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem value="" disabled>Category</MenuItem>
                        {data?.company?.products?.edges?.map((item: any) => {
                          return <MenuItem value={item?.node?.category?.parent?.id}>{item?.node?.category?.parent?.name}</MenuItem>
                        })}
                      </Select>
                      <h5>Select</h5>
                    </div>
                    <div className={classes.root}>
                      {ProductList.length > 0 &&
                        <GridList className="select_grid_inner">
                          {ProductList?.map((res: any) => {
                            var type = JSON.parse(res?.node?.description);
                            return (
                              <GridListTile key={res?.name}>
                                <div className="list_item_outer">
                                  <div className="list_item">
                                    <div className="list_img">
                                      <img src={res?.node?.images[0]?.image?.url} />
                                    </div>
                                    <div className="list_contant">
                                      <span>{res?.node?.name?.slice(0, 15)}</span>
                                      <p>{type.Type}</p>
                                    </div>
                                  </div>
                                  <div className="list_cheked">
                                    <Checkbox
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          setImage(res?.node?.images[0]?.image?.url)
                                          setName(res?.node?.name)
                                          setUnit(res?.node?.unit)
                                          setProductId(res?.node?.id)
                                          setType(type.Type)
                                        }
                                        else if (!e.target.checked) {
                                          setImage('')
                                          setName('')
                                          setUnit('')
                                          setProductId('')
                                          setType('')
                                        }
                                      }}
                                      checked={res?.node?.id === ProductId} />
                                  </div>
                                </div>
                              </GridListTile>
                            )
                          })
                          }
                        </GridList>}
                    </div>
                  </div>
                </div>
              }
              {Step === 2 &&
                <div className="col-md-7">
                  <div className="kilogram_box">
                    <input type="number" value={Value} className="kilogram_input" onChange={(e) => {
                      var re = /^[0-9\b]+$/;
                      if (e.target.value === '' || re.test(e.target.value)) {
                        setValue(parseInt(e.target.value))
                      }
                    }} />
                    <span>{unit}</span>
                  </div>
                </div>
              }
              {Step === 3 &&
                <div className="col-md-7">
                  <div className="select_country_inner">
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={Country}
                      onChange={selectCountry}
                      displayEmpty
                      className="category"
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      <MenuItem value="" disabled>Select country</MenuItem>
                      {data?.site?.countries?.map((res: any) => (
                        <MenuItem value={res?.code}
                          onClick={() => {
                            setCountryName(res?.country)
                          }}
                        >{res?.country}</MenuItem>
                      ))}
                    </Select>
                    <p>{CountryName}</p>
                  </div>
                </div>
              }
              {Step === 4 &&
                <div className="col-md-7">
                  <div className="massage_inner">
                    <textarea value={Message} className="massage_box" onChange={handleKeyPress2} placeholder="Here you can write your questions, requests etc" />
                  </div>
                </div>
              }
              {Step === 5 && !auth &&
                <>
                  <div className='mackeordererror'>
                    {RegisterError.map((res: any, index: any) => (
                      <p> <span style={{ color: 'red', border: '1px solid red' }}>{res.message}</span></p>))}
                    {LoginError.map((res: any, index: any) => (
                      <p> <span style={{ color: 'red', border: '1px solid red' }}>{res.message}</span></p>))}
                    {ProductError.map((res: any, index: any) => (
                      <p><span style={{ color: 'red', border: '1px solid red' }}>{res.message}</span></p>))}
                  </div>
                  <div className="submit_filds">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input_fild">
                          <TextField
                            label="Your Name"
                            className={classes.input_filed}
                            fullWidth
                            placeholder="Enter your Name"
                            type="text"
                            variant="outlined"
                            onChange={(e) => {
                              setNameValue(e.target.value)
                              if (e.target.value.length <= 0) {
                                setPasswordError(true);
                              } else {
                                setPasswordError(false);
                              }
                            }}
                            error={PasswordError}
                          />
                        </div>
                        <div className="input_fild">
                          <TextField
                            label="Your Email"
                            className={classes.input_filed}
                            fullWidth
                            placeholder="Enter your Email"
                            type="text"
                            variant="outlined"
                            onChange={(e) => {
                              setEmailvalue(e.target.value)
                              if (e.target.value.length <= 0) {
                                setEmailError(true);
                              } else {
                                setEmailError(false);
                              }
                            }}
                            error={EmailError}
                          />
                        </div>
                        <div className="input_fild">
                          <TextField
                            label="Create a password ( 8-digit )"
                            className={classes.input_filed}
                            fullWidth
                            placeholder="Create a password for sending messages to suppliers. (min 8 characters) "
                            type="password"
                            variant="outlined"
                            onChange={(e) => {
                              setPasswordValue(e.target.value)
                              if (e.target.value.length <= 0) {
                                setPasswordError(true);
                              } else {
                                setPasswordError(false);
                              }
                            }}
                            error={PasswordError}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }
              {Step < 5 &&
                <div className="row">
                  <div className="col-md-5">
                    <div className="query_summery_box">
                      <div className="inner_heading">
                        <h4>Details</h4>
                      </div>
                      {ProductId &&
                        <>
                          <span className="selected_item_make_order">Selected items</span>
                          <div className="select_box_uli">
                            <div className="selected_img">
                              <img src={Image} />
                            </div>
                            <div className="selected_contant">
                              <span>{Name ? Name : ""}</span>
                              <p>{type ? type : ""}</p>
                            </div>
                          </div>
                        </>
                      }
                      <div className="row">
                        <ValueChange />
                        {Country &&
                          <div className="col-xs-6">
                            <div className="purity_sec">
                              <span> Destination</span>
                              <p>{CountryName}</p>
                            </div>
                          </div>
                        }
                        {Message &&
                          <div className="col-xs-6">
                            <div className="purity_sec">
                              <span>Message</span>
                              <p>{Message && <p>{Message.slice(0, 25)}{Message.length > 25 && <span>...</span>}
                              </p>}</p>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              }
              {Step === 6 &&
                <>
                  <div className="makeover_thanks">
                    <h2>Thank you!</h2>
                    <h4>Supplier will contact you soon. </h4>
                  </div>
                </>
              }
            </div>
            <div className="col-md-12 makeour_btn">
              {Step > 1 && Step < 6 &&
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    setStep(Step - 1)
                  }}
                  disabled={Step === 1}
                >
                  Back
                </Button>
              }
              {Step < 5 &&
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    !auth && Step < 5 &&
                      setStep(Step + 1)
                    auth && Step === 4 && submitproduct()
                    auth && Step < 4 &&
                      setStep(Step + 1)
                  }}
                  disabled={Step === 1 ? (ProductId ? false : true) : Step === 2 ? (Value > 0 ? false : true) : Step === 3 ? (Country ? false : true) : Step === 4 ? (Message ? false : true) : true}
                >
                  {auth && Step === 4
                    ? <>Send</> : <>Next</>
                  }
                </Button>
              }
              {Step === 5 &&
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    onFormSubmit()
                  }}
                >
                  Send
                </Button>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default MakeOrder;