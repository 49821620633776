import { CircularProgress } from "@material-ui/core";
import React from "react";
import { useQuery, gql } from '@apollo/client';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useHistory } from 'react-router-dom';
import Typical from 'react-typical'
const PRODUCTS = gql`
query { 
    products(first:40, sortBy:{field:DATE_CREATED, direction:DESC},filter:{home:"*"}){
        edges{
            node{
                id        
                name
                slug
                category{
                id
                name
                }
                hsCode
                slug
                isPublished
                description
                creationDate
                isActive
                unit
                unitPrice
                unitNumber
                currency
                minimumOrderQuantity
                descriptionPlaintext
                organic
                privateLabel
                images{
                id
                image{
                    url
                    alt
                }
                altText
                order
                ppoi
                }
            }
        }
    }                           
}
`
const TopProduct = () => {
    const handleDragStart = (e: { preventDefault: () => any; }) => e.preventDefault();
    const { loading, error, data } = useQuery(PRODUCTS);
    const history = useHistory();
    const goToProductDetailPage = (value: any) => {
        history.push("/product-details/" + value);
    }
    const steps = [
        'suppliers', 2000,
        'new items', 2000,
        'ideas', 2000
    ];
    if (loading) return <div className="loading_seccestion"><CircularProgress /></div>
    if (error) return <p>Error: Something is wrong! </p>
    const options = {
        autoplay: true,
        dots:false,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1.5,
                margin: 5,
            },
            600: {
                items: 2,
                margin: 10,
            },
            1000: {
                items: 3,
                margin: 20,
            }
        },
    };
    return (
        <>
            <section className="top_products sec_space">
                <div className="container">
                    <div className="row typr_flex">
                        <div className="col-xs-12 d-sm-none d-xs-block">
                            <p style={{ textAlign: "center", color: "#000", marginBottom: 20, fontWeight: 500 }}>Buy wholesale products from Turkey that <strong style={{ fontWeight: 700 }}> your customer</strong>  will love </p>
                        </div>
                        <div className="col-sm-4 col-xs-12">
                        </div>
                        <div className="col-sm-8 col-xs-12">
                            <div className="selling text-writer" id="check">
                                <span>You are at the right place for <span className="d-xs-block"> Finding <Typical wrapper="span" steps={steps} loop={Infinity} className={'typewriter'} /></span> </span>
                            </div>
                        </div>
                    </div>
                    <div className="row product_align_slider">
                        <div className="col-sm-4 col-xs-5">
                            <div className="products_amount">
                                <span>Trending products</span>
                                <h1>1500+</h1>
                            </div>
                            <div className="coming_soon">
                                <ul>
                                    <li><a href="javascript:void(0)" className="active">Wellness</a></li>
                                    <li><a href="javascript:void(0)">Spiritual</a></li>
                                    <li><a href="javascript:void(0)">Work from Home</a></li>
                                    <li><a href="javascript:void(0)">Niche</a></li>
                                    <li><a href="javascript:void(0)">Seen on Social Media</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-8 col-xs-7">
                            <OwlCarousel className='owl-theme' {...options} loop margin={20} nav>
                                {data?.products?.edges?.map((res: any) =>
                                    <div className="product_inner trendingproduct_inner" style={{ paddingTop: 10 }}
                                        onClick={(e) => goToProductDetailPage(res?.node?.slug)}>
                                        <div className="topproduct_inner-imgwrap" style={{ borderRadius: 6, boxShadow: "0 2px 7px rgb(145 145 145 / 35%)", textAlign: 'center', overflow: 'hidden' }}>
                                            <img src={res?.node?.images[0]?.image?.url} />
                                        </div>

                                        <p className="d-xs-none">{res?.node?.name.slice(0, 25)} {res?.node?.name?.length > 25 && <span>...</span>}</p>
                                    </div>
                                )}
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}
export default TopProduct;