import React from "react";
import { useHistory } from 'react-router-dom';
const Footer = () => {
    const history = useHistory();
    const dashboard = () => {
        window.open(
            'https://panel.koytola.com',
            '_blank'
        );
    }
    return (
        <>
            <footer className="sec_space">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="footer_colum">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="footer_information_heading">
                                            <h4 style={{ fontWeight: 700 }}>Your data is safe with us.</h4>
                                        </div>
                                    </div>
                                    <div className="col-xs-3">
                                        <div className="colum_inner">
                                            <h6>Partnerships</h6>
                                            <ul>
                                                <li><a href="javascript:void(0)" onClick={() => {
                                                    dashboard()
                                                }}>membership</a></li>
                                                <li><a href="javascript:void(0)" onClick={() => {
                                                    dashboard()
                                                }}>sellers</a></li>
                                                <li><a href="javascript:void(0)" onClick={() => {
                                                    dashboard()
                                                }}>buyers</a></li>
                                                <li className="d-xs-block"><a href="javascript:void(0)" onClick={() => history.push('/faq')}>frequently asked questions </a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-xs-3">
                                        <div className="colum_inner">
                                            <h6>More Koytola</h6>
                                            <ul>
                                                <li><a href="#Koytola_supports" >values</a></li>
                                                <li className="d-xs-block"><a href="https://koytola.com/sitemap.xml" >Sitemap</a></li>
                                                <li><a href="javascript:void(0)" onClick={dashboard}>KVKK</a></li>
                                                <li><a href="javascript:void(0)" onClick={() => history.push('/cargo')}>cargo</a></li>
                                                <li className="d-xs-block"><a target="_blank" href="https://blog.koytola.com"
                                                >Blog</a></li>
                                                <li><a href="javascript:void(0)" onClick={() => { history.push("/contact") }}>contact us</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-4 d-xs-none">
                                        <div className="colum_inner tradeTech">
                                            <h6></h6>
                                            <ul>
                                                <li><a href="javascript:void(0)" onClick={dashboard}>TradeTech</a></li>
                                                <li><a href="javascript:void(0)" onClick={dashboard}>SmartTrade Tool</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 d-xs-none"></div>
                        <div className="col-lg-4">
                            <div className="footer_colum information">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="footer_information_heading">
                                            <h4>How can we do better?</h4>
                                            <p className="d-sm-none d-xs-block" style={{ fontSize: 13 }}>Contact us: export@koytola.com</p>
                                        </div>
                                    </div>
                                    <div className="col-md-5 col-xs-12">
                                        <div className="colum_inner">
                                            <h6>Head Q</h6>
                                            <span>
                                                Kollektif House
                                                Atasehir-Istanbul
                                                34750
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-xs-12">
                                        <div className="information_bottom">
                                            <p>“You are not a drop in the ocean. You are the entire ocean in a drop.”  </p>
                                            <span className="footer_rumi">― Rumi</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer_copyright">
                            <p>© 2022  <a href="javascript:void(0)" onClick={() => history.push('/')}>Koytola</a> All rights reserved</p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
export default Footer;