import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import SearchProduct from './SearchProduct';
import "swiper/swiper.min.css";
import SwiperCore, {
    Mousewheel, Pagination, Autoplay,
} from 'swiper/core';
import { useHistory } from 'react-router-dom';
SwiperCore.use([Mousewheel, Pagination, Autoplay]);
const Topline = () => {
    const history = useHistory();
    return (
        <>
            <div className="top_bar_header topbar-header-mobile">
                <Swiper
                    direction={'vertical'}
                    slidesPerView={1}
                    loop={true}
                    autoplay={{
                        "delay": 3000,
                        "disableOnInteraction": false
                    }}
                    spaceBetween={2}
                    mousewheel={true}
                    className="mySwiper topline topline-mobile"
                >
                    <SwiperSlide >
                        <p>Hey there, creating your own brand? <span
                            onClick={() => {
                                history.push("/supplier-sourcing")
                            }}
                        > Click here for free sourcing!   </span></p>
                    </SwiperSlide>
                    <SwiperSlide >
                        <p>Hey there, looking for a specific product? <span
                            onClick={() => {
                                history.push("/supplier-sourcing")
                            }}
                        > Click here for free sourcing!   </span></p>
                    </SwiperSlide>
                </Swiper>
                <div className="products_search searchproduct_MVIEW">
                    <SearchProduct />
                </div>
            </div>
        </>
    )
}
export default Topline;