import { Button, CircularProgress } from '@material-ui/core';
import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { makeStyles, Theme } from '@material-ui/core/styles';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import * as _ from 'underscore';
import Marquee from 'react-marquee-master';
import { useHistory } from 'react-router-dom';
import LocationOnIcon from '@material-ui/icons/LocationOn';
const DAILYOFFER = gql`
query {
products(
    first:40, sortBy:{field:DATE_CREATED, direction:DESC},
    filter:{home:"*"}
   ) {
    edges {
      node {
        id
        slug
        name
        tags
        hsCode
        currency
        unitNumber
        unit
        totalReview
        totalRatting
        unitPrice
        rosetter{
            name
            id
          }
        certificateType{
            id
            name
        }
        company{
          name
          id
          slug
          logo{
            url
          }
          city
          address{
            city
            country{
              code
              country
            }
            cityArea
          }
          representativeName
          photo{
            url
          }
          representative{
             name
             photo{
              url
            }
          }
        }
        images{
          image{
            url
          }
        }
      }
    }
    pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
  }
    categories(first: 40) {
        edges {
          node {
            id
            name
            slug
            backgroundImage{
                url
              }
          }
        }
      }
  }`;

const MoreProduct = () => {
    const history = useHistory();
    const { loading, error, data } = useQuery(DAILYOFFER);
    if (loading) return <div className="loading_seccestion"><CircularProgress /></div>
    if (error) return <p>Error: Something is wrong! </p>
    const goToCompanyPage = (value: any) => {
        history.push("/company-profile/" + value);
    }
    const goToProductDetailPage = (value: any) => {
        history.push("/product-details/" + value);
    }
    const values = data?.products?.edges;
    const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();
    const n = 8;
    var lists = _.groupBy(values, function (element, index) {
        return Math.floor(index / n);
    });
    lists = _.toArray(lists);
    var mArray: any = [];
    {
        values.map((item: any) =>
            <>
                {item?.node?.tags?.map((tag: any) =>
                    mArray.push(
                        <Button>
                            {tag}
                        </Button>
                    )
                )}
            </>
        )
    }

    const options = {
        autoplay: true,
        autoplayTimeout: 8000,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1.2,
                slideBy: 1,
                marginRight: 5,
            },
            460: {
                items: 1.5,
                slideBy: 1,
                marginRight: 10,
            },
            768: {
                items: 1,
                slideBy: 1
            },
            1000: {
                items: 1
            }
        },
    };
    return (<>
        <section className="daily_offer sec_space">
            <div className="container">
                <div className="section_heading">
                    <h2>More Products </h2>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="dealy_offter_marquee">
                            <Marquee marqueeItems={mArray} direction="left" />
                        </div>
                        <div className="col-md-12">
                            <div className="home_products d-xs-none">
                                <OwlCarousel  {...options} className='owl-theme' loop margin={10}>
                                    {lists?.map((item: any, index) => (
                                        <>
                                            <div className="row">
                                                {item?.map((res: any, index) => (
                                                    <div className="col-md-3">
                                                        <div className="product_list_box">
                                                            <div className="top_bar" onClick={(e) => goToCompanyPage(res?.node?.company?.slug)}>
                                                                <img className="d-xs-none" src={res?.node?.company?.logo?.url} />
                                                                <div className="location">
                                                                    <h4>{res?.node?.company?.name}</h4>
                                                                    <div className="loc">
                                                                        <LocationOnIcon fontSize="small" className="d-xs-none" />
                                                                        <span>
                                                                            {res?.node?.company?.city}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="product_image" style={{ borderRadius: 6, boxShadow: "0 2px 7px rgb(145 145 145 / 35%)", textAlign: 'center', overflow: 'hidden' }}>
                                                                <img src={res?.node?.images[0]?.image?.url} onClick={(e) => goToProductDetailPage(res?.node?.slug)} />
                                                            </div>
                                                            <div className="product_cont">
                                                                <h4 onClick={(e) => goToProductDetailPage(res?.node?.slug)}>{res?.node?.name}</h4>
                                                                <div className="price">
                                                                    <h3>{res?.node?.currency} {res?.node?.unitPrice}</h3>
                                                                    {res?.node?.unit &&
                                                                        <span>/ {res?.node?.unit?.toLowerCase()}</span>
                                                                    }
                                                                </div>
                                                                <div className="company_detail_hover">
                                                                    <h5 className="d-xs-none">Seller</h5>
                                                                    <div className="flex-row">
                                                                        <div className="supplier_infoaria2">
                                                                            <img src={res?.node?.company?.photo?.url} />
                                                                            <div className="supplier_info">
                                                                                <h5>{res?.node?.company?.representativeName}</h5>
                                                                                <span>{res?.node?.company?.city}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="zkitcard_foright">
                                                                            <a className="btn-follow" onClick={() => goToCompanyPage(res?.node?.company?.slug + "#companycontact")}>Message</a>
                                                                            <a className="btn-viewstore" onClick={(e) => goToCompanyPage(res?.node?.company?.slug)}>View store &gt; </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                    ))}
                                </OwlCarousel>
                            </div>
                            <div className="home_products d-xs-block d-sm-none">
                                <OwlCarousel  {...options} className='owl-theme' loop margin={10}>
                                    {data?.products?.edges?.map((res: any, index) => (
                                        <div className="product_list_box">
                                            <div className="top_bar" onClick={(e) => goToCompanyPage(res?.node?.company?.slug)}>
                                                <img className="d-xs-none" src={res?.node?.company?.logo?.url} />
                                                <div className="location">
                                                    <h4>{res?.node?.company?.name}</h4>
                                                    <div className="loc">
                                                        <LocationOnIcon fontSize="small" className="d-xs-none" />
                                                        <span>
                                                            {res?.node?.company?.city}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="product_image" style={{ borderRadius: 6, boxShadow: "0 2px 7px rgb(145 145 145 / 35%)", textAlign: 'center', overflow: 'hidden' }}>
                                                <img src={res?.node?.images[0]?.image?.url} onClick={(e) => goToProductDetailPage(res?.node?.slug)} />
                                            </div>
                                            <div className="product_cont">
                                                <h4 onClick={(e) => goToProductDetailPage(res?.node?.slug)}>{res?.node?.name}</h4>
                                                <div className="price">
                                                    <h3>{res?.node?.currency} {res?.node?.unitPrice}</h3>
                                                    {res?.node?.unit &&
                                                        <span>/ {res?.node?.unit?.toLowerCase()}</span>
                                                    }
                                                </div>
                                                <div className="company_detail_hover">
                                                    <h5 className="d-xs-none">Seller</h5>
                                                    <div className="flex-row">
                                                        <div className="supplier_infoaria2">
                                                            <img src={res?.node?.company?.photo?.url} />
                                                            <div className="supplier_info">
                                                                <h5>{res?.node?.company?.representativeName}</h5>
                                                                <span>{res?.node?.company?.city}</span>
                                                            </div>
                                                        </div>
                                                        <div className="zkitcard_foright">
                                                            <a className="btn-follow" onClick={() => goToCompanyPage(res?.node?.company?.slug + "#companycontact")}>Message</a>
                                                            <a className="btn-viewstore" onClick={(e) => goToCompanyPage(res?.node?.company?.slug)}>View store</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>)
}
export default MoreProduct;