export enum RoutesUrls {
    HOME = '/',
    COMPANIES = '/companies',
    PRODUCTS = '/product',
    PRODUCTS_DETAILS = '/product-details/:id',
    BLOG_DETAILS = '/blog-details/:id',
    CONTACT = '/contact',
    SLIDER = '/slider',
    LOGIN = '/login',
    SIGNUP = '/signup',
    ACCOUNT = '/account',
    CompanyProfile = '/company-profile',
}