import React, { useState } from "react";
import { Flex } from "../ui/flex";
import { Wrapper } from "./Header.styles";
import MenuItem from '@material-ui/core/MenuItem';
import { gql, useQuery } from '@apollo/client';
import SearchProduct from './SearchProduct';
import SubCategory from './subCategory/SubCategory';
import { CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Topline from './TopLine';
const ALLCATEGORY = gql`
query{
    categories(first: 100) {
      edges {
        node {
          id
          name
          backgroundImage {
            url
          }
            products(first: 10) {
              edges {
                node {
                  id
                  name
                }
              }
            }
            productCount
          parent {
            id
            name
          }
        }
      }
    }
  }
  `;
interface IHeaderProps {
    navLinks: Array<{
        name: string,
        path: string
    }>
}
export interface Subcategorydata {
    id: string,
}
export var Maincategoryvalue: Subcategorydata = {
    id: '',
};
export interface hideshow {
    show: boolean,
}
export var hideshowvalue: hideshow = {
    show: true,
};
const Header = (props) => {
    const [CategoryId, setCategoryId] = useState('')
    const [Hide, setHide] = useState(false);
    const MouseOver = (value: any) => {
        setCategoryId(value);
        setHide(false)
        Maincategoryvalue.id = value;
        hideshowvalue.show = false;
    };
    const history = useHistory();
    function homepage() {
        history.push("/");
    }
    let token = sessionStorage.getItem('auth');
    let userName = sessionStorage.getItem('Name');
    const { data, loading, refetch } = useQuery(ALLCATEGORY);
    if (loading) return <div className="loading_seccestion"><CircularProgress /></div>
    const values = data?.categories?.edges;
    const logout = () => {
        sessionStorage.removeItem('auth');
        sessionStorage.removeItem('Name');
        sessionStorage.removeItem('csrf');
        window.location.reload();
    };
    const blogs =()=>{
        window.open(
            'https://blog.koytola.com',
            '_blank'                
          );
    }
    return (<>
        <Topline />
        <Wrapper className="main-header">
            <Flex width='100%'>
                <header>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="top_header">
                                    <div className="row">
                                        <div className="col-sm-8 col-xs-12">
                                            <div className="left_colum">
                                                <div className="logo">
                                                    <h5 onClick={() => {
                                                        homepage()
                                                    }}>koytola</h5>
                                                </div>
                                                <div className="products_search ps_wrapper_DVIEW">
                                                    <div className="searchproduct_DVIEW">
                                                    <SearchProduct />
                                                    </div>
                                                    <div className="category-mobile">
                                                        <div className="dropdwn pi_category_drop">
                                                            <button
                                                                onMouseOver={() => { setHide(true) }}
                                                                className="dropbtn categiory_btn"
                                                            >All Category
                                                            </button>
                                                            {Hide &&
                                                                <div className="dropdown-content category" id="category_outer">
                                                                    <div className="container">
                                                                        <div className="row">
                                                                            <div className="column">
                                                                                {values?.map((res: any, index: any) => (
                                                                                    res?.node?.productCount > 0 &&
                                                                                    <MenuItem className="category_item" value={res?.node?.id} onClick={() => (
                                                                                        MouseOver(res?.node?.id)
                                                                                    )}>
                                                                                        <img src={res?.node?.backgroundImage?.url} />
                                                                                        <span className="category_name">{res?.node?.name} </span>
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="dropdwn discover_main_menu">
                                                            <button className="dropbtn" 
                                                            onClick={()=>{
                                                                blogs()
                                                            }}
                                                            >Blogs 
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 col-xs-12">
                                            <div className="profile float-right">
                                            <a className="become_supplier" href="https://panel.koytola.com/supplier/signup">Hadi! Hemen tedarikçi profili oluştur   </a>
                                                <div className="dropdown header_user">
                                                <span>Mevcut üye girişi </span> 
                                                    <button type="button" className="dropdown-toggle" data-toggle="dropdown">
                                                        <img src={require('../assets/images/user_vector.png')} />
                                                    </button>
                                                    {token &&
                                                        <div className="dropdown-menu">
                                                            <div className="dropdown-item">
                                                                {userName}
                                                            </div>
                                                            <a className="dropdown-item" href="https://panel.koytola.com">My Dashboard</a>
                                                            <div onClick={logout} style={{ cursor: 'pointer' }}>Logout</div>
                                                        </div>}
                                                </div>
                                            </div>
                                            <p className="word_header_p">-ücretsiz -kolay -hızlı </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </header >
            </Flex>
        </Wrapper>
        <SubCategory />
    </>
    )
};

export default Header;