import React from 'react'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import { RoutesUrls } from './utils/routeUrls'
import { Home } from './pages/home'
import CompanyProfile from './components/pages/CompanyProfile/CompanyProfile';
import Product from './components/pages/product/Product'
import ProductDetails from './components/pages/product/ProductDetails'
import Blogs from './components/pages/BlogDetail/Blogs'
import BlogShow from './components/pages/BlogDetail/BlogShow'
import PageNOtFound from './components/PageNotFound/PageNotFound'
import ShipOrder from './components/pages/home/ShipOrder'
import Searchcargo from './components/pages/home/Searchcargo'
import SellerReq from './components/SellerReq'
import Faq from './components/Faq'
import Contact from './components/Contact'
export const Routes: React.FC = () => {
    return (
        <Switch>
                <Route path={RoutesUrls.HOME} component={Home} exact={true} />
                <Route path='/product/:id/:search' component={Product} exact={true} />
                <Route path='/product/:id' component={Product} exact={true} />
                <Route path='/cargo' component={Searchcargo} exact={true} />
                <Route path='/contact' component={Contact} exact={true} />
                <Route path='/supplier-sourcing' component={SellerReq} exact={true} />
                <Route path='/cargo-result' component={ShipOrder} exact={true} />
                <Route path="/blogs" component={Blogs} exact={true} />
                <Route path="/faq" component={Faq} exact={true} />
                <Route path={RoutesUrls.PRODUCTS_DETAILS} component={ProductDetails} exact={true} />
                <Route path={RoutesUrls.BLOG_DETAILS} component={BlogShow} exact={true} />
                <Route path={RoutesUrls.CompanyProfile+ "/:id"} component={CompanyProfile} />
                <Route path="*" component={PageNOtFound} />
        </Switch>
    )
}

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    
`