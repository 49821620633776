import React from 'react'
import { useHistory } from 'react-router-dom';
const LatestPlateform = () => {
    const history = useHistory()
    return (
        <section className="sec_counter sec_space">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="counter_heading"> 
                        <h3 className="top_hed">B2b Wholesale Marketplace</h3>
                            <h3>Over a thousand items are available for sale on Koytola platform at any given time.</h3>
                            <p>If you don’t see the item you want, don’t worry! Just  <a href="javascript:void(0)" onClick={() => history.push('/supplier-sourcing')}>contact us.</a>  We will direct the right suppliers to you. </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default LatestPlateform;