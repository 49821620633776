import React from 'react';
const WhatElse = () => {
    return (
        <>
            <section className="sustainability" id="Koytola_supports">
                <div className="container">
                    <div className="row" >
                        <div className="col-md-12">
                            <h3><a href="#cargo">Koytola supports <strong> equality, fair-trade</strong> and <strong>sustainability</strong>.</a></h3>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default WhatElse;