import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button,CircularProgress } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Avatar from '@material-ui/core/Avatar';
import { gql, useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import YouMightLike from './YouMightLike';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import { useParams } from 'react-router';
import ReactImageZoom from 'react-image-zoom';
import PageNOtFound from '../../PageNotFound/PageNotFound';
moment().format();
const ProductOneGet = gql`
query product(
  $slug:String!
  ){
  product(slug: $slug) {
    id
    name
    totalReview
    totalRatting
    category {
      id
      name
      description
      parent {
        id
        name
        parent {
          id
          name
        }
      }
    }
    reviews(first:40){
      edges{
        node{
          id
      name
      rating
      location
      review
      createdAt
        }
      }
    }
    hsCode
    slug
    isPublished
    description
    creationDate
    isActive
    delivery
    deliveryTimeOption
    packaging
    unit
    unitPrice
    unitNumber
    currency
    deliveryTime
    minimumOrderQuantity
    descriptionPlaintext
    description
    organic
    rosetter{
      name
      image{
        url
      }
    }
    privateLabel
    company{
      name
      slug
      id
      representativeName
      photo{
        url
      }
      representative{
        photo{
          url
        }
        name
      }
    }
    images {
      id
      image {
        url
        alt
      }
      altText
      order
      ppoi
    }
  }
}`;
const ReviewsCreate = gql`
mutation productReviewsCreate(
  $productId:ID!
  $name:String!
  $rating:Float
  $location:String!
  $review:String!
){
  productReviewsCreate(productId:$productId,
input:{
      name: $name
rating: $rating
location: $location
review: $review
}){
productReview{
id
name
product{
  id
  name
}
rating
location
review
createdAt
}
productErrors{
field
message
}
}
}
`;

const useStyles = makeStyles((theme: Theme) => ({
  products_information: {
    paddingBottom: "15px !important",

  },
  Ckediter_product: {
    minHeight: "500px !important"
  },
  table: {
    border: "solid 0.5px black  !important"
  },
  Line_process: {
    height: "2px",
    color: "#7D3CDC",
    float: "left",
    width: "100%"
  },
  Upload_done: {
    color: "#44CC72",
    float: "right",
  },
  upload_image: {
    width: "59px",
    height: "37px",
    borderRadius: "6px",
    float: "left",
  },
  Follow_button: {
    height: "16px !important",
    border: "0.8px solid #D0D0D0 !important",
    boxSizing: "border-box",
    borderRadius: "12px !important"
  },

  tages_button: {
    height: "25px !important",
    border: "1px solid #979797 !important",
    boxSizing: "border-box",
    borderRadius: "12.5px !important",
    marginTop: "10px !important",
    marginRight: "10px !important",
  },
  Tital_button: {
    height: "16px !important",
    border: "0.8px solid #D0D0D0 !important",
    boxSizing: "border-box",
    borderRadius: "12px !important",
    marginRight: "10px !important",
  },
  Heading_cart: {
    fontSize: "30px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    textAlign: "center",
    paddingBottom: "20px",
    paddingTop: "20px",
  },
  view_store_cart: {
    height: "15px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "10px",
    lineHeight: "15px",
    color: "#888888",
  },
  category_name: {
    width: "102px",
    height: "14px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "9px",
    lineHeight: "13px",
    letterSpacing: " 0.1em",
    color: " #7A38DA"
  },
  product_name_show: {
    width: "80% !important"
  },

  Sub_titel: {
    height: "16px !important",
    border: "0.8px solid #908E8E !important",
    boxSizing: "border-box",
    borderRadius: "12px !important",
    marginRight: "5px !important"
  },
  Sub_titel_show: {
    paddingBottom: "40px",
  },
  incrjment_decrjment: {
    paddingBottom: "30px !important",
    paddingTop: "30px !important",
    display: "inline-block",
  },
  incrjment_decrjment_button: {
    width: "10px !important",
    height: "27px !important",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    minWidth: "40px !important",
  },
  incrjment_decrjment_count: {
    width: "60px",
    color: "black !important",
    height: "27px !important",
    background: "transparent !important",
    opacity: "1 !important",
    border: "1px solid #000000 !important",
    boxSizing: "border-box",
    borderRadius: "6px  !important",
  },
  add_To_cart: {
    width: "172px !important",
    height: " 42px !important",
    border: "1px solid #7A38DA !important",
    boxSizing: "border-box",
    borderRadius: "21px  !important",
    marginLeft: "40px !important",
  },
  FormFiledError: {
    color: "red",
    display: "block",
    marginTop: "5px",
  },
  Product_data_show: {
    width: "81px",
    height: "27px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "27px",
    color: " #444444"
  },
  Product_data_name: {
    fontSize: "10px",
    lineHeight: "12px",
    color: " #444444"
  },
  input_filed: {
    marginBottom: "10px !important",
    marginTop: "20px !important",
    display: "block !important"

  },
  socialLinks: {
    float: "right",
    marginRight: "10px !important"
  },
  Reviewstab: {
    backgroundColor: "transparent !important",
    color: "#000 !important",
    boxShadow: "none !important",

  },
  colSlug: {},
  colName: {
    paddingLeft: 0
  },
  colDateCreated: {
    textAlign: "center"
  },
  colPublished: {
    textAlign: "center"
  },
  colActive: {
    textAlign: "center"
  },
  tableRow: {
    cursor: "pointer"
  },
  flotLeft: {
    float: "left",
    padding: "5px",
    width: "100%"
  },
  mainImage: {
    width: "100%",
    borderRadius: "10px",
    minHeight: "300px",
    maxHeight: "300px"
  },
  subImage: {
    width: "150px",
    minHeight: "100px",
    maxHeight: "100px",
    float: "left",
    marginRight: "20px",
    borderRadius: "5px",

  },
  Image: {
    borderRadius: "5px",
    minHeight: "100px",
    maxHeight: "100px",
    width: "150px",
  },
  ImageshowEdit: {
    minHeight: "100px",
    maxHeight: "100px",
    width: "150px",
    objectFit: "cover",
  },
  ImageMain: {
    width: "100%",
    minHeight: "300px",
    maxHeight: "300px",
    borderRadius: "10px",

  },
  margin: {
    margin: "10%",
  },
  input: {
    display: 'none',
  },
  subImageShow: {
    mixHeight: "100px",
    padding: "0px !important",
    overflowX: "auto",
    display: "flex",
    alignItems: "center",
    overflowY: "hidden",
  },
  priceShow: {
    width: "33%",
    float: "left",
    display: "block",
  },
  DescriptionShow: {
    overflow: "auto",
    maxHeight: "200px",
    width: "100%",
  },
  totalAmount: {
    height: "21px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "21px",
    color: "#444444",
    float: "right"
  },
  flotRight: {
    float: "right"
  },
  showDetaile: {
    width: "100%",
  },
  marginLeft: {
    marginLeft: "20px"
  },
  error: {
    color: "red"
  },
  DeleteButton: {
    float: "right",
    color: "#8a8383"
  },
  MinHeight: {
    minHeight: "50px",
  },
  PageHeading: {
    fontSize: "30px",
    cursor: "pointer !important"
  },
  saveButton: {
    marginRight: "10px !important",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 500,
  },
  margenTop: {
    float: "left",
    width: "auto"

  },
  margenTop2: {
    float: "left",
    width: "auto",
    marginTop: "-10px"
  },
  paddingBottom2: {
    paddingBottom: "0px"
  },
  flotLeft2: {
    float: "left",
  },
  uplaodbtn2: {
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    height: "170px",
    borderColor: "#7a38da !important",
    backgroundColor: "#F3E3FF !important"
  },
  uplaodbtn2icon: {
    border: "solid 2px #7a38da !important",
    boxShadow: "none !important",
    backgroundColor: "transparent !important"
  },
  dropImage_icon: {
    fill: "#7D3CDC",
    fontSize: "40px",
  },
  dropImage_pre: {
    marginBottom: "0",
  },
  dropImage: {
    border: "dashed 1px #7D3CDC",
    float: "left",
    width: "100%",
    height: "170px",
    backgroundColor: "#ffffff",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: "15px",
    outline: "0px !important",
  },
  products_save: {
    borderTop: "solid 1px #ddd",
    paddingLeft: "0 !important",
    marginLeft: "40px !important",
  },
  dropImage_show: {
    position: "relative",
    width: "25%",
    height: "190px",
    float: "left",
    padding: "10px",
  },
  drop_image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "10px",
  },
  dropImage_delete_button: {
    position: "absolute",
    top: "5px !important",
    right: "10px !important",
    zIndex: 9,
    color: "#ffffff !important",
    padding: "10px",

  },
  unit_select: {
    width: "70%",
    borderRadius: " 50px",
    padding: "3px 5px",
    outline: "0px",
    marginLeft: "10px",

  },
  unit_main: {
    display: "inline-block",
    width: "140px",
    marginTop: "18px",
    marginLeft: "10px",
    float: "right",
  },
  buttonList: {
    margin: "10px",

  },
  avatar: {
    backgroundColor: "red",
  },
  media: {
    height: 300,
  },
  Link_in_cart: {
    height: "14px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "9px",
    lineHeight: "13px",
    letterSpacing: "0.1em",
    color: "#7A38DA",
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  varente_show: {
    width: "378px",
    height: "42px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "21px",
    color: "#2C2C2C",
  },
  rating_show_card: {
    paddingTop: "20px",
    paddingBottom: "20px"
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  rating: {
    marginTop: '20px'
  },
  FavoriteIconClass: {
    position: "relative",
  },
  Favorite_icon: {
    position: "absolute",
    right: "10px",
    top: "10px",
  },
  Save_icon: {
    position: "absolute",
    right: "10px",
    top: "40px",
  },
  ViewCompanyButton: {
    float: "right",
    borderRadius: "50px",
    fontSize: "14px",
    textTransform: "capitalize",
    color: "#000",
    fontWeight: 600,
    padding: "6px 20px",
  },
  Rosettes_button: {
    float: "left",
    margin: "10px",
    borderRadius: "15px",
  },
}));

export default function ProductDetails() {
  let ProductInfo: any = useParams();
  window.scrollTo(0, 0)
  const history = useHistory();
  const goToCompanyPage = (value: any) => {
    history.push("/company-profile/" + value);
  }
  const [productReviewsCreate] = useMutation(ReviewsCreate);
  const [FormState, setFormState] = useState({
    name: '',
    rating: 0,
    review: '',
    location: '',
  });
  const classes = useStyles();
  const [SpecificationsShow, setSpecificationsShow] = useState(false);
  const [SpecificationsData, setSpecificationsData] = useState('');
  const [MainImage, setMainImage] = useState('');
  const [NameError, setNameError] = useState(false);
  const [RatingError, setRatingError] = useState(false);
  const [oid, setOid] = useState('');
  function setid(id) {
    setOid(id);
    setMainImage('');
    window.scrollTo(0, 0)
  }
  oid != ProductInfo?.id && setid(ProductInfo?.id)
  const { loading, error, data, refetch } = useQuery(ProductOneGet, {
    variables: {
      slug: ProductInfo?.id
    }
  });
  if (loading) return <div className="loading_seccestion"><CircularProgress /></div>
  const product = data?.product?.images[0]?.image?.url ? data?.product?.images[0]?.image?.url : '';
  const CheckSpecifications = () => {
    Object.keys(JSON.parse(data?.product?.description)).map((key, i) => {
      JSON.parse(data?.product?.description)[key]?.trim() && setSpecificationsShow(true)
    })
  }
  if (data?.product?.description) {
    if (data?.product?.description != SpecificationsData) {
      setSpecificationsShow(SpecificationsShow => false)
      setSpecificationsData(SpecificationsData => data?.product?.description)
      CheckSpecifications()
    }
  }
  document.title = data?.product?.name;
  return (
    <>
      {data?.product?.slug &&
        <>
          <div className="top_bar_nav">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <span >Home{"  /  "} </span>
                  <span >Category {"  /  "} </span>
                  {data?.product?.category?.parent?.parent?.name &&
                    <span >{data?.product?.category?.parent?.parent?.name?.toLowerCase()}{"  /  "} </span>
                  }
                  {data?.product?.category?.parent?.name &&
                    <span >{data?.product?.category?.parent?.name?.toLowerCase()} {"  /  "} </span>
                  }
                  {data?.product?.category?.name &&
                    <span >{data?.product?.category?.name?.toLowerCase()}</span>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="products_main_outer">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="product_image_outer">
                    <div className="image_icon">
                      <FavoriteBorderIcon />
                      <BookmarkBorderIcon />
                      <span>+{data?.product?.images?.length}</span>
                    </div>
                    {(MainImage || product) && <ReactImageZoom
                      img={MainImage ? MainImage : product}
                      scale={1}
                    />}
                  </div>
                  <div className="products_sub_image">
                    {data?.product?.images?.map((res: any, index: any) =>
                      <div className="subImage"
                        onClick={() => {
                          setMainImage(res?.image?.url)
                        }}
                      >
                        <img src={res?.image?.url} className={classes.Image} />
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="product_dtl_main">
                    <div className="product_name_header">
                      <div className="product_detail_heading">
                        <span className="product_name">
                          {data?.product?.name}
                        </span>
                        <span className="sub_heading_ik"> by <a
                          onClick={(e) => goToCompanyPage(data?.product?.company?.slug)}
                        > {data?.product?.company?.name}</a></span>
                      </div>
                      <div className="ViewCompanyouter">
                        <Button variant="outlined"
                          className={classes.ViewCompanyButton}
                          onClick={(e) => goToCompanyPage(data?.product?.company?.slug)}
                        >
                          View Company
                        </Button>
                      </div>
                    </div>

                    <div className="priceShow">
                      <span className="Product_data_name">Price</span>
                      <span className="Product_data_show">
                        {data?.product?.unitPrice}
                      </span>
                      <span className="count">
                        {data?.product?.currency}/{data?.product?.unit?.toLowerCase()}
                      </span>
                    </div>
                    <div className="priceShow">
                      <span className="Product_data_name">MOQ</span>
                      <span className="Product_data_show">
                        {data?.product?.minimumOrderQuantity} {data?.product?.unit?.toLowerCase()}
                      </span>
                    </div>
                    {data?.product?.hsCode &&
                      <div className="priceShow">
                        <span className="Product_data_name">HS code</span>
                        <span className="Product_data_show">
                          {data?.product?.hsCode}
                        </span>
                      </div>}
                    {(data?.product?.deliveryTime ? data?.product?.deliveryTime : data?.product?.deliveryTimeOption?.replaceAll("_", " ").toLowerCase()) &&
                      <div className="priceShow">
                        <span className="Product_data_name">Delivery</span>
                        <span className="Product_data_show">
                          {data?.product?.deliveryTime ? data?.product?.deliveryTime : data?.product?.deliveryTimeOption?.replaceAll("_", " ").toLowerCase()}
                        </span>
                      </div>}
                    <div className="showDetaile">
                      <div className="avtar_main">
                        <span className="top_saller">Seller</span>
                        <Avatar alt="Remy Sharp" src={data?.product?.company?.photo?.url} />
                        <div className="prod_user_detail">
                          <span className="user_name">{data?.product?.company?.name}</span>
                          <span className="pro_represnt">{data?.product?.company?.representativeName}</span>
                        </div>
                      </div>
                      <div className="show_detail_right">
                        <Button variant="outlined"
                          className="ViewCompanyButton"
                          onClick={(e) => goToCompanyPage(data?.product?.company?.slug + "#companycontact")}
                        >
                          message
                        </Button>
                        <span className="view_company" onClick={(e) => goToCompanyPage(data?.product?.company?.slug)}>View Company
                          <ArrowForwardIosIcon fontSize="small" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {(data?.product?.rosetter?.length + data?.product?.descriptionPlaintext?.trim().length) > 0 &&
            <div className="profile_mission">
              <div className="container">
                {data?.product?.descriptionPlaintext?.trim().length > 0 &&
                  <div className="profile_heading_maltipal merhaba">
                    <h2>Description</h2>
                  </div>
                }
                <div className="row">
                  {data?.product?.descriptionPlaintext?.trim().length > 0 &&
                    <>
                      <div className="col-md-4">
                      </div>
                      <div className="col-md-8">
                        <div className="mission_inner_contant">
                          <span> {data?.product?.descriptionPlaintext}</span>
                        </div>
                      </div>
                    </>
                  }
                  {data?.product?.rosetter?.length > 0 &&
                    <div className="col-md-6">
                      <div className="product_rosettes">
                        <span className="product_rosettes_heading">Rosettes</span>
                        <div className="rosettes_button_pr">
                          {data?.product?.rosetter.map((res: any, index: any) =>

                            <Button
                              variant="outlined"
                              className={classes.Rosettes_button}
                              startIcon={<img src={res?.image?.url} style={{ height: "20px", width: "20px", borderRadius: "20px" }} />}
                            >
                              {res.name.toLowerCase()}
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          }
          {SpecificationsShow &&
            <div className="specifications_main">
              <div className="container">
                <h1 className="detail_main_heading">Specifications</h1>
                <div className="specification_colum_main">
                  {Object.keys(JSON.parse(data?.product?.description)).map((key, i) =>
                    <>
                      {JSON.parse(data?.product?.description)[key]?.trim() &&
                        <div className="specification_colums row">
                          <div className="specification_colum col-md-6" >
                            <p>{key}</p>
                          </div>
                          <div className="specification_colum col-md-6">
                            <span>{JSON.parse(data?.product?.description)[key]}</span>
                          </div>
                        </div>
                      }
                    </>
                  )}
                </div>
              </div>
            </div>
          }
          {((data?.product?.deliveryTimeOption === null ? 0 : 0 + (data?.product?.deliveryTime ? data?.product?.deliveryTime?.trim().length : data?.product?.deliveryTimeOption?.trim().length)) + data?.product?.packaging?.trim().length) > 0 &&
            <div className="detail_delivery">
              <div className="container">
                <h1 className="detail_main_heading">Packaging & Delivery</h1>
                <div className="row">
                  <div className="col-md-6">
                    <p className="delivery_head">Packaging</p>
                  </div>
                  <div className="col-md-6">
                    <p className="delivery_head">Delivery</p>
                  </div>
                  <div className="col-md-6">
                    {data?.product?.packaging?.trim().length > 0 &&
                      <span dangerouslySetInnerHTML={{ __html: data?.product?.packaging }} ></span>
                    }
                  </div>
                  <div className="col-md-6">
                    {(data?.product?.deliveryTime ? data?.product?.deliveryTime?.trim().length : data?.product?.deliveryTimeOption?.trim().length) > 0 &&
                      <span>{data?.product?.deliveryTime ? data?.product?.deliveryTime : data?.product?.deliveryTimeOption?.replaceAll("_", " ").toLowerCase()}
                      </span>
                    }
                  </div>
                </div>
              </div>
            </div>
          }
          <YouMightLike
            id={data?.product?.category?.id}
            pid={data?.product?.id}
          />
          <div className="resource_btns_main">
            <div className="container">
              <h1 className="detail_main_heading">Need help?</h1>
              <div className="resource_btns">
                <Button className="Rosettes_button" variant="outlined" onClick={() => history.push("/faq")}> Visit help center </Button>
                <Button className="Rosettes_button" variant="outlined" onClick={() => history.push("/contact")}> Contact us </Button>
              </div>
            </div>
          </div>
        </>}
      {!data?.product?.slug &&
        <PageNOtFound />
      }
    </>
  );
}
