import React, { useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import "react-multi-carousel/lib/styles.css";
import { gql, useQuery } from '@apollo/client';
import moment from 'moment';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useHistory } from 'react-router-dom';
moment().format();
const CategoryYouMightLike = gql`
    query category(
      $categoryId:ID!
      ){
        category(categoryId: $categoryId) {
            id
            name
            products(first: 10) {
                edges {
                    node {
                    id
                    name
                    slug
                    images{
                        image{
                        url
                        }
                    }
                    }
                }
            }
        }
        }`;
interface CategoryId {
    id: string;
    pid: string;
}
const YouMightLike: React.FC<CategoryId> = ({
    id,
    pid
}) => {
    const history = useHistory();
    const [OldProductid, setOldProductid] = useState('' as any);
    const goToProductDetailPage = (value: any) => {
        history.push("/product-details/" + value);
    }
    const { loading, error, data, refetch } = useQuery(CategoryYouMightLike, {
        variables: {
            categoryId: id
        }
    });
    if (OldProductid != id) {
        setOldProductid(id)
        refetch()
    }
    if (loading) return <div className="loading_seccestion"><CircularProgress /></div>
    const options = {
        autoplay: true,
        responsiveClass: true,
        nav: false,
        loop: false,
        dots: false,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 2,
            },
            600: {
                items: 3,
            },
            800: {
                items: 4,
            },
            1000: {
                items: 5,

            }
        },
    };
    return (
        <>
            {data && data?.category?.products?.edges?.length > 1 &&
                <div className="you_might_like sec_space">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-6">
                                <div className="heading_like">
                                    <h1 className="detail_main_heading">You might like</h1>
                                </div>
                            </div>
                            <div className="col-xs-6">
                                <div className="you_tag">
                                    <a href="javascript:void(0)">view all</a>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <OwlCarousel className='owl-theme' margin={10} items={1} {...options}>
                                    {data?.category?.products?.edges?.map((res: any, index: any) =>
                                        <>
                                            {pid != res?.node?.id &&
                                                <div className="product_inner" onClick={(e) => goToProductDetailPage(res?.node?.slug)}>
                                                    <img src={res?.node?.images[0]?.image?.url}
                                                        className="slider_image"
                                                    />
                                                    <p>{res?.node?.name}</p>
                                                </div>
                                            }
                                        </>
                                    )}
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};
YouMightLike.displayName = "YouMightLike";
export default YouMightLike;