import React from 'react';
import { CircularProgress} from '@material-ui/core';
import CurrencyConverter from '../../currencyConverter/CurrencyConverter';
import { gql, useQuery } from '@apollo/client';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import SearchProduct from '../../SearchProduct';
import { useHistory } from 'react-router-dom';
import * as _ from 'underscore';
export interface searchvalue {
    key: string
}
export var searchdata: searchvalue = {
    key: ''
};
const ALLCATEGORY = gql`
query {
    categories(first: 100) {
      edges {
        node {
          id
          name
          backgroundImage {
            url
          }
        }
      }
    }
  }
  `;

export const BannerSection = () => {
    window.scrollTo(0, 0)
    const history = useHistory();
    const goToProductPage = () => {
        searchdata.key &&
            history.replace("/product/search/" + searchdata.key)
    }
    const { loading, error, data } = useQuery(ALLCATEGORY);
    if (loading) return <div className="loading_seccestion"><CircularProgress /></div>
    if (error) return <p>Error: Something is wrong! </p>
    const values = data?.categories?.edges;
    const n = 5;
    var lists = _.groupBy(values, function (element: any, index: any) {
        return (Math.floor(index / n))
    });
    lists = _.toArray(lists);
    const options = {
        autoplay: true,
        responsiveClass: false,
        loop: true,
        dots:false,
        responsive: {
            0: {
                items: 4,
                nav: false,
                margin:20
            },
            768: {
                items: 4,
                nav: false,
                margin:10

            },
            1000: {
                items: 5,
                nav: false,
                slideBy:5
            }
        },
    };

    return (
        <>
            <CurrencyConverter />
            <div className="home_slider_main">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-6">
                            <div className="banner_contant">
                                <h1><span>Koytola</span>  ‘Wholesale Marketplace’</h1>
                                <p>Buy direct from <br />Manufacturers in <span> Turkey</span></p>
                                <div className="banner_contant_type">
                                    <div className="products_search">
                                        <SearchProduct />
                                        <button className="ko_banner_btn" onClick={goToProductPage}>KO </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-6">
                            <div className="home_top_slider">
                            <OwlCarousel className='owl-theme' margin={10} nav items={1} {...options}>
                                {data?.categories?.edges?.map((items: any) =>
                                                <img src={items?.node?.backgroundImage?.url} />
                                )}
                            </OwlCarousel>
                            </div>
                            <div className="home_top_img d-xs-none">
                                <img src={require('../../../assets/images/home_top_img.png')} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
