
import React, { useState } from 'react'
import "react-multi-carousel/lib/styles.css";
import Avatar from '@material-ui/core/Avatar';
import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { gql, useQuery } from '@apollo/client';
import MakeOrder from './MakeOrder';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useHistory } from 'react-router-dom';
import { useParams } from "react-router-dom";
import PageNotFound from '../../PageNotFound/PageNotFound';
export interface SocialResponsibilitySortingInput {
    field?: Date;
    direction: string | null;
}
export enum ProductListUrlFiltersEnum {
    DATE_CREATED = "DATE_CREATED",
    DESC = "DESC",
}
const COMPANIES = gql`
query company($slug:String!
    $after: String
    $before: String
    $first: Int
    $last:Int){
    company(slug:$slug) {
        id
        name
        contentPlaintext
        isActive
        isVerified
        isPublished
        noOfEmployees
        type
        exportCountries{
            code
            country
            latitude
            longitude
          }
        industry{
          name
        }
        seoDescription
        slug
        seoTitle
        logo {
          url
          alt
        }
        tradeShow{
            id
            city
            name
            year
            createdAt
        }
        logoAlt
        foundedYear
        city
        address {
          id
          city
          country {
            code
            country
            latitude
            longitude
          }
         cityArea
          postalCode
          firstName
          companyName
          countryArea
          addressName
          streetAddress1
          streetAddress2
        }
        products(after: $after
            before: $before
            first: $first
            last:$last
            ) {
                pageInfo {
                    endCursor
                    hasNextPage
                    hasPreviousPage
                    startCursor
                  }
            edges {
                node {
          id
          name
          description
          creationDate
          images{
              image{
              url
          }
        }
          isPublished
          isActive
          slug
          seoTitle
          seoDescription
          hsCode
                }
            }
        }
        user {
          id
          email
          firstName
          lastName
          userId
        }
        representativeName
          photo{
            url
          }
        representative {
          id
          position
          linkedinUrl
          name
          photo{
              url
          }
        }
        videos {
          youtubeUrl
          id
          description
          sortOrder
          video
        }
        seoTitle
        rosetter{
            name
            image{
              url
              alt
            }
          }
        categories {
          id
          name
          description
          slug
          seoTitle
          seoDescription
        }
        creationDate
        certificates {
            id
            name
            type {
                name
                image{
                  url
                }
              }
            certificate
            sortOrder
        }
        brochures {
          id
          description
          sortOrder
          brochure
        }
        
        images{
            id
            image {
              url
              alt
            }
            description
          }
      membership
      }
    }`;
const useStyles = makeStyles((theme: Theme) => ({
    avatar: {
        backgroundColor: "red",
    },
}));
const CompanyProfile = () => {
    const classes = useStyles();
    const history = useHistory();
    const goToProductDetailPage = (value: any) => {
        history.push("/product-details/" + value);
    }
    const companyId: any = useParams()
    const [pageshow, setPageshow] = useState({
        BeforeId: '',
        AfterId: '',
        first: 6,
        last: 0
    })
    const { loading, data, refetch } = useQuery(COMPANIES, {
        variables: {
            slug: companyId.id,
            first: pageshow.first > 0 ? pageshow.first : null,
            last: pageshow.last > 0 ? pageshow.last : null,
            after: pageshow.AfterId,
            before: pageshow.BeforeId
        }
    });
    if (!data) return <div className="loading_seccestion"><CircularProgress /></div>
    document.title = data?.company?.name;
    return (
        <>
            {data?.company != null &&
                <div className="cont_space_remove">
                    <div className="company_top_space container">
                        <div className="row">
                            <div className="col-md-5">
                                <div className="company_profile_head">
                                    <div className="company_logo">
                                        <img src={data?.company?.logo?.url} />
                                    </div>
                                    <h2>{data?.company?.name}</h2>
                                    <a href="javascript:void(0)" className="share_icon">
                                        <img src={require('../../../assets/images/share_icon.png')} />
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-3"></div>
                            <div className="col-md-4">
                                <div className="profile_right">
                                    <div className="flex-row">
                                        <div className="supplier_infoaria">
                                            <div className="supplier_pic">
                                                <Avatar aria-label="recipe" className={classes.avatar}><img src={data?.company?.photo?.url} /></Avatar>
                                            </div>
                                            <div className="supplier_info">
                                                <h5>{data?.company?.representativeName}</h5>
                                            </div>
                                        </div>
                                        <div className="zkitcard_foright">
                                            <a className="btn-follow"
                                                href="#companycontact"
                                            >Contact</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="latest_plat_main">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4 col-xs-6">
                                    <div className="latest_plat_box">
                                        <span>Founded </span>
                                        <h2>{data?.company?.foundedYear}</h2>
                                    </div>
                                </div>
                                <div className="col-md-4 col-xs-6 border_after_before">
                                    <div className="latest_plat_box">
                                        <span>City </span>
                                        <h2>{data?.company?.city} </h2>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="latest_plat_box">
                                        <span>Team of </span>
                                        <h2>{data?.company?.noOfEmployees}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {data?.company?.rosetter?.length > 0 &&
                        <div className="profile_tag_main">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="profile_tag_header resettes">
                                            <span className="profile_tag">Rosettes</span>
                                        </div>
                                        <div className="company_rosettes_box_outer">
                                            {data?.company?.rosetter.map((res: any) => (
                                                <div className="commpany_rosettes_box">
                                                    <img src={res?.image?.url} />
                                                    <span>{res?.name.toLowerCase()}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    <div className="products_gallery">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <div className="profile_heading_maltipal">
                                        <h2>Products</h2>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="products_gallery_tag">
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {data?.company?.products?.edges?.map((res: any, index: any) => (
                                    <div className="col-md-4">
                                        <div className="product_gallery_image" onClick={(e) => goToProductDetailPage(res?.node?.slug)}>
                                            <img src={res?.node?.images[0]?.image?.url} />
                                            <p>{res?.node?.name}</p>
                                        </div>
                                    </div>

                                ))}
                            </div>
                            <div className="gallery_btns">
                                {data?.company?.products?.pageInfo?.hasPreviousPage &&
                                    <Button disabled={!data?.company?.products?.pageInfo?.hasPreviousPage} onClick={() => {
                                        setPageshow({
                                            ...pageshow,
                                            AfterId: '',
                                            BeforeId: data?.company?.products?.pageInfo?.startCursor,
                                            first: 0,
                                            last: 6
                                        })
                                    }}>Pre</Button>}
                                {data?.company?.products?.pageInfo?.hasNextPage &&
                                    <Button disabled={!data?.company?.products?.pageInfo?.hasNextPage} onClick={() => {
                                        setPageshow({
                                            ...pageshow,
                                            AfterId: data?.company?.products?.pageInfo?.endCursor,
                                            BeforeId: '',
                                            first: 6,
                                            last: 0
                                        })
                                    }}>next</Button>}
                            </div>
                        </div>
                    </div>
                    {data?.company?.contentPlaintext?.length > 0 &&
                        <div className="profile_mission">
                            <div className="container">
                                <div className="profile_heading_maltipal merhaba">
                                    <h2>Merhaba</h2>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                    </div>
                                    <div className="col-md-8">
                                        <div className="mission_inner_contant">
                                            <span> {data?.company?.contentPlaintext}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    {(data?.company?.brochures?.length + data?.company?.images?.length + data?.company?.videos?.length) > 0 &&
                        <div className="gallery_slider_main">
                            <div className="container">
                                <div className="profile_gallery_heading">
                                    <h2>Visuals</h2>
                                </div>
                                <div className="row">
                                    {(data?.company?.brochures?.length + data?.company?.images?.length + data?.company?.videos?.length) > 1 &&
                                        <>
                                            <div className="col-md-12">
                                                {data?.company?.brochures?.map((item, i) => {
                                                    return (
                                                        <>
                                                            <div className="col-sm-4">
                                                                <img src={item?.brochure} /></div>
                                                        </>
                                                    )
                                                })}
                                                {data?.company?.images?.map((item, i) => {
                                                    return (<div className="col-sm-4">
                                                        <img src={item?.image?.url} /></div>
                                                    )
                                                })}
                                                {data?.company?.videos?.map((item, i) => {
                                                    return (<div className="col-sm-4">
                                                        <video controls>
                                                            <source src={item?.video} type="video/mp4" />
                                                        </video></div>
                                                    )
                                                })}
                                            </div>
                                        </>
                                    }
                                    {(data?.company?.brochures?.length + data?.company?.images?.length + data?.company?.videos?.length) === 1 &&
                                        <div className="col-md-12">
                                            <div className="gallery_slider">
                                                {data?.company?.brochures?.map((item, i) => {
                                                    return (
                                                        <img src={item?.brochure} />
                                                    )
                                                })}
                                                {data?.company?.images?.map((item, i) => {
                                                    return (
                                                        <img src={item?.image?.url} />
                                                    )
                                                })}
                                                {data?.company?.videos?.map((item, i) => {
                                                    return (
                                                        <video controls>
                                                            <source src={item?.video} type="video/mp4" />
                                                        </video>
                                                    )
                                                })}
                                            </div>
                                        </div>}
                                </div>
                            </div>
                        </div>}
                    <MakeOrder />
                </div>
            }
            {data?.company === null &&
                <PageNotFound />
            }
        </>
    )
}
export default CompanyProfile;