import { TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
const Country = gql`
  query{
      site {
        countries {
          country
          code
        }
      }
    }
    `;
export interface ordership {
    shipkg: number,
    shipto: string,
    shipcountry: string,
    shipfrom: string,
}
export var shipdata: ordership = {
    shipkg: 0,
    shipcountry: '',
    shipfrom: '',
    shipto: ''
};
const Logistics = () => {
    const history = useHistory();
    const { loading, data } = useQuery(Country);
    const [formdata, setFormdata] = useState({
        shipfrom: 'Turkey',
        shipto: '',
        shipcountry: '',
        shipkg: 0,
    })
    const [formerror, setFormerror] = useState({
        shipfrom: '',
        shipto: '',
        shipkg: '',
    })
    useEffect(() => {
        shipdata.shipkg = formdata?.shipkg
        shipdata.shipto = formdata?.shipto
        shipdata.shipfrom = formdata?.shipfrom
        shipdata.shipcountry = formdata?.shipcountry
    }, [formdata])
    const submitform = () => {
        setFormerror({
            ...formerror,
            shipfrom: formdata.shipfrom ? "" : "This field required",
            shipto: formdata.shipto ? "" : "This field required",
            shipkg: formdata.shipkg ? (formdata.shipkg > 300 ? "Maximum 300 kg allowed" : (!(formdata.shipkg > 0) ? "Greater than 0 kg allowed" : "")) : formdata.shipkg === 0 ? "Greater than 0 kg allowed" : "This field required",
        })
        if (formdata.shipto && formdata.shipkg && !(formdata.shipkg > 300) && formdata.shipkg > 0) {
            history.push("cargo-result");
        }
    }
    return (
        <>
            <section className="logistics sec_space" id="cargo">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="section_heading">
                                <h2>Cargo</h2>
                            </div>
                        </div>
                        <div className="col-md-8 d-xs-none">
                            <div className="selling more">
                                <h3>Calculate your shipping rate!</h3>
                                <span>You deserve fast shipping at a very affordable price..
                                    <br />
                                    Here you can compare local and global couriers rates up to 300 KGs.
                                </span>
                            </div>
                        </div>
                        <div className='col-xs-12 d-sm-none d-xs-block'>
                            <p style={{ color: "#000", paddingLeft: 30, fontSize: 13, fontWeight: 500, marginBottom: 20 }}>Calculate your shipping rate! <br />
                                You deserve fast shipping at a very affordable price..</p>
                        </div>
                    </div>
                    <div className="kargo_hespla_outer">
                        <div className="kargo_hespla shipping-calculatoin-wrapper">
                            <h1> Kargo Hesapla / Get Shipping Rates</h1>
                            <div className="row">
                                <div className="col-xs-6">
                                    <div className="kargo_input">
                                        <TextField
                                            disabled
                                            color="primary"
                                            variant="outlined"
                                            name="shipfrom"
                                            placeholder="Nereden / TURKIYE"
                                            fullWidth
                                        />
                                    </div>
                                </div>
                                <div className="col-xs-6">
                                    <div className="kargo_input">
                                        <Autocomplete
                                            fullWidth
                                            freeSolo={true}
                                            options={data?.site?.countries ? data?.site?.countries : []}
                                            getOptionLabel={(option: any) => option?.country}
                                            onChange={(event: object, values: any, reason: string) => {
                                                if (values?.country) {
                                                    setFormdata({
                                                        ...formdata,
                                                        shipto: values?.code,
                                                        shipcountry: values?.country
                                                    })
                                                } else {
                                                    setFormdata({
                                                        ...formdata,
                                                        shipto: '',
                                                        shipcountry: ''
                                                    })
                                                }
                                            }}
                                            renderInput={(params) =>
                                                <TextField {...params}
                                                    error={formerror.shipto ? true : false}
                                                    variant="outlined"
                                                    color="primary"
                                                    name="shipto"
                                                    placeholder="Nereye / Drop Off"
                                                />
                                            }
                                        />
                                        {formerror.shipto && <p>{formerror.shipto}</p>}
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="kargo_miktr">
                                        <h5>
                                            Miktar
                                            <br />
                                            Quantity
                                        </h5>
                                        <div className="kargo_input miktr">
                                            <div className="quantity">
                                                <TextField
                                                    inputProps={{ min: 0 }}
                                                    error={formerror.shipkg ? true : false}
                                                    onChange={(e: any) => {
                                                        setFormdata({
                                                            ...formdata,
                                                            shipkg: e.target.value.toString().split(".").map((el, i) => i ? el.split("").slice(0, 2).join("") : el).join(".")
                                                        })
                                                    }}
                                                    color="primary"
                                                    variant="outlined"
                                                    name="shipkg"
                                                    value={formdata.shipkg}
                                                    type="number"
                                                    fullWidth
                                                />
                                                <span>KG</span>
                                            </div>
                                            {formerror.shipkg && <p>{formerror.shipkg}</p>}
                                        </div>
                                    </div>
                                    <div className="lowest_btn">
                                        <button onClick={submitform} >EN UYGUN FİYATI GÖR <span>SEE THE LOWEST PRICE</span></button>
                                        <ul>
                                            <li>* Tahmini başlangıç fiyatlarıdır</li>
                                            <li>* Freights are based on estimation</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Logistics;
