import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { Layout } from "./components/Layout";
import { GlobalStyles } from './globalStyles';
import { Routes } from './Routes';
import React, { useState, useEffect } from 'react'
export interface searchvalue {
  open: any;
  click:any;
}
export var popdata: searchvalue = {
  open: true,
  click:false
};
const theme = {
  breakpoints: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1400px',
  },

  font: {
    sizes: {
      title: '60px',
    }
  },

  colors: {
    text: {
      base: '#fff',
      nav: '#fff',
      bg: '#fff',
    },
    background: {
      nav: '#fff',
    }
  }
};

const App = () => {
 

  return (

    <ThemeProvider theme={theme} >
     
      <GlobalStyles />
      <Router>
        <Layout>
          <Routes />
        </Layout>
      </Router>
    </ThemeProvider>
  );
}
export default App;
