import React from 'react';
const Whoweare = () => {
    return (
        <>
            <section className="strongly sec_space">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 ">
                            <div className="strongly_heading">
                                <span>essence</span>
                                <h4>We strongly believe that international trade is for everyone and It <span> should NOT be dominated </span>by 2-3 countries. Importers have the right to reach a small factory in Turkey, Uganda, or some other countries. In the same way, exporters should have a chance to be seen and offer their product and performance directly.</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default Whoweare;