import React  from 'react'

const Blogs = () => {
    window.location.href = "https://blog.koytola.com/"
    return (
        <>
            
        </>
    )
}
export default Blogs;