// import React from 'react';
// import ReactDOM from 'react-dom';
// import App from './App';
// import {
//   ApolloClient,
//   ApolloProvider,
//   InMemoryCache,
//   HttpLink,
//   ApolloLink, concat

// } from '@apollo/client';

// const httpLink = new HttpLink({ uri: 'http://testing-backend.koytola.com/graphql/' });

// const authMiddleware = new ApolloLink((operation, forward) => {
//   operation.setContext(({ headers = {} }) => ({
//     headers: {
//       ...headers,
//       authorization: 'JWT ' + sessionStorage.getItem('auth') || null,
//     }
//   })
//   )
//   return (forward(operation));
// })

// const client = new ApolloClient({
//   cache: new InMemoryCache(),
//   link: concat(authMiddleware, httpLink),
// });
// // const client = new ApolloClient({
// //   cache: new InMemoryCache(),
// //   link: new HttpLink({
// //     uri: 'http://testing-backend.koytola.com/graphql/',
// //     // uri: 'http://192.168.29.72:8000/graphql/',
// //   }),
// // })
// ReactDOM.render(
//   <React.Fragment>
//     <ApolloProvider client={client}>
//       <App />
//     </ApolloProvider>
//   </React.Fragment>,
//   document.getElementById('root')
// );
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  HttpLink,

} from '@apollo/client';
// import { setConfig } from 'react-google-translate';


const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    // uri: 'https://testing-backend.koytola.com/graphql/',
    uri: 'https://api.koytola.com/graphql/',
    // uri: 'http://192.168.29.72:8000/graphql/',
  }),
})
ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
