import React from 'react';
const TrackShippment = () => {
  return (
    <>
      <section className="dashboard sec_space">
        <div className="container">
          <div className="row">
            <div className="col-xs-6">
              <div className="dashboard_conatnt">
                <h4>User friendly CRM </h4>
                <p>
                Koytola wholesale marketplace helps small manufacturers in Turkey to have a customized management panel. After getting confirmation from both sellers' and buyers' sides, sellers will see the detail of the deal and ship their product to buyers hassle-free.  </p>
              </div>
            </div>
            <div className="col-xs-6">
              <div className="dashboard_img">
                <img src={require('../../../assets/images/logistic-search-bg.png')} className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default TrackShippment;