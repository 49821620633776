import React from 'react'
const WelcomeKoytola = () => {
    return (
        <section className="welcome_koytola sec_space">
            <div className="container">
                <h3>Ready to start ordering wholesale online?</h3>
                <h4> Buy wholesale products from Turkey that <b>your customers </b>will love.</h4>
            </div>
        </section>
    )
}
export default WelcomeKoytola;