import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  :root,
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');
  body {
    box-sizing: border-box;
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body {
    font-size: 1rem;
    margin: 0;
    overflow-x: hidden;
    font-family: 'Poppins', sans-serif !important;
  }
  h1,h2,h3,h4,h5,h6,p,ul,li,span,a,ol,strong{
    font-family: 'Poppins', sans-serif !important;
  }
  a {
    text-decoration: none;
    color: black;
  }

  .zkitcard_footer {
    display: none;
} 

  .zkitProductbox:hover .product_contant {
    position: relative;
    transform: translateX(0px) translateY(-100px);
    border-color: #ddd;
}

.zkitProductbox:hover .product_contant .tags_outer {
    display: none;
}

.zkitProductbox:hover .zkitcard_footer {
    display: block;
    margin-top: 20px;
    border-top: solid 1px #F2F2F2;
    padding-top: 10px;
}

.listing_img_box .MuiCardMedia-root {
    height: 300px;
}

.makeStyles-FavoriteIconClass-19 .makeStyles-Favorite_icon-20:hover {
    background-color: #f009;
    color: #fff;
}


.product_contant {
    background-color: #fff;
    border-radius: 10px;
    transition: 0.5s;
    border: solid 1px #fff;
    
}

  .zkitProductbox {
    box-shadow: none !important;
    border: 0;
    position: relative;
    font-family: Poppins !important;
    animation: smoothScroll 0.5s forwards;
  }

  @keyframes smoothScroll {
	0% {
		transform: translateY(-40px);
	}
	100% {
		transform: translateY(0px);
	}
}


  .zkitProductbox .MuiCardHeader-title {
    font-size: 16px;
    color: #434141;
}

.zkitProdheader .MuiCardHeader-subheader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;
  text-transform: capitalize;
  font-weight: 500;
}

.zkitProdheader .MuiCardHeader-subheader svg {
  font-size: 16px;
  color: #9F9D9D;
  margin-right: 3px;
}

 .zkitProdheader .share_icon {
  width: 30px;
  height: 30px;
  background-color: #e9e9e9;
}
.zkitProdheader .share_icon svg {
  width: 16px;
}
.zkitProdheader .btn-follow {
  border: solid 1px #b5b4b4;
  font-size: 11px;
  padding: 2px 18px;
  margin-left: 15px;
  border-radius: 50px;
}
.zkitProdheader .MuiCardHeader-action {
  margin-top: 0px;
}
.zkitProdheader {
    padding: 15px 10px !important;
}
.zkitProductbox .cat_title {
  text-transform: uppercase;
  font-weight: 500;
  display: block;
  margin-bottom: 8px;
  font-size: 12px;
  color: #7A38DA;
}
.ratting_warp .MuiRating-readOnly {
  margin-top: 10px;
}
.zkitProductbox .pro_title {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  margin-bottom: 8px;
}
.ratting_warp {
  padding: 0; 
  margin-bottom: 5px;
}

.ratting_warp .ratting_count {
  font-size: 14px;
  color: #707070;
  margin-left: 10px;
  position: relative;
  top: -2px;
  font-weight: 600;
}

.FavoriteIconClass svg {
    position: absolute;
    right: 15px;
    top: 20px;
    background-color: #ffffff38;
    font-size: 22px;
    width: 30px;
    padding: 5px;
    height: 30px;
    border-radius: 50px;
}

.FavoriteIconClass .Save_icon {
    top: 60px;
}

.tags_outer {
    padding-top: 20px;
    display: inline-block;
}

.tags_outer .tags {
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
    border: solid 1px #000;
    padding: 2px 10px;
    border-radius: 50px;
    font-size: 12px;
    min-width: 80px;
    text-align: center;
    line-height: 18px;
    transition: 0.5s;
    cursor: pointer;
}
.tags_outer .tags span {
  line-height: 1;
  font-weight: 500;
  color: #000;
}
.tags_outer .tags span:hover {
  background-color: #ECECEC;
  border-color: #ECECEC; 
}
.ratting_warp label.MuiRating-label {
  font-size: 18px;
}
.pro_price b {
  color: #454343;
  font-weight: 600;
  font-size: 18px;
}
.pro_price span {
  color: #909090;
  font-size: 12px;
  margin-left: 5px;
  font-weight: 500;
}
.zkitcard_footer .flex-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.supplier_infoaria {
  display: flex;
  align-items: center;
}
.supplier_pic {
  width: 40px;
  height: 40px;
  border: 0px;
  border-radius: 50%;
  margin-right: 10px;
}

.zkitProductbox .zkitcard_footer h5 {
  color: #929292;
}

.detailProdheader .MuiAvatar-colorDefault img {
  height: 40px;
  width: 40px;
  object-fit: cover;
}
.supplier_info h5 {
  font-weight: 600;
  margin: 0px;
  font-size: 16px;
  color: #434141 !important;
  margin-bottom: 2px;
}
.supplier_info span {
  color: #9B9B9B;
  font-size: 12px;
}
.zkitcard_footer .btn-follow {
  border: solid 1px #b5b4b4;
  font-size: 11px;
  padding: 2px 18px;
  border-radius: 50px;
  cursor: pointer;
}
.zkitcard_foright {
  text-align: right;
}
.btn-viewstore {
  display: block;
  font-size: 13px;
  margin-top: 10px;
  cursor: pointer;
}
.main-header .makeStyles-search-6,.main-header .makeStyles-category-11,.main-header .makeStyles-selectEmpty-5 {
  margin-top: 0;
}
.main-header .MuiInput-underline:before {
  border: none;
}

.currencyitems{
  width: 190px;
  height: 100%;
  display:flex;
  align-items: center;
  float: left;
}

.currencyItem .currency_count {
  position: relative;
}

.currencyItem .currency_count:before {
  border-color: #333 transparent;
  border-style: solid;
  border-width: 8px 4px 0 4px;
  width: 0;
  height: 0;
  content: "";
  position: absolute;
  top: 8px;
  right: -20px;
  display:none;
}

.currencyItem .currency_count.light_yelw:before {
  transform: rotate(180deg);
}


.currencyStrip_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: solid 1px #ddd;
  border-bottom: solid 1px #ddd;
}

.crnc_houry {
  width: 80px;
  float: left;
  margin: 0 22px;
  background-color: transparent;
  border: 0;
  font-size: 13px;
  font-weight: 500;
  color: #999;
  outline: 0;
  cursor: pointer;
}

.currencyItem .currency_count {
  color: #999;
    font-weight: 600;
    font-size: 12px;
    margin-right: 5px;
    padding: 2px 0px;
    border-radius: 3px;
    background-color: #fceef9;
    width: 160px;
    display: inline-block;
    text-align: center;
}

.currencyItem .currency_count.light_yelw {
  background-color: #E1FFF1;
}
.currencyItem .currency_count.light_pink {
  background-color:  #FFF6E1;
}

.currencyStrip_inner .marquee-container.horizontal {
  width: 100%;
  min-height: 50px !important;
}

.currencyStrip_inner .marquee-container.horizontal::before,
.currencyStrip_inner .marquee-container.horizontal::after {
  display: none;
}

.currencyHead{
  color: black;
  margin-right: 25px;
  font-weight: bold;
  font-size: 12px;
}

.neg{
  font-size: 12px;
  background: #FFB1B1;
  color: black;
  font-weight:bold;
  position: absolute;
  border-radius: 2px;
}
.pos{
  font-size: 12px;
  background: #5EE8C7;
  color: black;
  font-weight:bold;
  position: absolute;
  border-radius: 2px;
}
.imageset{
  height: 200px !important;
}
.latestplatformFont{ 
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.03em;
}
.latestplatformLine{
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.03em;
 }
.hr{
  opacity: 0.5;

} 
.latestplatform500{
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 0px;
}
  .FavoriteIconClass{
    position: relative;
    }
  .Favorite_icon{
    position: absolute;
    right:10px;
    top:10px;
  }
  .media{
    height: 364px;
    width: 590px;
  }
  .media1{
    width: 293px;
    height:  143px;
  }
  .rating{
    margin-Top: 20px;
  }
  html {
    scroll-behavior: smooth;
  }
  body {
      font-family: 'Poppins', sans-serif;
  }
  h1, h2, h3, h4, h5, h6, span, p, a {
      font-family: 'Poppins', sans-serif;
  }
  
  a {
      text-decoration: none !important;
      color: #333;
  }
  h6 {
      font-size: 18px;
  }
.top_header {
  padding: 25px 0;
}
.top_header .left_colum {
  display: flex;
  align-items: center;  
}
.top_header .logo {
  display: flex;
  align-items: center;
  position: relative;
}

// .top_header .logo:before {
//   content: "";
//   position: absolute;
//   top: 4px;
//   right: 0;
//   height: 28px;
//   width: 1px;
//   background-color: #ddd;
// }

.top_header .logo h5 {
  margin: 0;
  font-size: 30px;
  color: #7A38DA;
  font-weight: 600;
  margin-right: 25px;
  cursor: pointer;
}
.dropdown .dropdown-toggle {
  border: 0;
  background-color: transparent;
  outline: 0px;
  border-left: solid 1px #ddd;
  padding-left: 25px;
  font-size: 14px;
  color: #2F2F2F;
}
.products_search {
  margin-left: 50px;
  display: flex;
  align-items: center;
  position: relative;
}
.products_search .MuiAutocomplete-hasClearIcon {
  min-width: 280px;
  position: relative;
  margin-right: 0px;
  border-radius: 50px;
  padding: 3px;
}

.products_search .MuiInput-underline:after {
  display: none;
}
.MuiAutocomplete-option{
  font-size:13px !important;
  padding:4px 16px !important;
}
.products_search .MuiAutocomplete-hasClearIcon .MuiInput-underline {
  padding-left: 35px;
  padding-right: 10px !important;
  border: 1px solid rgb(221, 221, 221);
  border-radius: 50px;
  padding-right: 10px !important;
  background:#fff;
}

.banner_contant_type .products_search .MuiAutocomplete-hasClearIcon .MuiInput-underline {
  border: 0px;
}

.products_search .MuiAutocomplete-hasClearIcon .MuiInput-underline::before {
  display: none;
}
.products_search .MuiAutocomplete-hasClearIcon button {
  display: none;
}

.products_search .MuiAutocomplete-hasClearIcon .MuiInput-underline #combo-box-demo {
  font-size: 13px;
}

.products_search .MuiAutocomplete-hasClearIcon .MuiInput-underline #combo-box-demo::placeholder {
  color: #999;
  font-size: 14px;
  font-weight: 400;
} 

.products_search:before {
  content: "\f002";
  position: absolute;
  top: 10px;
  font-size: 14px;
  left: 14px;
  z-index: 9;
  font: normal normal normal 14px/1 FontAwesome;
  color: #a7a7a7;
}
.products_search .dropdown-toggle {
  border-left: 0;
}

.products_search span {
  padding-left: 20px;
  font-size: 14px;
  position: relative;
  margin-left: 20px;
  font-weight: 500;
  color: #837E7E;
  text-transform: lowercase;
}

.products_search span:before {
  content: "";
  position: absolute;
  top: -4px;
  left: 0;
  height: 28px;
  width: 1px;
  background-color: #ddd;
}

  .profile .dropdown-toggle, .main_header .dropdown-toggle {
      border-left: 0;
      padding-left: 0;
      width: 55px;
  }
  .profile .dropdown .dropdown-item {
    font-size: 14px;
    color: #000;
    display: block;
    margin-bottom: 10px;
}

.profile .dropdown .dropdown-item:last-child {
  margin-bottom: 0;
}

.profile.float-right .dropdown-menu {
    padding: 10px;
    text-align: left;
    min-width: auto;
    width: 120px;
}
  .main_header .navbar-expand-lg .navbar-nav {
      justify-content: space-between;
      width: 100%;
  }
  .main_header .tob_marquee p {
      color: #373737;
      padding: 0;
      font-size: 12px;
      min-width: 240px;
      position: relative;
      margin-bottom: 0;
  }
  .main_header marquee {
      width: 100%;
      margin-left: 60px;
  }
  .main_header .hourly li {
      display: flex;
  }
  .main_header .tob_marquee p:before {
      border: 0;
      content: "\f062";
      position: absolute;
      top: 5px;
      right: 32px;
      z-index: 9;
      font: normal normal normal 11px/1 FontAwesome;
  }
  .main_header .tob_marquee p.rotted:before {
      transform: rotate(180deg);
  }
  .main_header {
      border-top: solid 1px #ddd;
      border-bottom: solid 1px #ddd;
      padding: 15px 0;
      width: 100%;
      display: flex;
  }
  .main_header .upper {
      color: #000;
      font-weight: 600;
      padding-right: 5px;
  }
  .main_header .navbar-expand-lg .navbar-nav li {
      line-height: 1;
  }
  .main_header .bg {
      color: #000;
      font-weight: 600;
      padding: 2px 10px;
      font-size: 12px;
      margin-left: 5px;
  }
  .main_header .bg.pink {
      background-color: #ffbebe;
  }
  .main_header .bg.green {
      background-color: #a2f1de;
  }
  .main_header .tob_marquee {
      display: flex;
      align-items: center;
      justify-content: center;
  }
  .sec_space {
      padding: 60px 0;
  }
  .home_main_banner .banner_contant {
      padding: 50px;
  }
  .home_main_banner .banner_contant img {
      width: 160px;
      display: block;
      margin-bottom: 15px;
  }
  .home_main_banner .banner_contant span {
      font-size: 20px;
      font-weight: 500;
      line-height: 33px;
  }
  .home_main_banner  .products_search .dropdown-toggle {
      font-weight: 500;
      color: #000;
  }
  .home_main_banner .banner_contant .products_search {
      margin-left: 0;
      margin-top: 50px;
  }
  .dropdown-toggle::after {
      border: 0;
      // content: "\f107";
      position: absolute;
      top: 3px;
      right: -5px;
      z-index: 9;
      font: normal normal normal 20px/1 FontAwesome;
  }
  .main_header .hourly li .nav-link {
      padding: 0;
  }
  .main_header .dropdown-toggle::after {
      top: 0px;
      right: -10px;
  }
  .home_main_slder .owl-nav {
      width: 30%;
      float: right;
      margin-top: 0 !important;
      position: relative;
  }
  .home_main_slder .owl-nav button {
      position: absolute;
      left: 0;
      top: 0;
      outline: 0;
      background-color: transparent !important;
      color: #000 !important;
  }
  .home_main_slder .owl-nav button.owl-next {
      right: 0 !important;
      left: auto;
  }
  .home_main_slder .owl-nav button span {
      opacity: 0;
  }
 
  .home_main_slder  .owl-dots button span {
      width: 60px !important;
      margin: 0px !important;
      border-radius: 0px !important;
      height: 2px !important;
  }
 
  .home_main_slder .owl-nav button:after{
      border: 0;
      content: "\f061";
      position: absolute;
      top: 14px;
      right: 0px;
      z-index: 9;
      font: normal normal normal 14px/1 FontAwesome;
  }
  .home_main_slder .owl-nav button.owl-prev:after {
      transform: rotate(180deg);
  }
  .logo_slider .item img {
      max-width: 120px;
      margin: auto;
  }
  .logo_slider .owl-stage {
      display: flex;
      align-items: center;
      justify-content: center;
  }
  .products_amount {
      text-align: left;
  }
  .products_amount span {
    font-size: 13px;
    line-height: 20px;
    font-weight: 600;
    color: #000;
    width: 150px;
    display: inline-block;
    text-transform: initial;
}
  .products_amount h1 {
      font-size: 80px;
      font-weight: 600;
      -webkit-text-stroke: 1px black;
      color: #404040;
      letter-spacing: 2px;
  }
  .section_heading {
      padding-bottom: 50px;
  }
  .section_heading h2 {
    font-size: 26px;
    color: #000;
    font-weight: 500;
    margin: 0;
  }
  .selling.text-writer {
    width: 70%;
  }
  .selling.text-writer span {
    font-size: 34px;
    font-weight: 500;
    line-height: 50px;
    color: #000;
    margin-top: 0;
    max-width: 100%;
    display: initial;
  }
  .selling.more h3 {
    font-size: 34px;
    font-weight: 500;
    line-height: 42px;
    color: #000;
    margin-top: 0;
    max-width: 100%;
    display: inline-block;
    width: 70%;
  }
  .selling.text-writer #typewriter{
    color: #7A38DA;
  }
  .typewriter {
    color: #7A38DA !important;
  }
  .selling span {
      font-size: 16px;
      font-weight: 400;
      color: #000;
      display: block;
      max-width: 50%;
      padding-top: 20px;
      line-height: 24px;
  }
  .product_slider {
      margin-right: -119px;
      padding-top: 100px;
  }
  .product_slider .owl-nav {
      display: none;
  }
  .owl-dots span {
      width: 54px !important;
      border-radius: 0px !important;
      height: 2px !important;
      margin: 0px !important;
  }
  .owl-dots button {
      outline: 0px !important;
  }
  .product_slider .owl-dots {
      position: absolute;
      top: -50px;
      left: 0;
  }
  .product_slider .item {
      padding-left: 20px;
      position: relative;
      left: -20px;
      cursor: pointer;
  }
  .product_slider .item h4 {
      text-align: left;
      padding-top: 10px;
      font-size: 14px;
      color: #000;
  }

.logo-slider {
    padding: 60px 0;
}
  .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
      background: #323131;
  }
  .success_heading {
      padding-bottom: 60px; 
  }
  .success_heading h2 {
      font-size: 38px;
      color: #000;
      line-height: 60px;
  }
  .agriculture span {
      font-size: 14px;
      margin-top: 20px;
      color: #000;
      font-weight: 500;
      display: inline-block;
      width: 60%;
  }
  .changing h2 {
      text-transform: uppercase;
      line-height: 42px;
      font-size: 26px;
      color: #000;
      font-weight: 600;
  }
  .makeover_thanks {
    text-align: center;
}

.makeover_thanks h2 {
    font-size: 42px;
    color: #7A38DA;
    margin-top: 0px;
    margin-bottom: 40px;
    font-weight: 600;
}

.makeover_thanks h4 {
    font-size: 30px;
    color: #7a38da;
    font-weight: 600;
    margin: 0;
}
  agriculture.sec_space {
    padding-top: 0;
  }

  .banner_contant_type .products_search {
    margin-left: 0;
    border: 1.8px solid rgba(122, 56, 218, 0.87);
    box-sizing: border-box;
    border-radius: 15px;
  }

  .agriculture2 {
      margin-top: 80px;
  }
  .agriculture2 p {
      font-size: 14px;
      margin-top: 20px;
      color: #000;
      font-weight: 500;
  }
  .agriculture2 p:first-letter {
      text-transform: capitalize;
  }
  .agriculture2 h5 {
      margin-top: 40px;
      font-size: 20px;
      color: #434341;
      line-height: 16px;
  }
  .agriculture2 span {
      color: #BCB7B7;
      font-size: 14px;
      font-weight: 500;
  }
  .changing p {
      font-size: 14px;
      margin-top: 120px;
      color: #000;
      font-weight: 500;
      width: 90%;
  }
  .changing a {
      color: #590ec6;
      margin-top: 30px;
      display: block;
      font-size: 16px;
  }
  .changing a i {
      font-size: 13px;
      margin-left: 10px;
  }
  .storie_btn a {
      border: solid 1px #707070;
      padding: 3px 20px;
      display: inline-block;
      border-radius: 50px;
      font-size: 10px;
      color: #000;
      margin-right: 8px;
      margin-bottom: 10px;
      font-weight: 500;
  }
  .storie_contant {
      display: flex;
  }
  .storie_contant p {
      font-size: 16px;
      color: #000;
      font-weight: 500;
      margin-bottom: 0;
  }
  .storie_contant p:first-letter {
      text-transform: capitalize;
  }
  .news_tabs_inner span:first-letter {
     text-transform: capitalize;
  }
  .strory_slider .col-md-3:first-child .storie_contant p {
      font-size: 14px;
  }
  .strory_slider .col-md-3:first-child .storie_btn a {
      font-size: 8px;
  }
  .storie_contant i {
      transform: rotate(-45deg);
      font-size: 13px;
  }
  .strory_slider .item img {
      margin: 15px 0;
  }
  .storie_contant.second i {
      transform: rotate(45deg);
  }
  .daily_offer .tab-content {
    padding-bottom: 40px;
    padding-top: 20px;
  }
  .offer_top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 20px 0;
      text-align: left;
  }
  .offer_top .offer_left {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 70%;
  }
  .offer_top .offer_left h4 {
      font-size: 18px;
      margin-bottom: 0;
  }
  .offer_top .offer_left p {
      margin-bottom: 0;
      font-size: 13px;
      margin-top: 5px;
      color: #828282;
  }
  #offers_tab_contant .owl-dots {
      display: none;
  }
  .offer_top .offer_left span {
      margin-left: 15px;
      width: 80%;
  }
  .offer_top .offer_right p {
      color: #FA8D0E;
      margin-bottom: 0;
      font-size: 16px;
  }
  .offer_box_heading {
      display: flex;
      align-items: center;
      padding-bottom: 30px;
      margin-top: 15px;
  }
  .offer_box_heading p {
      margin-bottom: 0;
      font-size: 16px;
      width: 40%;
  }
  .offer_box_heading h2 {
      margin: 0;
      font-size: 28px;
      font-weight: 600;
      width: 68%;
  }
  .offer_bottom {
      padding: 20px 0;
      text-align: left;
  }
  .offer_top .offer_left img {
    width: 50px;
    height: 50px;
    border-radius: 100%;
  }
  .offer_box_heading p span {
      background-color: #FF9F9F;
      color: #fff;
      border-radius: 4px;
      padding: 2px 10px;
      margin-left: 10px;
  }
  .shop_btn a {
      color: #7A38DA;
      font-weight: 500;
      font-size: 16px;
      cursor: pointer;
  }
  .offer_box_heading.secound {
      display: block;
      padding-bottom: 30px;
      margin-top: 0px;
  }
  .offer_box_heading.secound p {
      margin-bottom: 20px;
      width: 100%;
  }
  .offer_bottom.secound {
      padding: 0;
  }
  .offer_box_heading.secound h2 {
      width: 100%;
      font-size: 22px;
      margin-top: 40px;
  }
  .duble_box {
      margin-top: 97px;
  }
  .duble_box.secound {
      margin-top: 30px;
  }
  .daily_offer .nav-tabs {
      border-bottom: 0;
  }
  .daily_offer .nav-tabs .nav-link, .daily_offer .nav-tabs .nav-link:hover {
      border: solid 1px #000;
      margin-bottom: 10px;
      border-radius: 50px;
      color: #000;
      padding: 6px;
      font-size: 14px;
  }
  .daily_offer .nav-tabs .nav-link.active {
      color: #590ec6;
      border-color: #590ec6;
  }
  .daily_offer .nav-tabs .owl-carousel .owl-nav.disabled {
      display: flex;
      position: absolute;
      left: 0;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      top: -10px;
      z-index: -1;
  }
  .daily_offer .nav-tabs .owl-carousel .owl-nav.disabled .owl-prev {
      left: -30px;
  }
  .daily_offer .nav-tabs .owl-carousel .owl-nav.disabled .owl-next {
      right: -30px;
  }
  .daily_offer .nav-tabs .owl-carousel .owl-nav.disabled button {
      position: relative;
      font-size: 38px;
      top: -17px;
      outline: 0;
      background-color: transparent;
      color: #000 !important;
  }
  .view_more {
      margin-bottom: 60px;
  }
  .view_more a {
      color: #434141;
      font-weight: 600;
      font-size: 14px;
      text-transform: capitalize;
  }
  .furniture .heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
  }
  .furniture .heading h4 {
      margin-bottom: 0;
      color: #434141;
      font-size: 16px;
      font-weight: 600;
  }
  .furniture .heading a {
      font-size: 14px;
      font-weight: 500;
      color: #999;
      text-transform: initial;
      letter-spacing: 0.03em;
  } 

  .daily_offer .furniture .heading {
    justify-content: flex-start;
    margin-bottom: 20px;
    margin-left: -10px;
  }

  .sub_categiory_slider .item h5 {
      text-align: left;
      color: #5B5B5B;
      font-size: 12px;
      margin-bottom: 0;
      margin-top: 10px;
  }
  .furniture {
      padding: 0px 20px;
  }
  .koytola_family {
      background: linear-gradient(271.02deg, #B785FF 5.34%, rgba(133, 185, 255, 0.528412) 51.53%, rgba(100, 194, 172, 0.154116) 88.2%, rgba(255, 255, 255, 0) 103.3%), #7A38DA;
      padding: 40px 0;
  }
  .footer_contant {
      max-width: 40%;
      margin: auto;
      text-align: left;
  }
  .footer_contant h2 {
      color: #fff;
      font-size: 42px;
      font-weight: 300;
      line-height: 60px;
      margin-top: 0px;
  }
  .footer_contant .account {
      margin-top: 40px;
  }
  .footer_contant .account a {
      color: #fff;
      display: block;
      font-weight: 300;
      font-size: 16px;
  }
  .footer_contant .account a:first-child {
      margin-bottom: 25px;
  }
  .footer_contant .account a img {
      width: 18px;
      margin-left: 10px;
  }
  .footer_contant .account a img.rotted {
      transform: rotate(35deg);
  }
  .strory_slider .carousel-item {
      overflow: hidden;
      padding: 0;
  }
  .strory_slider .carousel-item .inner {
      transition: .4s ease all;
      transform: scale(0.90);
  }
  .strory_slider .carousel-item.active .inner{
      transition: .4s ease all;
      transform: scale(0.90);
  }
  .strory_slider .carousel-item .inner img {
      
      width: 100%;
      height: 320px;
      object-fit: cover;
  }
  .storie_contant {
      display: flex;
      justify-content: space-between;
  }
  .strory_slider .owl-stage-outer {
      padding-bottom: 50px;
  }
  .top_products.category {
    padding-top: 60px;
    padding-bottom: 0;
  }
  .sub_categiory_slider .owl-dots {
      display: none;
  }
  #tabnav_slider .owl-item li {
      text-align: center;
      padding: 10px 0;
  }
  #tabnav_slider .owl-dots {
      display: none;
  }
  #tabnav_slider .owl-item li a {
      border: solid 1px #000;
      font-size: 14px;
      padding: 5px 10px;
      border-radius: 50px;
  }
  .counter_heading {
      margin-bottom: 80px;
  }
  .counter_heading h3 {
      font-size: 36px;
      font-weight: 600;
      line-height: 48px;
      color: #000;
      margin-bottom: 40px;
  }
  .counter_heading p {
      font-size: 14px;
      color: #000;
      font-weight: 600;
      width: 45%;
  }
  .counter_box {
      border-top: solid 1px #000;
      padding-top: 15px;
  }
  .counter_box span {
      font-size: 14px;
      font-weight: 600;
      color: #000;
      margin-bottom: 15px;
      display: inline-block;
  }
  .counter_box h1 {
      font-size: 48px;
      color: #3A3A3A;
      font-weight: 600;
  }
  #Shop_category .owl-dots {
      bottom: -50px;
      top: auto;
  }
  .Shop_category {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 40px;
  }
  .product_slider.category {
      padding-top: 0;
  }
  .Shop_category span {
      font-size: 24px;
      font-weight: 500;
      width: 200px;
      line-height: 34px;
  }
  .Shop_category a {
      color: #000;
      font-weight: 500;
      font-size: 14px;
  }
  .Shop_category_contant .card {
      border: 0px;
  }
  .sub_categiory_item {
    padding-right: 15px;
  }
  .sub_categiory_item h5{
    cursor: pointer;
  }
  .Shop_category_contant .card-header {
      background-color: transparent;
      border-bottom: solid 1px #c0c0c0;
      margin-bottom: 0px !important;
  }
  .Shop_category_contant .card-header h5 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #000;
      font-size: 15px;
  }
  .Shop_category_contant .accordion {
      width: 70%;
  }
  .Shop_category_contant .card-body {
      font-size: 14px;
      color: #000;
      border-bottom: solid 1px #c0c0c0;
  }
  .card .card-header a:not(.collapsed) .rotate-icon {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
  }
  .why_koytola .heading a {
      color: #000;
      border: solid 1px #000;
      border-radius: 50px;
      padding: 4px 20px;
      font-size: 16px;
  }
  .why_koytola .heading h3 {
      margin: 25px 0px;
      font-size: 45px;
      font-weight: 400;
      line-height: 66px;
      text-transform: uppercase;
      color: #000;
  }
  .why_koytola .support i {
      transform: rotate(45deg);
      font-size: 14px;
  }
  .why_koytola .support {
    border-bottom: solid 1px #000;
}
  .why_koytola .support a {
      color: #000;
      padding-bottom: 5px;
  }
  .why_koytola .support a span {
      padding-right: 20px;
      font-size: 16px;
  }
  .why_koytola .support {
      display: block;
      margin-bottom: 50px;
  }
  .barriers_heading h2 {
      font-size: 26px;
      line-height: 42px;
      color: #000;
      text-align: right;
      width: 55%;
      margin-left: auto;
      text-transform: capitalize;
  }
  .barriers_heading {
      margin-right: 40px;
      width: 35%;
  }
  .barriers_contant {
      margin-left: 40px;
      text-align: left;
      width: 35%;
  }
  .barriers_contant span {
      font-size: 14px;
      color: #000;
  }
  .barriers_contant h5 {
      margin-top: 10px;
      color: #000;
      font-size: 16px;
      font-weight: 600;
      line-height: 28px;
  }
  .barriers_contant p {
      margin-bottom: 0;
      font-size: 14px;
      color: #000;
      text-transform: initial;
  }
  .barriers_inner .row {
      margin-bottom: 100px;
  }
  .barriers_inner .row:last-child {
      margin-bottom: 0;
  }
  .welcome_box span {
      color: #000;
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 5px;
      display: inline-block;
  }
  .welcome_box h4 {
      color: #414141;
      font-size: 22px;
      font-weight: 600;
  }
  .welcome_box .sign_up {
      color: #590ec6;

  }
  .welcome_box .sign_up i {
      font-size: 12px;
      margin-left: 10px;
  }
  .welcome_box a {
      font-size: 14px;
      color: #000;
      font-weight: 500;
      cursor: pointer;
  }
  .dashboard {
      background-color: #590EC6;
      padding: 100px 0;
  }
  .dashboard .row:first-child {
      margin-bottom: 100px;
  }
  .dashboard_conatnt h4 {
      color: #fff;
      font-size: 22px;
      font-weight: 400;
      margin-bottom: 30px;
  }
  .dashboard_conatnt p {
      width: 65%;
      color: #fff;
      font-weight: 300;
      font-size: 15px;
      line-height: 28px;
  }
  .strongly .row:first-child {
      margin-bottom: 100px;
      margin-top: 40px;
  }
  .strongly_heading span {
      color: #2F2F2F;
      margin-bottom: 25px;
      display: inline-block;
      font-weight: 400;
      font-size: 18px;
      text-transform: initial;
  }

  .strongly_heading h4 {
      line-height: 34px;
      font-size: 24px;
      font-weight: 600;
      width: 90%;
      text-align: center;
      margin: auto;
      text-transform: initial;
  }
  
  .strongly_heading h4 span{
      color: #ff6060;
      display: initial;
      font-size: 22px;
  }
  
  .strongly_box {
      margin-bottom: 60px;
  }
  .strongly_box h3 {
      font-size: 22px;
      color: #2F2F2F;
      font-weight: 500;
  }
  .strongly_box span.count {
      display: inline-block;
      font-style: italic;
      font-size: 14px;
      margin-bottom: 20px;
      font-weight: 500;
  }
  .strongly_box h5 {
      font-weight: 600;
      margin-bottom: 15px;
      font-size: 20px;
  }
  .strongly_box p {
      margin-bottom: 10px;
      font-weight: 600;
      font-size: 16px;
  }
  .strongly_box span {
      font-size: 14px;
  }
  .news_contant {
      border-top: solid 1px #000;
      padding: 15px 0;
  }
  .news_contant a, .news_box a{
      font-size: 14px;
      color: #7A38DA;
      margin-bottom: 10px;
      display: inline-block;
  }

  .news_box_contant span h1 {
    line-height: 22px !important;
    margin: 0px !important;
}
.news_box_contant span div {
  line-height: 22px;
  font-size: 14px;
}

  .news_contant h4 {
    font-size: 20px;
    font-weight: 600;
    margin-top: 0px;
    margin-bottom: 10px;
    line-height: 28px;
    text-transform: initial;
    cursor: pointer;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 54px;
  }

  .news_box_contant h4 {
    margin: 0;
    margin-bottom: 15px;
    cursor: pointer;
}
  .news_contant p {
      line-height: 20px;
      margin-bottom: 0;
      font-size: 12px;
      color: #393939;
  }
  .news_box p {
      line-height: 20px;
      margin-bottom: 0;
      font-size: 12px;
      color: #393939;
      margin-top: 8px;
  }
  .news_box_contant h1 {
    font-size: 22px;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 20px;
    line-height: 32px;
    text-transform: initial;
    cursor: pointer;
  }
  #news_slider .owl-dots {
      display: none;
  }
  .news_tabs_inner a {
      display: block;
      font-size: 14px;
      color: #7A38DA;
      margin-bottom: 5px;
  }
  .news_tabs_inner {
      padding: 10px 0;
      border-bottom: solid 1px #ddd;
  }
  .news_tabs_inner span {
      font-size: 14px;
  }
  .news_tabs marquee {
      max-height: 450px;
  }
  .news_tabs {
      border-top: solid 1px #000;
  }
  .news_tabs .nav-tabs {
      border-bottom: 0;
      padding-top: 10px;
      padding-bottom: 30px;
  }
  .news_tabs .nav-tabs .nav-link.active {
      color: #000;
  }
  .news_tabs .nav-tabs .nav-link {
      border: 0;
      font-weight: 500;
      color: #999;
      font-size: 14px;
      padding: 0;
      padding-right: 50px !important;
  }
  .news_tabs .nav-tabs .nav-link:hover {
      color: #000;
  }
  .news_tabs .nav-tabs li:last-child a {
      padding-right: 0 !important;
  }
  .news_letter {
      border-top: solid 1px #000;
      padding-top: 15px;
      position: relative;
  }
  .news_letter p {
      font-weight: 600;
      font-size: 16px;
  }
  .news_letter input {
      border: 0;
      border-bottom: solid 1px #ddd;
      width: 100%;
      outline: 0px;
      padding: 10px;      
      font-size:14px;
  }

  .news_tabs .nav-tabs .nav-link:hover, .news_tabs .nav-tabs .nav-link:focus {
    background-color: transparent;
  }

  .news_letter a,
  .news_letter span{
      position: absolute;
      right: 10px;
      top: 55px;
      color: #999;
  }

  .news_letter span {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.03em;
    color: #434141;
  }

  .news_letter h6 {
    font-weight: 500;
    font-size: 12px;
    line-height: 21px;
    letter-spacing: 0.03em;
    color: red;
    margin-top: 5px;
  }

  .news_letter a img {
    width: 16px;
  }
  .news_more .more_btn {
      width: 100%;
      display: block;
      text-align: right;
      color: #000;
      font-weight: 600;
      font-size: 14px;
  }
  .news_more_inner {
      padding: 20px 0;
      width: 80%;
      margin: auto;
  }
  .news_more_inner .more_contant {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
  .more_contant a {
      font-size: 12px;
      color: #590ec6;
      margin-right: 15px;
  }
  .more_contant h4 {
      color: #000;
      font-weight: 600;
      font-size: 18px;
      margin: 10px 0;
      cursor: pointer;
      line-height: 28px;
  }
  .more_contant p {
      font-size: 14px;
      line-height: 23px;
      color: #000;
      margin-bottom: 10px;
      width: 90%;
  }

  .more_contant p h3 {
    font-size: 14px !important;
    line-height: 23px !important;
    color: #000 !important;
    margin-bottom: 0px !important;
    text-align: left !important;
    font-weight: 400;
}

.more_contant p h3 div{
    font-size: 14px !important;
    line-height: 23px !important;
    color: #000 !important;
    margin-bottom: 0px !important;
    text-align: left !important;
    font-weight: 400;
}
  
  .more_contant span {
    color: #000;
    font-size: 14px !important;
    font-weight: 400 !important;
  }
  .news_update span {
      color: #9d9d9d;
      display: block;
      margin-bottom: 10px;
      font-size: 14px;
  }
  .news_update span i {
      margin-right: 10px;
  }
  .news_more_inner .row {
      margin-bottom: 100px;
      align-items: center;
      display: flex;
  }
  .dashboard_img img {
      width: 100%;
  }
  .agriculture img {
      max-width: 100%;
  }
  
  .font-effect-outline{
    cursor: pointer;
}
  
  @media (min-width: 768px) {
  
      /* show 3 items */
      .carousel-inner .active,
      .carousel-inner .active + .carousel-item,
      .carousel-inner .active + .carousel-item + .carousel-item,
      .carousel-inner .active + .carousel-item + .carousel-item + .carousel-item  {
          display: block;
      }
      
      .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
      .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item,
      .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item,
      .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item + .carousel-item {
          transition: none;
      }
      
      .carousel-inner .carousel-item-next,
      .carousel-inner .carousel-item-prev {
        position: relative;
        transform: translate3d(0, 0, 0);
      }
      
      .carousel-inner .active.carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
          position: absolute;
          top: 0;
          right: -25%;
          z-index: -1;
          display: block;
          visibility: visible;
      }
      
      /* left or forward direction */
      .active.carousel-item-left + .carousel-item-next.carousel-item-left,
      .carousel-item-next.carousel-item-left + .carousel-item,
      .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item,
      .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item,
      .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
          position: relative;
          transform: translate3d(-100%, 0, 0);
          visibility: visible;
      }
      
      /* farthest right hidden item must be abso position for animations */
      .carousel-inner .carousel-item-prev.carousel-item-right {
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          display: block;
          visibility: visible;
      }
      
      /* right or prev direction */
      .active.carousel-item-right + .carousel-item-prev.carousel-item-right,
      .carousel-item-prev.carousel-item-right + .carousel-item,
      .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item,
      .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item,
      .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
          position: relative;
          transform: translate3d(100%, 0, 0);
          visibility: visible;
          display: block;
          visibility: visible;
      }
  
  }
  
   /* Bootstrap Lightbox using Modal */
  
  #profile-grid { overflow: auto; white-space: normal; } 
  #profile-grid .profile { padding-bottom: 40px; }
  #profile-grid .panel { padding: 0 }
  #profile-grid .panel-body { padding: 15px }
  #profile-grid .profile-name { font-weight: bold; }
  #profile-grid .thumbnail {margin-bottom:6px;}
  #profile-grid .panel-thumbnail { overflow: hidden; }
  #profile-grid .img-rounded { border-radius: 4px 4px 0 0;}
  
  
  
  
  .live_port .furniture {
      padding: 0px;
  }
  
  .live_port .furniture input {
      outline: 0px;
      padding: 7px;
      border: solid 1px #ddd;
      height: 34px;
      font-size: 12px;
      width: 40%;
      border-radius: 5px;
  }
  
  
  .live_port_table .table td, .live_port_table .table th {
      border: 0; 
  }
  
.live_port_table .table td img {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    object-fit: cover;
}
  
  .live_port_table .table td 
   span {
      font-size: 12px;
      font-weight: 600;
      color: #999;
  }
  
  .live_port_table .table td h4 {
      display: block;
      font-size: 15px;
      margin-bottom: 5px;
      margin-top: 0px;
      color: #000;
  }
  
  .live_port_table .table th {
      font-size: 15px;
      font-weight: 500;
      color: #999;
  }

  .live_port_table .table td .price_count {
    font-size: 15px;
    font-weight: 500;
    color: #000;
  }

  .live_port_table .table td .price_count span {
    text-transform: lowercase;
  }

  .live_port_table .table td .quantity {
    font-size: 15px;
    color: #000;
    font-weight: 500;
}

.live_port_table .table td .quantity span {
    color: #9999a6;
    font-weight: 600;
    text-transform: lowercase;
}
  
  .live_port_table .table td sub{
      font-weight: 600;
  }
  
  .live_port_table .table td p {
      background-color: #FDF0F0;
      text-align: center;
      width: 60px;
      font-size: 14px;
      margin-bottom: 0px;
      margin-top: 5px;
      padding: 5px;
      color: #F01A1A;
  }
  
  .live_port_table .table td .timing {
      display: block;
      font-size: 12px !important;
      border: solid 2px #999;
      border-radius: 50px;
      padding: 3px 8px;
      text-align: center;
      margin-bottom: 5px;
  }
  .live_port_table .table td .getintouch {
    cursor: pointer;
}
  .success_story .owl-nav,
  .success_story .owl-dots {
    display: none;
}
  
  .live_port_table .table td a {
      color: #7A38DA;
      text-align: center;
      display: block;
      font-weight: 500;
  }
  
  .live_port_table .table tbody tr:first-child {
    border: solid 1px #e9e9e9;
}

.live_port_table .table tbody tr td {
    position: relative;
    font-size: 14px;
    padding: 15px 10px;
}

.live_port_table .table tbody tr td:last-child:before {
    position: absolute;
    content: "";
    width: 4px;
    right: 2px;
    top: 40%;
    height: 30px;
    background-color: #FFADAD;
}

.live_port_table .table tbody tr:nth-child(2) td:last-child:before {
    background-color: #FFDA91;
}

.live_port_table .table tbody tr:nth-child(3) td:last-child:before {
    background-color: #3BDD12;
}

.map_img {
    background-color: #fff;
    padding: 10px;
    margin-top: 35px;
    box-shadow: 0px 0px 5px #00000017;
    border-radius: 5px;
    display: inline-block;
    width: 100%;
}

.selling.more h3 {
    border-bottom: 0;
    line-height: 55px;
}

.selling.more span {
    max-width: 75%;
    text-transform: inherit;
    font-weight: 600;
    font-size: 15px;
}

.logistics_inner_box {
    background-color: #F8F8FA;
    padding: 50px;
    border-radius: 50px;
    border: solid 2px #000;
    margin-top: 50px;
}

.shipment>h4 {
    font-size: 16px;
    color: #000;
    margin-bottom: 15px;
}

.shipment .form-group {
    margin-bottom: 30px;
}

.shipment .form-group label {
    color: #7A38DA;
    font-size: 14px;
    font-weight: 400;
}

.shipment .form-group input {
    box-shadow: none;
}

.form-group.checkbox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.form-group.checkbox input {
    width: 15px;
    margin-left: 0px;
    margin-right: 15px;
}

.form-group.checkbox label {
    margin-bottom: 0;
}

.quantity label {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.quantity label span {
    font-size: 12px;
    color: #999;
}

.instant_btn button {
    display: block;
    padding: 10px 30px;
    border: 0;
    border-radius: 5px;
    background-color: #B180F9;
    color: #fff;
    outline: 0;
    cursor: pointer;
    margin-bottom: 5px;
}

.instant_btn span {
    font-size: 13px;
    color: #999;
}

.shipment .form-group input::placeholder {
    font-size: 12px;
    color: #999;
}

.search_outline button {
    border: solid 1px #7A38DA;
    padding: 10px 50px;
    border-radius: 5px;
    color: #7A38DA;
    outline: 0;
    cursor: pointer;
    background-color: transparent;
    margin-top: 20px;
    font-size: 14px;
}

.track_box:before {
    content: "\f002";
    position: absolute;
    top: 35px;
    font-size: 14px;
    left: 15px;
    z-index: 9;
    font: normal normal normal 14px/1 FontAwesome;
    color: #7a38da;
}

.track_box {
    position: relative;
}

.track_box input {
    padding-left: 40px;
}

.sustainability h3 a{
    font-size: 38px;
    text-align: center;
    margin-bottom: 0;
    color: #000;
}

.sustainability p {
  text-align: center;
  font-size: 16px;
  width: 100%;
  margin: auto;
  margin-top: 10px;
  color: #000;
}

.sustainability {
  padding: 60px 0;
  text-align: center;
}

.sustainability h3 strong{
    font-weight: 600;
}

.interested {
    background-color: #fff;
    padding: 100px 0;
}

.list_menu ul {
    padding-left: 0;
    list-style: none;
}

.list_menu ul li:not(:last-child) {
    padding-bottom: 34px;
}

.list_menu ul li a {
    text-transform: uppercase;
    font-size: 38px;
    font-style: italic;
    color: #fff;
    text-shadow: 0 1px 1px #000, 0 -1px 1px #000, 1px 0 1px #000, -1px 0 1px #000;
}

.interested_contant h4 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 25px;
}

.interested_contant span {
    font-size: 18px;
    line-height: 32px;
    font-weight: 500;
}

.footer_colum .colum_inner h6 {
    min-height: 26px;
    font-size: 16px;
    font-weight: 600;
    color: #000;
}

.footer_information_heading {
  padding-bottom: 20px;
}

.footer_colum .colum_inner ul {
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;
}

.footer_colum .colum_inner ul li {
    margin-bottom: 8px;
}

.footer_colum .colum_inner ul li a {
    font-size: 14px;
    font-weight: 500;
    color: #000; 
    text-transform: capitalize;
}



.footer_information_heading h4 {
    font-size: 20px;
    margin-bottom: 18px;
    color: #000;
    font-weight: 600;
}

.information .colum_inner span {
    font-size: 15px;
    color: #000;
    width: 90%;
    display: block;
    font-weight: 500;
}

.information_bottom {
    padding-top: 60px;
}

.information_bottom p {
    margin: 0;
    font-size: 15px;
    font-weight: 600;
    color: #000;
}

.footer_rumi {
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  text-align: right;
  padding-right: 75px;
  width: 100%;
  font-style: italic;
  color: #000;
}

.footer_copyright {
  margin-top: 20px;
  text-align: center;
  display: inline-block;
  width: 100%;
}

.footer_copyright p {
    margin-bottom: 0;
    font-size: 15px;
    font-weight: 600;
    color: #000;
}

.footer_copyright p a {
  color: #000;
}

.footer_colum .colum_inner ul li a:hover {
    color: #590ec6;
} 

.main-header .sc-bdnylx {
  justify-content: center;
}

.currencyStrip>.MuiInput-root {
  margin-right: 30px;
}

.currencyStrip>.MuiInput-root .MuiSelect-icon {
  top: calc(50% - 8px);
  font-size: 20px;
}

.currencyStrip .MuiSelect-select {
  font-size: 14px;
  padding: 10px 5px;
  min-height: auto;
}

.loqTTm .dropdwn .dropbtn {
  display: flex;
  padding: 0;
  margin-left: 18px;
  align-items: center;
  font-size: 14px !important;
  color: #373737;
  font-weight: 500;
}

.loqTTm .dropdwn .dropbtn .MuiSvgIcon-root {
  font-size: 18px !important;
  margin-left: 5px;
}

.loqTTm .column {
  background-color: transparent;
  height: auto;
}

.dropdown-content .column a {
  font-size: 14px;
  padding: 10px;
  font-weight: 500;
}

.dropdown-content .column a:hover {
  color: #7a38da;
  background-color: transparent !important; 
}

.dropdown-content.category {
  background-color: transparent;
  box-shadow: none;
  padding: 0px;
  left: -45%;
}

.inline_tags_aria {
  display: flex;
  border-top: solid 1px #ddd;
  padding: 40px 0;
  border-radius: 0px !important;
  box-shadow: none !important;
}

.inline_tags_aria .MuiGrid-grid-xs-2 {
  padding: 0 10px;
}

.dropdown-content.category li {
  width: calc(10% - 20px);
  float: left;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: 13px;
  font-weight: 500;
  margin: 0px 10px;
  margin-bottom: 30px;
  padding: 0;
  padding-bottom: 10px;
  color: #000;
  cursor: pointer;
  position: inherit;
}

.dropdown-content.category li .category_count {
  border-left: 0;
  padding-left: 0;
  border: solid 1px #000;
  padding: 2px 10px;
  line-height: 1;
  border-radius: 50px;
  margin-top: 8px;
  font-size: 12px;
}

.dropdown-content.category .row {
  margin-top: 32px;
  background-color: #fff;
  border-top: solid 1px #ddd;
  padding-top: 30px;
  height: 90vh;
  overflow: auto;
}

.dropdown-content.category li {
  font-size: 12px;
  padding: 0px 5px;
}

.dropdown-content.category li .category_name {
  display: block;
  border-left: 0;
  white-space: nowrap;
  text-align: center;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  margin-left: 0px;
}

.dropdown-content.category .column {
  width: 100%;
}

.dropdown-content.category li img {
  width: 100%;
  display: inline-block;
  margin-bottom: 10px;
  height: 100px;
  object-fit: cover;
  border-radius:6px;
  box-shadow:0 2px 7px rgb(145 145 145 / 35%);
  text-align:center;
  overflow:hidden;
}

.inline_tags_aria img {
  width: 100%;
  margin-bottom: 15px;
  height: 120px;
  object-fit: cover;
}

.inline_tags_aria p {
  font-size: 14px;
  border: 0;
  margin-bottom: 0;
  text-align: center;
  font-weight: 500;
}

.inline_tags_aria .listItems {
  border-bottom: 0;
}

.inline_tags_aria .MuiGrid-item {
  border-radius: 3px;
  padding-bottom: 0;
}

.inline_tags_aria .listItems {
  border: solid 1px #999;
  margin-bottom: 6px;
  padding: 6px;
  border-radius: 50px;
  width: 100%;
  font-size: 12px;
  white-space: nowrap;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.home_slider_main {
  padding: 60px 0 0;
}

.home_slider_main .selectEmpty {
  margin-top: 0;
  margin-left: 50px !important;
  width: 80%;
  margin: auto;
}

.inline_tags_aria .listItems:hover {
  border-color: #7a38da;
  color: #7a38da;
}

.banner_contant_type {
  display: flex;
  align-items: flex-end;
  margin-top: 50px;
}

.banner_contant_type .MuiAutocomplete-hasPopupIcon {
  width: 60%;
}

.banner_contant_type .MuiAutocomplete-hasPopupIcon .MuiFormControl-root {
  width: 100% !important;
}

.banner_contant_type  .MuiFormControl-root  {
  width: 30%;
  margin: 0;
}

.banner_contant_type  .MuiFormControl-root .MuiSelect-selectMenu {
  font-size: 14px;
}

.banner_contant_type .MuiAutocomplete-hasPopupIcon .MuiFormControl-root label {
  font-size: 11px;
  margin-bottom: 0;
  line-height: 11px;
}

.banner_contant h2 {
  width: 50%;
  font-size: 22px;
  line-height: 38px;
}

.banner_contant {
  padding-top: 40px;
  padding-left: 60px;
}

.banner_contant img {
  max-width: 180px;
}

.home_slider_main .Carousel-indicators-34 button {
  width: 70px;
  height: 3px;
  background-color: #ddd;
  border-radius: 0px;
}

.home_slider_main .Carousel-indicators-34 button span {
  display: none;
}

.home_slider_main .Carousel-indicators-34 {
  text-align: left;
  width: 50%;
  margin-left: 25px;
  position: relative;
  margin-top: 20px;
  padding-bottom: 10px;
}

.home_slider_main .Carousel-indicators-34:before, .home_slider_main .Carousel-indicators-34:after {
  position: absolute;
  content: "01";
  left: -25px;
  top: -5px;
  font-size: 20px;
  color: #c6c6c6;
  font-weight: 500;
}

.home_slider_main .Carousel-indicators-34:after {
  left: auto;
  right: -25px;
  content: "04";
}

.home_slider_main button.Carousel-active-37 {
  background-color: #000;
}

.product_inner img {
  width: 100%;
  height: 230px;
  object-fit: cover;  
  cursor: pointer;  
}

.product_inner p {
  text-align: left;
  font-weight: 500;
  padding-top: 10px;
  font-size: 14px;
  margin: 0;
  color: #000;
  cursor: pointer;
}

.selling {
  padding-bottom: 80px;
}

.logo_slider img {
  height: 35px;
  object-fit: contain;
}

.logo_slider .react-multiple-carousel__arrow {
  display: none;
}

.logo_slider li {
  text-align: center;
}

.agriculture2 img {
  height: 330px;
  width: 100%;
  object-fit: cover;
}

.welcome_koytola {
  margin-top: 50px;
    padding: 0px;
    text-align: center;
}

img {
  max-width: 100%;
}

.why_koytola .row.mt-5 {
  border-bottom: solid 1px #000;
}

.more_img img {
  width: 100%;
  height: 130px;
  object-fit: fill;
  border-radius: 5px;
}

#news_slider .row.mb-5 {
  margin-bottom: 50px;
}

.deals_port .makeStyles-search-14 {
  width: 50%;
  height: auto;
  padding: 6px;
  border-radius: 4px;
  border-color: #bebebe;
  margin: 0;
}

.deals_port .makeStyles-search-14 input {
  width: auto;
  font-size: 16px;
  padding: 6px 10px;
}

.deals_search {
  width: 50%;
  display: flex;
  align-items: center;
  margin-top: 50px;
  justify-content: center;
  margin-bottom: 20px;
}

.deals_view {
  width: 50%;
  text-align: right;
  font-size: 14px;
}

.deals_table_main {
  display: flex;
}

.deals_table_main th {
  font-size: 14px;
  color: #000;
}

.news_box img {
  height: 280px;
  object-fit: cover;
  cursor: pointer;
}

.news_img img {
  height: 280px;
  object-fit: cover;
}

.deals_table_main .MuiPaper-rounded {
  box-shadow: none;
}

.MuiTabs-scroller.MuiTabs-fixed button span {
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
}

#full-width-tabpanel-0 label .MuiFormControlLabel-label,
#full-width-tabpanel-1 label .MuiFormControlLabel-label,
#full-width-tabpanel-2 label .MuiFormControlLabel-label,
#full-width-tabpanel-3 label .MuiFormControlLabel-label,
#full-width-tabpanel-4 label .MuiFormControlLabel-label{
  font-size: 13px;
}

.daily_offer .owl-dots,
.daily_offer .owl-nav {
  display: none;
}

.offer_top .offer_left.w-100 {
  width: 100%;
  justify-content: space-between;
}

.news_koytola .row.mb-5 {
  margin-bottom: 50px;
}

.news_koytola .owl-nav,
.news_koytola  .owl-dots {
  display: none;
}

.daily_offer .react-multi-carousel-track button {
  font-size: 14px;
  padding: 6px 20px;
  border-color: #949494 !important;
}

.daily_offer .react-multi-carousel-track .react-multi-carousel-item {
  width: auto !important;
}

.strory_slider .owl-nav {
  display: none;
}

.strory_slider .owl-dots {
  text-align: right;
}

.sub_categiory {
  padding-bottom: 0;
  padding-top: 30px;
}

.sub_heading_ik {
  display: block;
  font-size: 14px;
}

.sub_heading_ik a {
  color: #7a38da;
  cursor: pointer;
}

.priceShow {
  width: 50%;
  float: left;
  margin-bottom: 30px;
  min-height: 90px;
  padding: 0px 10px;  
}

.priceShow .Product_data_name {
  display: block;
  font-size: 14px;
  margin-bottom: 8px;
  color: #6B6767;
  font-weight: 500;
}

.priceShow .Product_data_show {
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  color: #000;
}

.priceShow .Product_data_show .stars {
  font-size: 18px;
  margin-left: 5px;
}

.product_image_outer {
  box-shadow: none;
}

.priceShow .count {
  word-break: break-all;
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  margin-left: 10px;
}

.ViewCompanyouter {
  display: inline-block;
  width: 30%;
}

.product_name_header {
  display: flex;
  align-items: flex-end;
  margin-bottom: 50px;
}

.product_detail_heading {
  width: 70%;
}

.showDetaile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: solid 1px #ddd;
  padding-top: 20px;
  width: 100%;
}

.showDetaile .MuiAvatar-circle {
  float: left;
}

.avtar_main .top_saller {
  display: block;
  font-size: 14px;
  width: 100%;
  margin-bottom: 10px;
  font-weight: 500;
  color: #6B6767;
}

.avtar_main .user_name {
  display: inline-block;
  font-size: 18px;
  line-height: 1;
  font-weight: 500;
  color: #434141;
  margin-bottom: 5px;
}

.show_detail_right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
}

.show_detail_right .ViewCompanyButton {
  border-radius: 50px;
  text-transform: capitalize;
  display: block;
  margin-bottom: 12px;
  font-size: 12px;
  color: #000;
  font-weight: 600;
  padding: 2px 20px;
}

.show_detail_right .view_company {
  display: flex;
  align-items: center;
  font-size: 13px;
  justify-content: space-between;
  color: #7a38da;
  font-weight: 500;
  cursor: pointer;
}

.show_detail_right .view_company svg {
  margin-left: 5px;
}

.product_image_outer {
  position: relative;
  box-shadow: none !important;
}

.product_image_outer .products_image {
  height: 400px;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.product_image_outer .image_icon {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 999;
}

.product_image_outer .image_icon svg {
  display: block;
  background-color: #ffffffab;
  width: 30px;
  height: 30px;
  margin-bottom: 10px;
  padding: 5px;
  color: #333;
  border-radius: 50px;
}

.subImage {
  box-shadow: none !important;
}

.subImage img {
  width: 140px;
  max-height: 120px;
  object-fit: cover;
  height: 120px;
}

.product_description {
  margin: 50px 0;
}

.product_rosettes {
  margin-top: 100px;
}

.product_rosettes_heading {
  margin-bottom: 15px;
  display: block;
  font-size: 14px;
  color: #6B6767;
  font-weight: 500;
}

.product_description p {
  font-size: 15px;
  color: #000;
  line-height: 24px;
}

.specifications_main {
  padding: 0px 15px;
}

.detail_main_heading {
  font-size: 28px;
  color: #000;
  margin-bottom: 40px;
  margin-top: 0;
}

.detail_table th {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  padding: 0;
  padding-bottom: 15px;
  padding-top: 15px;
}

.detail_table td {
  font-size: 14px;
  color: #6B6767;
}

.detail_delivery {
  padding: 60px 15px;
}

.detail_delivery p {
  font-size: 16px;
  font-weight: 500;
}

.detail_delivery .MuiGrid-grid-xs-12 {
  padding: 0 !important;
}

.detail_review {
  padding: 60px 15px;
  padding-bottom: 0;
}

.detail_review .review_count {
  font-size: 80px;
  font-weight: 600;
  color: #000;
  margin-right: 20px;
}

.review_outer {
  display: flex;
  align-items: center;
}

.review_outer .ratting {
  font-size: 18px;
  font-weight: 600;
  color: #191919;
  text-transform: capitalize;
}

.review_outer .ratting span {
  display: block;
  font-size: 20px;
}

.review_outer .ratting span span {
  display: initial;
}
.write_review_btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.write_review_btn button {
  padding: 8px 20px;
  border-radius: 50px;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 600;
}

.you_minght {
  padding: 60px 0;
}

.you_minght .view_btn_outer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.you_minght .view_btn_outer button {
  font-size: 14px;
  padding: 6px 20px;
  border-radius: 50px;
  color: #000;
  font-weight: 600;
  text-transform: capitalize;
}

.you_minght_slider .slider_image {
  width: 100%;
  object-fit: cover;
  height: 200px;
  border-radius: 3px;
}

.you_minght_slider .react-multi-carousel-item {
  padding: 0 15px;
}

.create_review_popup .MuiDialog-paperWidthSm {
  width: 600px;
}

.review_writer_name {
  display: block;
  font-size: 16px;
  text-transform: capitalize;
  color: #444444;
  font-weight: 500;
  margin-bottom: 5px;
}

.review_writer_location {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  color: #9B9B9B;
}

.review_star span {
  font-size: 14px;
}

.review_desc {
  font-size: 14px;
  margin: 10px 0;
}

.review_right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.review_right .last_text {
  font-size: 14px;
  color: #9B9B9B;
}

.right_review {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;
}

.right_review .thum_icon svg {
  margin: 0px 10px;
}

.show_review_date {
  font-size: 12px;
  color: #9B9B9B;
}

.resource_btns_main {
  padding-bottom: 60px;
}

.Rosettes_button {
  font-size: 14px !important;
  margin-right: 15px !important;
  padding: 6px 20px !important;
  border-radius: 50px !important;
  font-weight: 600 !important;
}

.resource_btns_main .detail_main_heading {
  margin-bottom: 10px;
}

.fillter_inner {
  text-align: center;
  padding: 0px 50px;
  box-shadow: rgb(0 0 0 / 4%) 0px 5px 5px !important;
  padding-top: 0px;
  padding-bottom: 0px;
  margin: 20px 0;
  border-radius: 0px !important;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

#full-width-tabpanel-0>.MuiBox-root,
#full-width-tabpanel-1>.MuiBox-root,
#full-width-tabpanel-2>.MuiBox-root,
#full-width-tabpanel-3>.MuiBox-root,
#full-width-tabpanel-4>.MuiBox-root {
  padding: 0;
}

.fillter_inner label,
.selected_fillter_inner label {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 100px;
  float: left;
}

.fillter_inner.ratting {
  padding-bottom: 20px;
}

.fillter_inner.ratting label {
  justify-content: center;
  margin-bottom: 0;
}

.fillter_inner.ratting label:last-child {
  display: none;
}

.fillter_inner label .MuiIconButton-colorSecondary,
.selected_fillter_inner label .MuiIconButton-colorSecondary {
  background-color: #d8d8d8;
  border-radius: 50px;
  height: 25px;
  width: 25px;
  margin-right: 10px;
  color: transparent !important;
}

.fillter_inner label .MuiCheckbox-colorSecondary.Mui-checked,
.selected_fillter_inner label .MuiCheckbox-colorSecondary.Mui-checked {
  background-color: #7a38da;
}

.selected_fillter_outer {
  width: 100%;
  margin: 40px auto;
  box-shadow: none !important;
  border-bottom: solid 1px #ddd;
  border-radius: 0 !important;
}

.fillter_inner label .MuiFormControlLabel-label {
  font-size: 13px !important;
  font-weight: 600;
}

.selected_fillter_inner label .MuiFormControlLabel-label {
  font-size: 13px !important;
  font-weight: 600;
}

.selected_fillter_outer>p {
  font-size: 14px;
  color: #464646;
}

.selected_fillter_inner {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.detailProdheader {
  padding: 20px 0 !important;
  justify-content: space-between;
}

.detailProdheader .MuiCardHeader-content span {
  font-size: 28px;
  font-weight: 600;
}

.detailProdheader .MuiCardHeader-action {
  align-self: center;
  border-left: solid 1px #ddd;
}


.profile_header_rigth svg {
  color: #000;
  font-size: 20px;
}

.profile_header_rigth button {
  font-size: 16px;
  color: #6b6b6b;
  display: block;
  padding: 0;
}

.profile_header_rigth .right_followars {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.profile_header_rigth .right_followars .btn-follow {
  border: solid 1px #929292;
  color: #000;
  padding: 5px 20px;
  line-height: 1;
  border-radius: 50px;
  margin-top: 6px;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 400;
}

.latest_plat_main {
  padding: 40px 0;
}

.latest_plat_box span {
  font-size: 15px;
  display: inline-block;
  font-weight: 500;
  color: #000;
}

.latest_plat_box h2 {
  color: #3A3A3A;
  font-weight: 600;
  font-size: 45px;
}

.profile_right .flex-row {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.supplier_infoaria .MuiAvatar-colorDefault {
  background-color: transparent;
  border: solid 1px #ddd;
}

.detailProdheader .MuiAvatar-root {
  background-color: transparent;
  border: solid 1px #ddd;
}

.supplier_infoaria .MuiAvatar-colorDefault img {
  height: 40px;
  width: 40px;
  object-fit: cover;
}

.profile_right .supplier_info h5 {
  font-size: 16px;
  font-weight: 400;
}

.profile_right .supplier_info span {
  font-size: 14px;
  display: inline-block;
}

.profile_right .zkitcard_foright .btn-follow {
  font-size: 14px;
  border: solid 1px #929292;
  padding: 4px 25px;
  line-height: 1;
  border-radius: 50px;
  font-weight: 500;
  cursor: pointer;  
}

.company_rosettes_box_outer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.company_rosettes_box_outer .commpany_rosettes_box {
  width: 23%;
  margin-right: 20px;
  border: solid 1px #ddd;
  border-radius: 40px;
  padding: 3px 8px;
  margin-bottom: 20px;
}

.company_rosettes_box_outer .commpany_rosettes_box img {
  width: 30px;
  margin-right: 10px;
}

.company_rosettes_box_outer .commpany_rosettes_box span {
  font-size: 12px;
  color: #000;
}

.list_item_outer .list_cheked span svg {
  font-size: 30px;
  color: #00000059;
}

.profile_tag_header .profile_tag {
  border: solid 1px #1C1C1C;
  text-align: center;
  padding: 4px 30px;
  border-radius: 50px;
  font-size: 14px;
  color: #1C1C1C;
}

.profile_tag_header {
  display: inline-block;
  border-bottom: solid 1px #ddd;
  width: 100%;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.profile_tag_header.resettes {
  border-bottom: 0;
  margin-bottom: 0;
}

.rosettes_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: solid 1px #ddd;
  padding: 10px;
  flex-direction: row-reverse;
}

.rosettes_box p {
  margin-bottom: 0;
  color: #262525;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
}

.cartipy_pi_name {
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 500;
  color: #000;
}

.profile_tag_main {
  padding: 60px 0;
}

.profile_gallery_heading {
  display: inline-block;
  width: 100%;
  margin-bottom: 30px;
}

.profile_gallery_heading h2 {
  color: #323232;
  font-size: 40px;
  font-weight: 600;
}

.gallery_slider  .Carousel-indicators-32 {
  display: none;
}

.gallery_slider_main img,
.gallery_slider_main video {
  width: 100% !important;
  height: 300px;
  object-fit: cover;
  margin: auto;
  margin-bottom: 25px;
}

.gallery_slider img,
.gallery_slider video {
  height: 500px;
}

.gallery_slider {
  width: 100%;
}

.gallery_slider_main {
  padding: 0;
}

.industry {
  width: 50%;
  float: left;
}

.industry .industry_box {
  margin-bottom: 20px;
  padding-right: 20px;
}

.industry .industry_box span {
  font-size: 14px;
  color: #333;
  display: inline-block;
  width: 100%;
  margin-bottom: 8px;
}

.industry .industry_box p {
  font-size: 16px;
  color: #000;
  margin-bottom: 0;
  text-transform: capitalize;
}

.industry_outer {
  padding-bottom: 60px;
}

.mambership_box ul {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}

.mambership_box ul li {
  padding-bottom: 15px;
  font-weight: 500;
  font-size: 16px;
  color: #262525;
}

.profile_mission {
  padding: 60px 0px;
}

.profile_heading_maltipal h2 {
  margin: 0;
  color: #323232;
  font-size: 30px;
  font-weight: 600;
}

.mission_inner_contant h3 {
  font-size: 40px;
  line-height: 52px;
  color: #262525;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 50px;
}

.mission_inner_contant h3 span {
  display: inline-block;
}

.mission_inner_contant p {
  font-size: 20px;
  line-height: 30px;
  color: #000;
  width: 85%;
}

.products_gallery .row.align-items-center {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}

.products_gallery_tag {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.products_gallery_tag .tag {
  margin: 0 20px;
  font-size: 14px;
  font-weight: 500;
  border: solid 1px #999;
  padding: 4px 25px;
  border-radius: 50px;
}

.products_gallery {
  padding: 60px 0;
}

.product_gallery_image img {
  width: 100%;
  height: 350px;
  object-fit: cover;
  cursor: pointer;
}
.product_gallery_image p {
    text-align: left;
    font-weight: 500;
    padding-top: 10px;
    padding-left: 10px;
    font-size: 14px;
    margin: 0;
    color: #000;
    cursor: pointer;
}
.products_gallery .col-md-4 {
  margin-bottom: 30px;
}

.products_gallery_pagination button {
  font-size: 16px;
  width: 40px;
  height: 40px;
  border-radius: 50px;
}

.products_gallery_pagination button svg {
  font-size: 18px;
}

.products_gallery_pagination {
  margin-top: 20px;
  margin-left: -20px;
}

.border_left_right {
  border-left: solid 1px #ddd;
  border-right: solid 1px #ddd;
}

.border_after_before {
  position: relative;
}

.border_after_before:before,
.border_after_before:after {
  position: absolute;
  left: -50px;
  content: "";
  height: 40px;
  width: 1px;
  background-color: #ddd;
  top: 40%;
}

.border_after_before:after {
  right: 60px;
  left: auto;
}

.profile_tab_panel {
  padding: 60px 0;
}

.profile_tab_panel .profile_heading_maltipal {
  margin-bottom: 40px;
  padding-bottom: 20px;
  border-bottom: solid 2px #ddd;
}

._2Jtxm._35Ago ._2kL0S {
  background-color: #7a38da !important;
}

.create_query {
  display: inline-block;
  width: 100%;
  padding-bottom: 60px;
  text-align: left;
}

.create_query h3 {
  font-size: 26px;
  font-weight: 600;
  color: #000;
}

.select_box_inner {
  border: solid 1px #ddd;
  border-radius: 10px;
  max-height: 320px;
  overflow-y: auto;
}

.select_categiory_outer {
  padding: 15px 25px;
  border-bottom: solid 1px #ddd;
}

.select_categiory_outer .select_categiory:before,
.select_categiory_outer .select_categiory:after {
  display: none;
}

.select_categiory_outer .select_categiory {
  font-size: 16px;
  color: #000;
}

.select_categiory_outer .select_categiory svg {
  font-size: 25px;
  right: -10px;
  color: #000;
}

.query_summery_box {
  border: solid 1px #ddd;
  border-radius: 10px;
  padding-bottom: 15px;
}

.selected_item_make_order {
  display: inline-block;
  width: 100%;
  padding-left: 20px;
  padding-top: 20px;
  font-size: 14px;
  color: #999;
}
.query_summery_box .inner_heading {
  padding: 15px 25px;
  border-bottom: solid 1px #ddd;
}

.query_summery_box .inner_heading h4 {
  margin: 0;
  font-size: 16px;
  color: #000;
}

.select_categiory_outer .select_categiory>div {
  padding-top: 0;
  padding-bottom: 0;
}

.list_item_outer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.select_grid_inner li {
  width: 100% !important;
  height: auto !important;
  padding: 15px 20px !important;
  border-bottom: solid 1px #ddd;
}

.select_box_inner .makeStyles-root-45 {
  display: inline-block;
  width: 100%;
}

.list_item_outer .list_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;
}

.list_item_outer .list_item .list_img {
  width: 20%;
}

.list_item_outer .list_item .list_contant {
  width: 75%;
}

.list_item_outer .list_cheked {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list_item_outer .list_item .list_img img {
  width: 100%;
  height: 80px;
  object-fit: cover;
  border-radius: 2px;
}

.list_item_outer .list_item .list_contant span {
  font-size: 16px;
  font-weight: 500;
  color: #070707;
  display: inline-block;
  padding-bottom: 5px;
}

.list_item_outer .list_item .list_contant p {
  margin-bottom: 0;
  font-size: 14px;
  color: #676565;
}

.list_item_outer .list_cheked span {
  font-size: 15px;
  color: #000;
  font-weight: 500;
}

.select_box_uli {
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.select_box_uli .selected_img {
  width: 20%;
}

.select_box_uli .selected_img img {
  width: 60px;
  height: 60px;
  border-radius: 2px;
  object-fit: cover;
}

.select_box_uli .selected_contant {
  width: 80%;
}

.select_box_uli .selected_contant span {
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  padding-bottom: 5px;
}

.select_box_uli .selected_contant p {
  margin-bottom: 0;
  font-size: 13px;
  color: #676565;
}

.create_query h3 span {
  font-size: 18px;
  display: inline-block;
  padding-right: 20px;
  color: #CECECE;
  font-weight: 500;
}

.regions_main .profile_heading_maltipal {
  padding-bottom: 60px;
}

.regions_contery {
  border: solid 1px #ddd;
  padding: 30px;
  border-radius: 10px;
}

.regions_contery h3 {
  margin-top: 0;
  margin-bottom: 30px;
  font-size: 16px;
}

.regions_contery_inner p {
  font-size: 14px;
  margin-bottom: 15px;
}

.regions_main {
  padding: 0px 0 30px;
}

.social_activity {
  padding: 60px 0;
}

.social_activity .profile_heading_maltipal {
  padding-bottom: 60px;
}

.social_activity .profile_heading_maltipal h2 {
  border-bottom: solid 2px #ddd;
  padding-bottom: 15px;
  width: 50%;
}

.social_activity .profile_tag_header {
  margin-bottom: 0;
  border-bottom: 0;
}

.social_activity .profile_tag_header .profile_tag {
  font-weight: 500;
  padding: 6px 30px;
}

.responsibilities {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  align-items: center;
}

.responsibilities .image_box {
  width: 25%;
}

.responsibilities .image_box img {
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 2px;
}

.responsibilities .image_box video {
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 2px;
}

.responsibilities .contant_box {
  width: 75%;
  padding-left: 30px;
}

.responsibilities .view_time {
  width: 10%;
}

.responsibilities .contant_box .tag {
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  text-transform: uppercase;
  color: #7a38da;
}

.responsibilities .view_time .view_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdown.header_user {
  text-align: center;
  font-size: 14px;
  margin-left: 10px;
}

.category-mobile .categiory_btn {
  position: relative;
}

.category-mobile .categiory_btn:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 22px;
  left: -10px;
  background-color: #ddd;
}

.responsibilities .contant_box p {
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
}

.responsibilities .view_time .view_box img {
  width: 15px;
}

.responsibilities .view_time .view_box span {
  font-size: 14px;
}

.responsibilities .view_time .time_line {
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: space-between;
}

.responsibilities .view_time .time_line svg {
  color: #000;
  font-size: 18px;
}

.responsibilities .view_time .time_line span {
  font-size: 16px;
}

.responsibilities .contant_box .view_time {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.responsibilities .contant_box .view_box {
  display: flex;
  align-items: center;
}

.responsibilities .contant_box .view_box img {
  width: 16px;
  margin-right: 10px;
}

.responsibilities .contant_box .view_box span {
  font-size: 15px;
}

.responsibilities .contant_box .view_time .time_line {
  display: flex;
  align-items: center;
}

.responsibilities .contant_box .view_time .time_line svg {
  font-size: 17px;
  color: #000;
  margin-right: 10px;
}

.responsibilities .contant_box .view_time .time_line span {
  font-size: 16px;
  color: #000;
}

.supplier_pic img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  background-color: #ddd;
}

.dropdown-content.category li:hover {
  background-color: transparent;
}

.dropdown-content.category li:hover .category_name {
  color: #7a38da;
}

.home_slider_main .owl-dots {
  text-align: left;
}

.home_slider_main .owl-dots {
  width: 50%;
  float: left;
  text-align: left !important;
  position: relative;
  margin-left: 25px;
  margin-top: 15px;
}
.home_slider_main .owl-dots:before, .home_slider_main .owl-dots:after {
  position: absolute;
  content: "01";
  left: -25px;
  top: -5px;
  font-size: 20px;
  color: #c6c6c6;
  font-weight: 500;
}
.home_slider_main .owl-dots:after {
  left: auto;
  right: -25px;
  content: "05";
}

.home_slider_main .owl-nav {
  width: 30%;
  float: right;
  margin-top: 0 !important;
  position: relative;
}
.home_slider_main .owl-nav button {
  position: absolute;
  left: 0;
  top: 0;
  outline: 0;
  background-color: transparent !important;
  color: #000 !important;
}
.home_slider_main .owl-nav button.owl-next {
  right: 0 !important;
  left: auto;
}
.home_slider_main .owl-nav button span {
  opacity: 0;
}

.home_slider_main .owl-nav button:after{
  border: 0;
  content: "\f061";
  position: absolute;
  top: 14px;
  right: 0px;
  z-index: 9;
  font: normal normal normal 14px/1 FontAwesome;
}
.home_slider_main .owl-nav button.owl-prev:after {
  transform: rotate(180deg);
}

.logo_slider .owl-nav,
.logo_slider .owl-dots {
  display: none;
} 

.top_products .owl-nav {
  display: none;
}

.top_products .owl-dots {
  text-align: left;
  position: absolute;
  top: -60px;
}

.top_products .owl-carousel {
  margin-top: 80px;
}

.product_slider.category .owl-dots {
  display: none;
}

.gallery_slider .owl-nav,
.gallery_slider .owl-dots {
  display: none;
}

.progressbar {
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
}

.progressbar .CircularProgressbar {
  width: 70px;
}

.progressbar .row {
  display: flex;
  align-items: center;
}

.right.pz {
  display: none;
}

.kilogram_box .kilogram_input {
  outline: 0;
  text-align: center;
  border-radius: 10px;
  height: 50px;
  min-height: 250px;
  width: 100%;
  margin-right: 20px;
  padding: 15px;
  border: solid 1px #ddd;
  font-style: normal;
  font-weight: 600;
  font-size: 70px;
  line-height: 1;
  color: #000000;
}

.kilogram_box {
  position: relative;
  width: 60%;
  text-align: center;
}

.kilogram_box span {
  font-size: 18px;
  color: #000;
  position: absolute;
  right: 20px;
  top: 20px;
}
.select_box_uloi {
  padding: 15px;
}

.select_box_uloi .select_pro {
  display: inline-block;
  width: 100%;
  margin-bottom: 15px;
  font-size: 16px;
  color: #999;
  text-transform: capitalize;
}

.wizardForm_wrap ul._1Lo2h{
  display:none;

}

.purity_contnt span {
  font-size: 14px;
  color: #000;
}

.purity_contnt p {
  font-size: 14px;
  margin-bottom: 0;
  margin-top: 5px;
  color: #999;
}

.purity_sec {
  margin-top: 15px;
  margin-left: 15px;
}

.purity_sec span {
  font-size: 14px;
  display: inline-block;
  color: #999;
  margin-bottom: 5px;
}

.purity_sec p {
  font-size: 14px;
  color: #000;
}

.create_query.border_bottom {
  border-bottom: solid 1px #ddd;
  padding-bottom: 20px;
}

.progressbar.bottom_space {
  padding-bottom: 60px;
}

.select_grid_inner {
  width: 100%;
}

.select_country_inner .category:before,
.select_country_inner .category:after {
  display: none;
}

.select_country_inner .category {
  font-size: 16px;
  font-weight: 500;
  color: #000;
}

.select_country_inner .category svg {
  font-size: 24px;
}

.massage_inner .massage_box {
  width: 60%;
  min-height: 250px;
  border: solid 1px #ddd;
  border-radius: 10px;
  padding: 20px;
  outline: 0px;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.045em;
  color: #000000;
}
.selected_item_make_order {
  display: inline-block;
  width: 100%;
  padding-left: 20px;
  padding-top: 20px;
  font-size: 14px;
  color: #999;
}
.cths {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cths h5 {
  margin: 0;
  padding-right: 120px;
  font-weight: 400;
}
.select_country_inner {
  text-align: left;
  width: 60%;
  border: solid 1px #ddd;
  border-radius: 10px;
  padding: 20px;
  min-height: 250px;
}

.select_country_inner p {
  text-align: center;
  margin: 30px 0;
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 1;
  color: #000000;
}


.massage_inner .massage_box {
  width: 60%;
  min-height: 250px;
  border: solid 1px #ddd;
  border-radius: 10px;
  padding: 20px;
  outline: 0px;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.045em;
  color: #000000;
}

.submit_filds .input_fild {
  margin-bottom: 40px;
}

.submit_filds .input_fild input {
  border-radius: 5px;
  font-size: 14px;
  padding: 15px;
  outline: 0;
}

.submit_filds .input_fild label {
  margin-bottom: 0;
  line-height: 8px;
  font-size: 12px;
}

._3uApM {
  width: 40%;
  float: right;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 50px;
}

._3uApM a {
  border-radius: 50px;
  font-size: 14px;
  padding: 8px 30px;
  margin-right: 40px;
}

._3uApM ._2pGos._hsN1w {
  background-color: #7a38da;
  border-color: #7a38da;
}

._3uApM ._2pGos._hsN1w:hover {
  background-color: transparent;
  box-shadow: 0px 2px 4px #7a38da80;
}

.progress {
  width: 60px;
  height: 60px;
  font-size: 16px;
  color: #fff;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  background: #fff;
  text-align: center;
  line-height: 60px;
  float: right;
}

.progress .overlay {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: #fff;
}

.progress::after {content: "%";}

.progress .title {
  position: relative;
  z-index: 100;
  color: #999;
  padding-left: 10px;
}


.progress .left, .progress .right {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 4px solid #ceb3f5;
  border-radius: 100px 0px 0px 100px;
  border-right: 0;
  transform-origin: right;
}

.progress .left {animation: load1 1s linear forwards;}

.progress:nth-of-type(2) .right, .progress:nth-of-type(3) .right {animation: load2 .5s linear forwards 1s;}

.progress:last-of-type .right, .progress:first-of-type .right {animation: load3 .8s linear forwards 1s;}

@keyframes load1 {
  0% {transform: rotate(0deg);}

  100% {transform: rotate(180deg);}
}

@keyframes load2 {
  0% {z-index: 100;transform: rotate(180deg);}

  100% {z-index: 100;transform: rotate(270deg);}
}

@keyframes load3 {
  0% {z-index: 100;transform: rotate(180deg);}

  100% {
      z-index: 100;transform: rotate(315deg);}
}

.company_top_space {
  margin-top: 0px !important;
  align-items: center;
  margin-bottom: 20px !important;
  border-bottom: solid 1px #ddd;
  padding-bottom: 15px;
}

.product_slider.category .item img {
  height: 180px;
  object-fit: cover;
}

.deals_port_table td svg {
  display: block;
  font-size: 22px;
}

.deals_port_table td span {
  font-size: 13px;
  margin: 5px 0;
  display: inline-block;
}

.sub_categiory_slider img {
  height: 150px;
  object-fit: cover;
}

.single_box img {
  width: 100%;
  height: 450px;
  object-fit: cover;
}

.duble_box img.w-100 {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.duble_box .offer_top .offer_left img {
  height: 50px;
  width: 50px;
  object-fit: cover;
}

.home_slider_main .owl-carousel img {
  height: 70px;
  object-fit: cover;
  width: 100%;
  border-radius: 10px;
}

.counter_heading h3.top_hed {
  font-weight: 400;
  margin-bottom: 20px;
  font-size: 32px;
}

.home_top_img {
  text-align: center;
}

.home_top_slider{
  margin-bottom: 30px;
  box-shadow: 0px 4px 5px #00000038;
  padding: 10px;
  border-radius: 20px;
}

.sec_counter.sec_space {
  padding: 0;
}

.home_slider_main .owl-carousel .col-md-2 {
  padding: 0px 5px;
  width: 20%;
}

.sub_categiory_slider h5 {
  font-size: 14px;
  line-height: 20px;
}

.daily_offer .section_heading {
  padding-bottom: 30px;
}

.discover_main_menu .dropdown-content {
  background-color: transparent;
  box-shadow: none;
  left: auto;
}

.discover_main_menu .dropdown-content .row {
  background-color: #fff;
  margin-top: 35px;
  border: solid 1px #ddd;
  padding: 20px;
}
.product_grid_list{
  padding: 0 15px !important;
}
.listing_img_box {
  cursor: pointer;
  border-radius:6px;
  box-shadow:0 2px 7px rgb(145 145 145 / 35%);
  text-align:center;
  overflow:hidden;
  
}

.zkitProdheader .MuiCardHeader-avatar img {
  height: 40px;
  width: 50px;
  object-fit: cover;
}

.products_sub_image .MuiPaper-rounded {
  min-width: 160px;
  width: 160px;
}

.products_sub_image {
  width: 100%;
  display: flex;
  overflow-x: auto;
  margin-top: 20px;
}

::-webkit-scrollbar {
  background-color:#fff;
  width:5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background-color:#fff
}

::-webkit-scrollbar-thumb {
  background-color:#7a38da85;
  border-radius:0px;
}
::-webkit-scrollbar-thumb:hover {
  background-color:#7a38da;
}

::-webkit-scrollbar-button {
  display:none;
}

.rosettes_button_pr {
  display: inline-block;
  width: 100%;
  margin-top: 10px;
}

.rosettes_button_pr button {
  margin: 0;
  margin-right: 15px;
  margin-bottom: 15px;
  font-size: 13px;
  border-radius: 100px;
  text-transform: capitalize;
  color: #000;
}

.detail_review .MuiGrid-item {
  padding: 0 !important;
}

.prod_user_detail .pro_represnt {
  width: 100%;
  display: block;
  font-size: 12px;
  color: #858383;
  font-weight: 500;
}

.prod_user_detail {
  display: inline-block;
  margin-left: 10px;
  margin-top: 2px;
}

.detail_delivery .MuiGrid-item {
  padding: 0 !important;
  padding-bottom: 20px !important;
  padding-top: 20px !important;
}

.news_tabs .marquee-container:before,
.news_tabs .marquee-container:after {
  background: transparent;
}

.daily_offer .marquee {
  width: 100%;
  justify-content: flex-start !important;
}

.daily_offer .marquee button:hover {
  color: #7A38DA;
  border-color: #7A38DA;
  background-color: transparent;
}

.main-header {
  display: initial;
}

.fcBWsD .dropdwn .dropbtn svg {
  font-size: 18px;
  position: relative;
  top: 4px;
  left: 5px;
}

.fcBWsD .column {
  background-color: #fff;
  height: auto;
}

.profile_tag_n {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.profile_tag_n .tag_name {
  width: 70%;
}

.profile_tag_n .tag_detail {
  width: 30%;
}

.profile_tag_n_outer {
  margin-top: 30px;
}

.profile_tag_n .tag_name .name {
  font-size: 18px;
  font-weight: 500;
  color: #383737;
  text-transform: capitalize;
}

.profile_tag_n .tag_detail span {
  font-size: 14px;
  color: #797979;
  margin: 0 20px;
  font-weight: 400;
  display: inline-block;
}

.marquee-container.horizontal::before,
.marquee-container.horizontal::after {
  display: none;
}

.daily_offer .marquee-container.horizontal {
  min-height: 70px !important;
  border-bottom: solid 1px #EEEEEE;
  border-top: solid 1px #EEEEEE;
  margin-top: 30px;
  margin-bottom: 50px;
}

.daily_offer .dealy_offter_marquee {
  position: relative;
}

.daily_offer .dealy_offter_marquee:before,
.daily_offer .dealy_offter_marquee:after {
  border: 0;
  content: "\f105";
  position: absolute;
  top: 20px;
  left: -20px;
  z-index: 9;
  font: normal normal normal 20px/1 FontAwesome;
  transform: rotate(180deg);
  font-size: 32px;
  color: #777777;
  cursor: pointer;
}

.daily_offer .dealy_offter_marquee:after {
  right: -20px;
  transform: rotate(0deg);
  left: auto;
}


.marquee-item {
  margin: 0 10px;
}

.marquee-item button {
  font-size: 13px;
  text-transform: capitalize;
  border: solid 1px #2f2f2f;
  color: #2f2f2f;
  border-radius: 50px;
  line-height: 1;
  padding: 7px 20px;
}

.rosettes_box_outer {
  max-height: 300px;
  overflow: auto;
}

.cont_space_remove {
  margin-top: 40px;
}

.live_port .float-right a {
  font-size: 14px;
}


.news_koytola .col-md-8 {
  width: 66.66666667%;
}

.rosettes_box img {
  width: 40px;
  height: 40px;
} 

.news_box_contant span {
  font-size: 14px !important;
  color: #393939 !important;
  font-weight: 400 !important;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 65px;
}
 
.news_contant span {
  font-size: 14px !important;
  color: #393939 !important;
  font-weight: 400 !important;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 140px;
}

.news_contant span h1 {
  margin-top: 0;
}

.news_box_contant span h3 {
  font-size: 14px !important;
  color: #393939 !important;
  font-weight: 400 !important;
  text-align: left !important;
}

.news_more_inner .more_contant p h1 {
  font-size: 14px;
  line-height: 23px !important;
  color: #000;
  margin-bottom: 10px !important;
}

.gallery_btns {
  width: 100%;
  text-align: right;
  display: inline-block;
  margin-top: 30px;
}

.gallery_btns button {
  font-size: 16px;
  border: solid 1px #ddd;
  border-radius: 50px;
  margin-left: 20px;
  padding: 10px 35px;
  line-height: 1;
  cursor: pointer !important;
  pointer-events: auto !important;
  transition: 0.5s;
}

.gallery_btns button:hover {
  background-color: #ececec !important;
}

.live_port .float-right {
  text-align: right;
}

.top_bar_nav {
  margin-bottom: 30px;
  margin-top: 30px
}

.top_bar_nav span {
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 500;
  color: #000;
}

// .zkitProductbox {
//   min-height: 638px;
// }

.listing_more_btn {
  text-align: center;
}

.listing_more_btn .listing_view_btn {
  padding: 10px 25px;
  border-radius: 50px;
  font-size: 14px;
  line-height: 1;
  color: #7a38da;
  border: solid 1px #7a38da !important;
  background-color: #fff !important;
}

.errorlo {
  color: red;
  text-align: center;
  width: 100%;
}

.specification_colum_head {
  margin-bottom: 30px !important;
}

.specification_colum_head span {
  font-size: 16px;
  font-weight: 500;
  color: #000;
}

.specification_colum {
  margin-bottom: 8px !important;
}

.specification_colum p {
  font-size: 14px;
  font-weight: 600;
  color: #3e3e3e;
  margin-bottom: 0;
}

.specification_colum span {
  font-size: 14px;
  color: #505050;
}

.specification_colums.MuiGrid-item {
  margin: 0px !important;
  margin-bottom: 15px !important;
  border-bottom: solid 1px #ddd;
  padding-bottom: 15px;
}

.mission_inner_contant span {
  display: inline-block;
  font-size: 16px;
  line-height: 26px;
  color: #323232;
  width: 90%;
  text-transform: lowercase;
}

.product_image_outer .image_icon span {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  background-color: #ffffffab;
  width: 30px;
  height: 30px;
  padding: 5px;
  color: #333;
  border-radius: 50px;
}

.mission_inner_contant span:first-letter {
  text-transform: capitalize;
}

.fcBWsD .dropdwn .dropbtn {
  font-size: 14px;
  padding: 0px;
  margin-left: 20px;
}

.welcome_box .sign_up img {
  width: 22px;
  margin-left: 8px;
}

.product_align_slider {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.dropdown-content.category li span:before {
  display: none;
}

.dropdown-content.category li span {
  color: #000;
}

.daily_offer .view_more {
  margin-bottom: 50px;
  margin-top: 100px;
}

.top_products.category .owl-carousel {
  margin-top: 0;
}

.product_slider.category {
  margin-right: 0;
}

.product_slider.category .item {
  position: initial;
  padding-left: 0px;
  padding: 0px 5px;
}

.strory_slider .owl-stage-outer .active::first-child {
  margin-top: 50px !important;
}

.footer_colum .colum_inner.tradeTech li {
 
}

.slect_fillter_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.slect_fillter_header p {
  font-size: 14px;
  color: rgb(70, 70, 70);
  margin-bottom: 0;
}

.slect_fillter_header button {
  background-color: transparent;
  color: #000;
  text-transform: capitalize;
  font-size: 14px;
  border: solid 1px #d8d8d8;
  border-radius: 50px;
  padding: 8px 20px;
  line-height: 1;
  transition: .5s;
}

.slect_fillter_header button:hover {
  background-color: #d8d8d8;
}

.producty_list_border {
  border: solid 0.5px #f0f0f0b0;
  width: 100%;
  position: relative;
  top: -20px;
}

.ViewCompanyouter button {
  font-size: 12px;
  padding: 4px 15px;
}

.banner_contant_type .products_search .MuiFormControl-root {
  width: 100%;
  margin: 0;
}

.dropdown-content.category li .category_count {
  margin: 0px !important;
  margin-top: 6px !important;
}

.certificates_outerm {
  display: flex;
  align-items: flex-start;
  overflow-y: auto;
  flex-wrap: wrap;
  max-height: 300px;
}

.certificates_outerm .product_inner {
  display: inline-block;
    margin: 0px 10px;
    margin-bottom: 20px;
    padding: 0px 10px;
    text-align: center;
    width: 140px;
}

.certificates_outerm .product_inner p {
  text-align: center;
}

.certificates_outerm .product_inner img {
  padding-right: 0;
  height: 50px;
  width: 50px;
}

.blog_detail_inner {
  padding: 30px 0;
}
.page_not_found{
  text-align: center;
    padding: 50px;
    font-size: large;
    font-weight: 500;
}
.blog_detail_inner h4 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 24px;
}
.blog_detail_inner h1 span {
  display: inline-block;
}
.blog_detail_inner span {
  display: inline;
  font-size: 15px !important;
  color: rgb(90, 90, 90);
  line-height: 22px;

}
.blog_detail_inner img.blog_detail_inner-img{
  width:auto;
  display:block;
  height:650px;
  text-align:center;
  margin:30px auto;
}
.blog_detail_inner div {  
  font-size: 15px;
}
.blog_detail_inner img {
  width: 100%;
  height: 550px;
  margin: 30px 0;
  object-fit: cover;
}
.blog_detail_inner ul, .blog_detail_inner ol {
  margin: 20px 0;
  font-size: 15px;
}
.blog_detail_inner p, .blog_detail_inner font {
  font-size: 15px !important;
  line-height: 26px;
  color: #5a5a5a;
  font-family:'Poppins',sans-serif !important
}

.blog_detail_inner b {
  font-size: 18px;
}
.detail_delivery span {
  font-size: 16px;
  color: #696969;
}

.inmecar.active {
  color: #999;
  text-shadow: 0 1px 1px #999, 0 -1px 1px #999, 1px 0 1px #999, -1px 0 1px #999;
}

.storie_contant.bottom_tx i {
  transform: rotate(-45deg);
}

.makeour_btn {
  text-align: right;
  margin-top: 40px;
}

.makeour_btn button {
  margin-left: 40px;
  font-size: 14px;
  border-radius: 50px;
  min-width: 100px;
}

.progressbar .CircularProgressbar .CircularProgressbar-path {
  stroke-linecap: square;
}

.progressbar .col-md-10 {
  padding: 0;
}

.progressbar .col-md-2 {
  text-align: right;
  padding: 0;
}

.specification_colum_main {
  padding: 0 !important;
  margin-top: 20px !important;
}

.logo_img_center {
  text-align: center;
}

.heading_logo h4 {
  font-weight: 300;
  color: #929292;
  margin-bottom: 10px;
  font-size: 14px;
}

.logo_slider_home {
  padding: 50px 0 20px 0px;
}

.banner_contant h1 {
    margin-top: 0;
    font-size: 28px;
    color: #363636;
    font-weight: 500;
    line-height: 46px;
}

.banner_contant h1 span {
  display: block;
  color: #590EC6;
  font-size: 42px;
}

.banner_contant p {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #5B5B5B;
  margin-top: 30px;
} 

.banner_contant p span {
  font-weight: 600;
  text-transform: uppercase;
  color: #000;
} 

.product_cont h4,
.product_cont h4 font,
.zkitProductbox .cat_title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product_align_slider .coming_soon {
  margin-top: 40px;
}

.product_align_slider .coming_soon  span {
  display: inline-block;
  width: 100%;
  padding-bottom: 10px;
  font-size: 13px;
  color: #7A38DA;
}

.product_align_slider .coming_soon li {
  list-style: none;
  display: inline-block;
  width: 100%;
  margin-bottom: 8px;
}

.product_align_slider .coming_soon li a {
  font-size: 13px;
  font-weight: 400;
  color: #2F2F2F;
}

.product_align_slider .coming_soon ul {
  padding-bottom: 0;
  padding-left: 80px;
}

.counter_heading p a {
  color: #7A38DA;
}

.why_koytola .support a img {
  width: 15px;
  position: relative;
  top: -3px;
}

.interested .row.align-items-center {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.company_profile_head {
  display: flex;
  align-items: center;
}

.company_profile_head .company_logo {
  height: 50px;
  width: 50px;
  border-radius: 100%;
  overflow: hidden;
}

.company_profile_head .company_logo img {
  height: 50px;
  width: 50px;
  border-radius: 100%;
  object-fit: cover;
}

.company_profile_head h2 {
  margin: 0 20px;
  font-size: 34px;
  font-weight: 600;
  color: #1D1D1D;
}

.company_profile_head .share_icon img {
  width: 14px;
  margin-left: 10px;
}

.latest_plat_box .offer_over {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.latest_plat_box .offer_over span {
  margin-top: 15px;
  font-weight: 400;
  font-size: 14px;
  color: #7A38DA;
}

.profile_heading_maltipal.merhaba {
  display: flex;
}

.profile_heading_maltipal.merhaba span {
  font-size: 14px;
  color: #040404;
  margin-left: 20px;
  font-weight: 300;
}

.profile_heading_maltipal.make_ober h3 {
  margin: 0;
  color: #959595;
  font-size: 40px;
  font-weight: 600;
  margin-left: 140px;
}

.responsibilities .contant_box h4 {
  color: #000;
  font-size: 18px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 20px;
}

.responsibilities .contant_box h4:first-letter {
  text-transform: capitalize;
}

.responsibilities .contant_box p:first-letter {
  text-transform: capitalize;
}

.profile_tag_n .tag_detail span:first-letter {
  text-transform: capitalize;
}

.makeover_thanks {
  text-align: center;
}

.makeover_thanks h2 {
  font-size: 42px;
  color: #7A38DA;
  margin-top: 0px;
  margin-bottom: 40px;
  font-weight: 600;
}

.makeover_thanks h4 {
  font-size: 30px;
  color: #7a38da;
  font-weight: 600;
  margin: 0;
}

.loading_seccestion {
  text-align: center;
}

.loading_seccestion .MuiCircularProgress-colorPrimary {
  color: #590ec6;
  width: 30px !important;
  height: 30px !important;
}

.product_image_outer {
  overflow: initial !important;
  z-index:9;
}

.product_image_outer img {
  width: 100% !important;
  height: 450px !important;
  object-fit: cover;
}

.perimeter {
  max-width: 1024px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.imageperimeter {
  flex: 0 0 100%;
}


.googletransleterp {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 0;
  margin-right: 60px;
  position: relative;
  top: 10px;
  list-style: none;
}

.googletransleterp span {
  font-size: 13px;
}

.googletransleterp .slesh {
  margin: 0px 5px;
}

.product_not_found {
  text-align: center;
  font-size: 16px;
}

.welcome_koytola .welcome_box {
  text-align: left;
  width: 50%;
  margin: auto;
}

#dedicated-slider .swiper-container {
  height: 250px;
  overflow: hidden;
}

.wrapper{
  height: 300px;
  width: 300px;
}
.owl-carousel-vertical{
  transform: rotate3d(0, 0, 1, 90deg);
}
.owl-carousel-vertical .item{
  transform: rotate3d(0, 0, 1, -90deg);
}


.swiper-container {
  width: 100%;
  height: 200px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


@keyframes fadeInUp {
  from {
      transform: translate3d(0,40px,0)
  }

  to {
      transform: translate3d(0,0,0);
      opacity: 1
  }
}

@-webkit-keyframes swiper-slide-active {
  from {
      transform: translate3d(0,40px,0)
  }

  to {
      transform: translate3d(0,0,0);
      opacity: 1
  }
}

.swiper-slide {
  animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both
}

.swiper-slide-active {
  opacity: 0
}

.swiper-slide-active {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}

.guarantee_outer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.styles_typicalWrapper__1_Uvh::after {
  display:none;
}

.multi_textbtn {
  font-size: 22px;
  font-weight: 600;
  border: solid 1px #000;
  padding: 10px 22px;
  border-radius:25px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 200px;
  color:#000 !important;
}

.multi_textbtn span {
  font-size: 16px;
  margin-left: 32px;
  font-weight: 500;
  position: relative;
}

.multi_textbtn span:before {
  content: '';
  background-color: #7A38DA;
  width: 15px;
  height: 2px;
  position: absolute;
  top: 16px;
  left: -25px;
  transform: rotate(-45deg);
}

.multi_textbtn span:after {
  content: '';
  background-color: #7A38DA;
  width: 15px;
  height: 2px;
  position: absolute;
  bottom: 16px;
  left: -25px;
  transform: rotate( 45deg);
}

.top_products.sec_space {
  padding: 100px 0;
}

.success_story.sec_space {
  padding-top: 100px;
} 

.why_koytola .row.align-items-center {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-top: 80px;
}

.inner_headi h5 {
  text-align: right;
  font-size: 20px;
  color: #000;
  margin: 0;
  margin-bottom: 40px;
}

.inner_headi h2 {
  color: #7A38DA;
  font-size: 36px;
  line-height: 52px;
  font-weight: 600;
  text-transform: uppercase;
}                             

.dream_box {
  margin-bottom: 30px;
}

.dream_box.m-0 {
  margin: 0;
}

.dream_box h5 {
  font-size: 19px;
  line-height: 25px;
  color: #000;
  font-weight: 500;
}

.dream_box h5 span {
  font-weight: 700;
}

.micro_trade {
  background: rgba(248,243,255,0.8);
  padding: 60px 0;
  text-align: center;
  margin: 100px 0;
  position: relative;
  margin-bottom: 200px;
}

.micro_trade_contnt h3 {
  margin-top: 0;
  font-weight: 400;
  font-size: 34px;
  color: #000;
  margin-bottom: 30px;
}

.micro_trade_contnt h3 span {
  font-weight: 600;
}

.micro_trade_contnt p {
  font-size: 16px;
  width: 40%;
  margin: 0 auto;
  line-height: 26px;
  font-weight: 500;
  color: #030303;
}

.ins_heading h5 {
  font-weight: 400;
  font-size: 18px;
  color: #2E2E2E;
  margin: 0;
}

.b_advantege {
  padding-bottom: 80px;
  text-align: center;
  padding-top: 20px;
}

.b_advantege_cont h5 {
  margin-top: 0;
  font-size: 20px;
  color: #030303;
}

.b_advantege_cont p {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: #030303;
}

.vartical_inspaction {
  padding-bottom: 60px;
  padding-top: 60px;
}

.logo_img_center img {
  width: 110px;
  object-fit: contain;
  filter: grayscale(100%);
}

.logo_img_center {
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile.float-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile.float-right .become_supplier {
  color: #fff;
  background: linear-gradient(271.02deg, #B785FF 5.34%, rgba(133, 185, 255, 0.528412) 51.53%, rgba(100, 194, 172, 0.154116) 88.2%, rgba(255, 255, 255, 0) 103.3%), #7A38DA;
  font-size: 14px;
  padding: 8px 12px;
  text-align: center;
    border-radius: 10px;
}

.word_header_p {
  word-spacing: 20px;
  margin-left: 15px;
  margin-top: 5px;
  font-size: 12px;
  margin-bottom: 0px;
}

.ko_banner_btn {
  background: linear-gradient(271.02deg, #B785FF 5.34%, rgba(133, 185, 255, 0.528412) 51.53%, rgba(100, 194, 172, 0.154116) 88.2%, rgba(255, 255, 255, 0) 103.3%), #7A38DA;
  border-radius: 10px;
  border: 0;
  font-weight: 500;
  font-size: 14px;
  color: #FFFFFF;
  margin-right: 8px;
  padding: 6px 10px;
  line-height: 1;
  cursor: pointer;
}

.micro_trade:before {
  content: '';
  position: absolute;
  left: 50%;
  top: 45%;
  width: 0;
  height: 0;
  border-left: 200px solid transparent;
  border-right: 200px solid transparent;
  border-bottom: 380px solid rgb(240 208 143 / 16%);
  clear: both;
  -webkit-transform: translate(-50%,0);
  -ms-transform: translate(-50%,0);
  transform: translate(-50%,-50%);
}
.images_hadoweffect{
  border-radius:6px;
  box-shadow:0 2px 7px rgb(145 145 145 / 35%);
  text-align:center;
  overflow:hidden;
}

.product_list_box {
    position: relative;
    min-height: 530px;
    cursor: pointer;
    margin: 0 10px;
}

.product_list_box .top_bar {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
}

.product_list_box .top_bar img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: solid 1px #ddd;
    margin-right: 10px;
    object-fit: cover;
    background:#ebebeb;
}

.product_list_box .top_bar .location h4 {
    margin: 0;
    font-size: 16px;
    margin-bottom: 5px;
}

.product_list_box .top_bar .location svg {
    font-size: 15px;
    color: #9F9D9D;
    margin-right: 5px;
}

.product_list_box .top_bar .location  .loc {
  display: flex;
  align-items: center;
}

.product_list_box .top_bar .location span {
  font-size: 12px;
  color: #828181;
  letter-spacing: 0.5px;
}

.product_list_box .product_image img {
  width: 100%;
  height: 220px;
  object-fit: cover;
}

.product_cont h4 {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #2C2C2C;
}

.product_cont .price {
  display: flex;
  align-items: flex-end;
}

.product_cont .price h3 {
  margin: 0;
  margin-top: 8px;
  font-weight: 600;
  font-size: 18px;
  line-height: 1;
  color: #454343;
  margin-right: 8px;
}

.product_cont .price span {
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #909090;
}

.company_detail_hover {
  border-top: solid 1px #f2f2f2;
  margin-top: 20px;
  padding-top: 10px;
  display: none;
}

.company_detail_hover h5 {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;
  color: #B7B7B7;
  margin-bottom: 12px;
}

.company_detail_hover .supplier_infoaria2 {
  width: 65%;
  display: flex;
  align-items: center;
}

.company_detail_hover .zkitcard_foright {
  width: 35%;
}

.company_detail_hover .flex-row {
  display: flex;
  align-items: center;
}

.company_detail_hover .supplier_infoaria2 img {
  width: 40px !important;
  height: 40px;
  object-position: top;
  border-radius: 100%;
  object-fit: cover;
  background:#ebebeb;
}

.company_detail_hover .supplier_infoaria2 h5 {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.03em;
  color: #434141;
}

.company_detail_hover .supplier_infoaria2 .supplier_info {
  margin-left: 8px;
}

.company_detail_hover .supplier_infoaria2 .supplier_info span {
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.05em;
  color: #9B9B9B;
}

.company_detail_hover .zkitcard_foright .btn-follow {
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  color: #000000;
  border: solid 1px #000;
  border-radius: 15px;
  padding: 2px 9px;
}

.company_detail_hover .zkitcard_foright .btn-viewstore {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #888888;
}

.product_cont {
  transition: 0.5s;
  padding: 10px;
  padding-bottom: 20px;
  background-color: #fff;
  border-radius: 10px;
  border: solid 1px transparent;
  margin-top:10px;
}

.product_list_box:hover .product_cont {
  transform: translateX(0px) translateY(-100px);
  border-color: #ddd;
}

.product_list_box:hover .company_detail_hover {
  display: block;
}

.home_products {
  padding-top: 60px;
}

.trky_koytola {
  margin: 0;
  text-align: right;
  font-size: 16px;
  line-height: 27px;
  color: #000000;
  font-weight: normal;
  position: relative;
  top: -45px;
}

.hom_viw_products {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.03em;
  color: #434141;
  margin: 0;
  border-bottom: solid 1px #ddd;
  padding-bottom: 30px;
}

.koytola_family .top_heading {
  width: 90%;
  margin: auto;
  margin-bottom: 40px;
}

.koytola_family .top_heading h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 42px;
  color: #FFFFFF;
  margin: 0;
}

.selling_fast h5 {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #FFFFFF;
  margin-bottom: 20px;
  margin-top: 0;
}

.selling_fast p {
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #FFFFFF;
  margin-bottom: 15px;
}

.selling_fast.border_lr {
  position: relative;
}

.selling_fast.border_lr:before,
.selling_fast.border_lr:after {
  content: "";
  width: 1px;
  height: 100px;
  position: absolute;
  background-color: #fff;
  left: -100px;
  top: 50px;
  transform: translate(0, 0%);
}

.selling_fast.border_lr:after {
  left: auto;
  right: 70px;
}

.koytola_family .account a {
  color: #fff;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}

.koytola_family .account a .rotted {
  width: 17px;
  margin-left: 8px;
}

.row:before {
  display: flex;
}

.Shop_category_contant {
  border-top: solid 1px #ddd;
  padding-top: 10px;
}

.gallery_slider .owl-stage-outer .owl-item {
  text-align: center;
}

.work_proses {
  height: 240px;
  width: 80%;
  object-fit: cover;
  object-position: center;
  margin: auto;
}

.you_might_like {
  padding: 100px 0;
}

.you_might_like .you_tag {
  display: inline-block;
  width: 100%;
}

.you_might_like .you_tag a {
  border-radius: 50px;
  text-transform: capitalize;
  font-size: 14px;
  color: #000;
  font-weight: 600;
  padding: 5px 20px;
  border: solid 1px #ddd;
  width: 110px;
  text-align: center;
  float: right;
}

.zGQrp {
  display: initial;
}

.products_sub_image .subImage {
  margin-right: 20px;
  min-width: 140px;
  cursor: pointer;
}

.specification_colum {
  border-bottom: solid 1px #ddd;
  margin-bottom: 30px !important;
  padding-bottom: 8px;
}

.detail_delivery .col-md-6 {
  margin-top: 40px;
}

.resource_btns {
  margin-top: 40px;
}
.massage_inner .massage_box::placeholder {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.045em;
  color: #000000;
  width: 60%;
}

.cths {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cths h5 {
  margin: 0;
  padding-right: 120px;
   font-weight: 400;
}

.live_port.sec_space {
  display: none;
}

.strongly.sec_space {
  display: none;
}
.export_list_top{
  max-height: 70px;
  overflow: auto;
  border: solid 1px;
  border-radius: 5px;
  padding: 10px;
  float: left;
}



.zkitProductbox .zkitcard_footer h5:first-letter {
  text-transform: uppercase;
}

.supplier_info span {
  text-transform: capitalize;
}

.kargo_hespla {
  background: #7324d4;
  box-sizing: border-box;
  border-radius: 35px;
  padding: 50px;
  padding-right: 30%;
  position: relative;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 15%);
  border: 3px solid #FFFFFF;
}

.kargo_hespla:after {
  background-color: #fff;
    width: 350px;
    height: 200%;
    content: "";
    position: absolute;
    right: -314px;
    bottom: -144px;
    transform: rotate(24deg);
    overflow: hidden;
}

.kargo_hespla h1 {
  font-weight: 600;
  font-size: 35px;
  color: #FFFFFF;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 60px;
}

.kargo_input fieldset {
  border: 0px !important;
}

.kargo_input input {
  color: #333 !important;
  background-color: #fff;
  font-size: 16px !important;
  padding: 15px !important;
  border-radius: 5px;
}

.kargo_input input::placeholder {
  color: #333 !important;
  opacity: 1;
}

.kargo_input .MuiOutlinedInput-adornedEnd {
  padding: 0px !important;
}

.kargo_input p {
  color: #3fff00;
  font-size: 14px;
  margin-top: 10px;
}

.kargo_miktr {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px 0;
}

.kargo_miktr h5 {
  margin: 0;
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  margin-right: 40px;
}

.kargo_input.miktr input {
  background-color: transparent;
  text-align: center;
  font-size: 40px !important;
  line-height: 60px;
  color: #5B5B5B !important;
  font-weight: 600;
  width: 100%;
}

.kargo_input.miktr .quantity {
  background-color: #fff;
  text-align: center;
  border-radius: 5px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  position: relative;
}

.kargo_input.miktr .quantity span {
  position: absolute;
  right: 8px;
  font-size: 20px;
  line-height: 1;
  color: #5B5B5B;
  bottom: 8px;
  font-weight: 500;
}

.lowest_btn {
  text-align: center;
  display: inline-block;
  width: 100%;
}

.lowest_btn button {
  font-size: 16px;
  text-align: center;
  color: #676767;
  font-weight: 500;
  padding: 10px 40px;
  line-height: 18px;
  margin-bottom: 15px;
}

.lowest_btn button span {
  display: block;
  font-size: 14px;
}

.lowest_btn ul {
  padding: 0;
  text-align: left;
  width: 32%;
  margin: 0px auto;
}

.lowest_btn ul li {
  color: #fff;
  list-style: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  line-height: 24px;
}

.products_maching .row {
  background: rgba(122, 56, 218, 0.05);
  border-radius: 15px;
  padding: 20px;
}

.products_maching {
  margin-top: 50px;
}

.products_maching h2 {
  margin-top: 0;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 35px;
  line-height: 48px;
  color: #000000;
}

.products_maching h2 span {
  display: block;
  font-size: 15px;
  line-height: 1;
  font-weight: 400;
}

.products_maching p {
  font-size: 12px;
  line-height: 18px;
  padding-left: 80px;
  color: #000;
  padding-bottom: 40px;
  padding-top: 25px;
}

.hesapla_heading h2 {
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  color: #676767;
  margin: 0;
}

.hesapla_heading h2 span {
  font-weight: 400;
  display: block;
}

.hesapla_top_bar {
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 25px;
}

.hesapla_top_bar .text {
  width: 20%;
}

.hesapla_top_bar .text h4 {
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  margin: 0;
}

.hesapla_top_bar .text h4 span {
  display: block;
  font-weight: 400;
}

.hesapla_top_bar .h_buttons {
  display: flex;
  align-items: flex-start;
}

.hesapla_top_bar .h_buttons button {
  background: rgba(122, 56, 218, 0.21);
  border-radius: 15px;
  border: 0;
  min-width: 88px;
  min-height: 25px;
  font-size: 14px;
  line-height: 21px;
  color: #676767;
}

.hesapla_top_bar .h_buttons span {
  margin: 5px 9px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #676767;
}

.hesapla_top_bar .h_buttons p {
  color: red;
  font-size: 12px;
  margin-top: 3px;
  margin-bottom: 0;
}

.hed_ecom {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
}

.economy_left_box .box_inner {
  background: rgba(196, 196, 196, 0.25);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  margin-top: 20px;
}

.economy_left_box .box_inner p {
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #000000;
  margin: 0;
  position: relative;
  top: 5px;
}

.economy_left_box .box_inner h3 {
  margin: 0px;
  font-weight: 600;
  font-size: 30px;
  line-height: 35px;
  color: #000;
}

.hed_ecom.exps {
  margin-top: 40px;
}

.economy_left_box .box_inner p span {
  position: relative;
  bottom: 10px;
  margin-right: 5px;
  color: #7A38DA;
}

.box_inner.yellow {
  background: #fff50366;
}

.economy_right_box .img_box_inner {
    display: flex;
    align-items: center;
    justify-content: start;
    padding-left: 10%;
    margin-top: 20px;
}

.economy_right_box .img_box_inner img {
    width: 20%;
    height: 35px;
    object-fit: contain;
}

.economy_right_box .img_box_inner p {
    width: 40%;
    padding-left: 20px;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
}

.economy_right_box .img_box_inner button {
    background: rgba(122, 56, 218, 0.05);
    border-radius: 5px;
    border: 0px;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
    width: 110px;
    text-align: left;
    padding: 10px 20px;
    height: 67px;
}

.economy_right_box .img_box_inner button span {
    display: block;
    font-weight: 400;
}

.bulk_buy h2 {
  font-size: 16px;
  margin-bottom: 8px;
  line-height: 1.1;
  font-weight: 500;
}

.bulk_buy  p {
  padding: 0;
}

.bulk_buy .text_right {
  text-align: right;
}

.bulk_buy h1 {
  margin-top: 10px;
  font-size: 24px;
  color: #000;
}

.contant_oi {
  padding-top: 20px;
  padding-left: 30px;
}

.contry_selct .MuiOutlinedInput-notchedOutline {
  border: 0px !important;
}

.contry_selct .MuiOutlinedInput-adornedEnd {
  background-color: #e3d5f7;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border-radius: 70px;
}

.contry_selct input {
  color: #000;
  opacity: 1;
  font-size: 14px;
  padding: 5px !important;
  font-weight: 500; color: #000;
  opacity: 1;
  font-size: 12px;
  padding: 6px !important;
}

.contry_selct 
.MuiAutocomplete-clearIndicatorDirty {
  min-width: auto !important;
  background-color: #7324d4 !important;
  width: 20px;
  height: 20px;
  color: #fff !important;
  margin-top: 4px;
  min-height: auto !important;
}

.contry_selct .MuiAutocomplete-clearIndicatorDirty svg {
  color: #fff;
}

.kilogram_int {
  background: rgba(122,56,218,0.21);
  border-radius: 15px;
  border: 0;
  min-width: 120px;
  min-height: 25px;
  font-size: 14px;
  line-height: 21px;
  color: #676767;
  text-align: center;
  margin-right: 10px;
  outline: 0px;
}

.hesapla_top_bar button.change_btn {
  background: rgba(182, 182, 182, 0.15);
  box-shadow: 0px 4px 4px rgb(0 0 0 / 12%);
  margin-top: -12px;
}

.countryouter {
  width: 180px;
}

.krgo_inner_cnt h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  padding-left: 15px;
  margin-bottom: 20px;
}

.krgo_inner_cnt p {
  padding-left: 30px;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  color: #000000;
}

.krgo_inner_cnt.top_space {
  padding-top: 40px;
}

.saprat_sec {
  padding-top: 20px;
}

.saprat_sec .kargo_hespla {
  padding: 40px;
  padding-right: 20%;
}

.saprat_sec .kargo_hespla h1 {
  font-size: 24px;
  margin-bottom: 40px;
}
.saprat_sec .kargo_input input {
  padding: 10px 15px !important
}

.saprat_sec .kargo_miktr {
  margin: 40px 0;
}

.saprat_sec .kargo_input.miktr .quantity {
   height: 80px;
   width: 110px;
}

.saprat_sec .kargo_miktr h5 {
  font-size: 16px;
  line-height: 24px;
}

.saprat_sec .kargo_input.miktr .quantity span {
  font-size: 16px;
}

.saprat_sec .lowest_btn button {
  padding: 8px 30px;
  line-height: 18px;
}

.saprat_sec .kargo_hespla_outer {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 20px;
}

.news_koytola .section_heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
}

.blog_detail_inner .blog_detail_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.blog_detail_inner .blog_detail_title p,
.news_koytola .section_heading span {
  font-size: 14px;
  color: #7A38DA;
  font-weight: 500;
  cursor: pointer;
}

.mackeordererror span {
  color: red;
  border: solid 1px red;
  margin-right: 10px;
  font-size: 12px;
  padding: 5px 12px;
  line-height: 1;
  border-radius: 30px;
  display: inline-block;
  margin-bottom: 10px;
}

.mackeordererror p {
  margin: 0;
}

.blog_list_box {
  margin-bottom: 30px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 4px 5px #0000001a;
}

.blog_list_box img {
  width: 100%;
  height: 180px;
  object-fit: cover;
  cursor: pointer;
}

.blog_list_box h2 {
  cursor: pointer;
  color: #000;
  font-size: 16px;
  line-height: 1.2;
  margin-top: 0;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  white-space: normal;
  height: 40px;
  overflow: hidden;
  margin-bottom: 6px;
}

.blog_list_box  p {
  color: #000;
  font-size: 12px;
  line-height: 1.2;
  margin-top: 0;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  white-space: normal;
  height: 58px;
  overflow: hidden;
  margin-bottom: 6px;
}

.blog_list_box .cont_list {
  padding: 15px;
}

.blog_list_box .cont_list span {
  font-size: 12px;
  color: #7A38DA;
  font-weight: 500;
  cursor: pointer;
}

.mySwiper.topline {
  height: 40px;
}

.mySwiper.topline .swiper-slide {
  height: 40px !important;
  background-color: transparent;
}

.top_bar_header {
  background: rgba(122, 56, 218, 0.11);
}

.top_bar_header p {
  margin-bottom: 0px;
  font-size: 14px;
  color: #000;
}

.top_bar_header p span {
  color: #7A38DA;
  cursor: pointer;
}

.conetct_form {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.conetct_form p {
  width: 50%;
}

.conetct_form input {
  width: 100%;
  background: #7324d430;
  border-radius: 10px;
  border: 0;
  outline: 0px;
  padding: 14px;
  margin-bottom: 5px;
  font-size: 14px;
  color: #000;
}

.conetct_form .input_box {
  width: 50%;
  margin-bottom: 10px;
}

.conetct_form .input_box span {
  font-size: 12px;
  color: red;
}

.conmplet_outer {
  width: 50%;
  margin: auto;
}

.conmplet_outer h1 {
  margin-top: 0;
  font-weight: 500;
  font-size: 30px;
  line-height: 140.62%;
  letter-spacing: 0.02em;
  color: #000000;
  width: 60%;
  margin-bottom: 20px;
}

.conmplet_outer p {
  font-weight: normal;
  font-size: 15px;
  line-height: 140.62%;
  margin-bottom: 0;
  letter-spacing: 0.02em;
  color: #000000;
}

.conmplet_outer .conetct_form {
  margin-top: 40px;
}

.conmplet_outer .conetct_form p {
  font-size: 16px;
  margin-bottom: 20px;
}

.conmplet_outer  .right_btn {
  text-align: right;
  width: 100%;
  margin-top: 20px;
}

.conmplet_outer .right_btn button {
  font-weight: 600;
  font-size: 15px;
  line-height: 140.62%;
  letter-spacing: 0.02em;
  color: #7A38DA;
  border: 0;
  background-color: transparent;
}

.creating_sour {
  font-weight: normal;
  font-size: 15px;
  line-height: 140.62%;
  margin-bottom: 0;
  letter-spacing: 0.02em;
  color: #000000;
  padding-left: 60px;
}

.site_map_contant {
  padding: 50px 0;
}

.site_map_contant h1 {
  font-weight: 500;
  font-size: 40px;
  line-height: 140%;
  color: #000000;
}

.site_map_contant h3 {
  cursor: pointer;
  font-size: 18px;
  margin: 15px 0;
  color: #000;
}
.video_slider_heading {
  margin-bottom: 80px;
}

.video_slider_main {
  padding: 60px 0;
}

.video_slider_heading h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 34px;
  color: #000000;
  margin-bottom: 0;
}

.video_slider_heading p {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  margin-bottom: 0;
}

.video_circle {
  border: 3px solid #7500CC;
  border-radius: 100%;
  width: 160px;
  height: 160px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video_circle h2 {
  margin: 0;
  font-size: 31px;
  line-height: 29px;
  color: #7500CC;
  font-weight: 700;
}

.video_circle h2 span {
  font-size: 20px;
  font-weight: 400;
  position: relative;
  bottom: 4px;
}

.slider_center_btn {
  text-align: center;
  margin-top: 30px;
  position: relative;
}

.slider_center_btn img {
  width: 22px;
}

.slider_center_btn span {
  display: block;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
/* identical to box height */
  color: #7500CC;
  margin-top: 5px;
}

.slider_center_btn:before,
.slider_center_btn:after {
  content: "";
  position: absolute;
  height: 1px;
  width: 30%;
  background-color: #7500CC;
  left: 10%;
  top: 50%;
  transform: translate(0px, -50%);
}

.slider_center_btn:after {
  left: auto;
  right: 10%;
}

.video_title span {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #333232;
  padding: 5px 0;
}

.logo_img_center .larg.larg {
  width: 160px;
}

.video_title span svg {
  margin-right: 10px;
  color: #7500CC;
  transform: rotate(30deg);
}

.sldier_side_btn button {
  background: rgba(122, 56, 218, 0.1);
  border-radius: 15px;
  border: 0;
  margin: 10px;
  padding: 5px 5px;
  min-width: 100%;
  letter-spacing: 0.02em;
  color: #000000;
  font-size: 12px;
}

.slider_side_contant h3 {
  margin: 0;
  font-weight: 500;
  font-size: 25px;
  line-height: 140.62%;
  letter-spacing: 0.02em;
  color: #000000;
}

.slider_side_contant h3 svg {
  color: #7A38DA;
  font-size: 40px;
  position: absolute;
  cursor: pointer;
}

.video_slider_main .align_center_row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.video_circle_img {
  text-align: center;
  margin-top: 30px;
}

.video_circle_img img {
  width: 60%;
}

.input_box.quantity_sap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.input_box.quantity_sap input {
  width: 65%;
  margin-right: 5%;
}

.input_box.quantity_sap select {
  width: 30%;
  background: #7324d430;
  border-radius: 10px;
  border: 0;
  outline: 0px;
  padding: 13px 5px;
  margin-bottom: 5px;
  font-size: 14px;
  color: #000;
}

.input_box.quantity_sap .error_f {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.slider_zoom {
  background-color: #fff;
  padding: 15px;
  box-shadow: 0px 0px 5px #00000047;
  padding-bottom: 5px;
  position: relative;
  bottom: 30px;
}

.slider_zoom iframe {
  height: 175px;
}

.video_slider_inner .pading {
  padding: 0px 5px;
}

.we_recievid {
  padding: 60px 0;
  text-align: center;
}

.we_recievid h1 {
  width: 100%;
  font-size: 28px;
}

.we_recievid h2 {
  letter-spacing: 0.02em;
  color: #000000;
  margin-bottom: 34px;
  font-size: 26px;
}

.we_recievid  button {
  background: rgba(122, 56, 218, 0.79);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  border: 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 140.62%;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  padding: 4px 12px;
}

.currencyStrip_inner .currency_text {
  width: 100% !important;
}



.roster_tag_company_profile img {
  width: 35px;
  margin-left: 50px;
}

.roster_tag_company_profile  span {
  margin-left: 12px;
  font-size: 14px;
  color: #333;
}

.create_query h3 b {
  font-weight: 600;
  color: #7324d4;
}

.accordian_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #af84fd;
  margin-bottom: 15px;
  padding: 20px;
  border-radius: 10px;
}

.faq_contant {
  width: 60%;
  padding: 50px 0;
}

.accordian_header h5 {
  margin: 0px;
  color: #fff;
  font-size: 16px;
}

.accordian_header button {
  padding: 0;
  color: #fff;
}

.accordian_header button svg {
  font-size: 20px;
}

.accordian_contant  .MuiCollapse-wrapperInner {
  font-size: 14px;
  color: #000;
  padding: 20px 15px;
  margin-bottom: 20px;
  border: solid 1px #ddd;
  border-radius: 10px;
}

.sustainability span {
  font-size: 16px;
  color: #7324d4;
  cursor: pointer;
  font-weight: 500;
}

.sustainability i {
  margin-top: 15px;
  display: block;
  font-size: 16px;
}




























/**=====================
    RESPONSIVE CSSS
 ======================**/
@media screen and (min-width: 1200px) {
  .MuiGrid-container {
    width: 1170px !important;
    margin: 0 auto !important;
  }

  .live_port_table {
    height: 65vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .typr_flex {
    display: flex;
  }
}

@media (max-width: 1199.98px) {
	.products_search input {
		width: 160px; 
	}
	.barriers_heading h2 {
		width: 65%;
	}
	.news_tabs .nav-tabs .nav-link {
		padding-right: 25px !important;
	}
	.footer_contant {
	    max-width: 45%;
	}

	.live_port .col-md-8 {
	    max-width: 100%;
	    flex: 0 0 100%;
	}

	.live_port .col-md-4 {
	    max-width: 100%;
	    flex: 0 0 100%;
	    margin-top: 50px;
	}

	.track_box:before {
		top: 62px;
	}

	.sustainability h3 {
	    font-size: 28px;
	}

  .selling.text-writer {
    width: 80%;
  }

  .owl-dots span {
    width: 50px !important;
  }

  .welcome_koytola .welcome_box {
    width: 90%;
  }

  .banner_contant h2 {
    width: 100%;
  }

  .offer_box_heading h2 {
    font-size: 22px;
  }

  .offer_box_heading.secound h2 {
    font-size: 18px;
    margin-top: 20px;
    line-height: 28px;
  }

  .products_search .MuiAutocomplete-hasClearIcon {
    min-width: 200px;
  }

  #marker-example {
    height: 400px !important;
  }

  .footer_colum.information {
    padding-top: 40px;
  }  

  .company_top_space .col-md-5 {
    width: 50%;
  }

  .company_top_space .col-md-4 {
      width: 50%;
  }

  .company_top_space .col-md-3 {
      display: none;
  }

  .latest_plat_box h2 {
    font-size: 40px;
  }

  .products_search {
    margin-left: 10px;
  }

  .top_header .col-md-3 {
    padding-left: 0px;
  }

  .profile.float-right .become_supplier {
    padding: 8px 6px;
  }

  .banner_contant {
    padding-left: 0px;
  }

  .home_products .col-md-3 {
    width: 33.33%;
  }
  .you_might_like {
    padding: 60px 0;
}
  .product_list_box {
    min-height: 510px;
  }

  .selling.more h3 {
    line-height: 44px;
  }

  .dream_main .col-md-5 {
    width: 50%;
  }

  .dream_main .col-md-2 {
      display: none;
  }

  .dream_box h5 {
    font-size: 16px;
    
  }
  .banner_contant_type .products_search {
    
    width: 100%;
}
.banner_contant_type .MuiAutocomplete-hasClearIcon {
  width: 100%;
}
  .micro_trade_contnt h3 {
    font-size: 30px;
  }

  .selling.more span {
    width: 100%;
    max-width:100%
  }

  .barriers_contant {
    width: 50%;
  }

  .barriers_heading {
    margin-right: 40px;
    width: 50%;
  }

  .strongly_heading h4 {
    font-size: 20px;
  }

  .selling_fast.border_lr:before, .selling_fast.border_lr:after {
    left: -70px;
  }

  .selling_fast.border_lr:after {
    right: 45px;
    left: auto;
  }

  .sustainability {
    padding: 80px 0;
  }

  .interested {
    padding: 60px 0;
  }

  .dropdown-content.category {
    z-index: 9999;
  }

  .dropdown-content.category li {
    width: calc(14% - 20px);
  }

//   .zkitProductbox {
//     min-height: 580px;
// }

.profile.float-right .dropdown-menu {
  width: 85px;
}
.welcome_koytola {
  margin-top: 0;
}
.daily_offer .section_heading{
  padding:0
}
.company_top_space{
  margin-top:0 !important;
}
.profile_heading_maltipal h2, .profile_gallery_heading h2{
  font-size:26px;
}
.industry_outer {
  padding-bottom: 30px;
}
} 

@media (min-width: 992px) and (max-width: 1199px) {
  .welcome_box span {    
    font-size: 18px;   
  }
  .welcome_box h4 {    
    font-size: 28px;    
  }
  .welcome_box a {
    font-size: 16px;    
  }
  .top_products.sec_space {
    padding: 30px 0;
  }
  .products_amount span {
    font-size: 16px;
  }
  .products_amount h1 {
    font-size: 60px;
  }
  .product_align_slider .coming_soon {
    margin-top: 25px;
}
.product_align_slider .coming_soon ul {
  padding-bottom: 0;
  padding-left: 0;
}
.product_align_slider .coming_soon li a {
  font-size: 15px;
  
}
.home_products,.live_port.sec_space, .logistics.sec_space,.strongly.sec_space,.sec_counter.sec_space {
  padding-top: 0;
} 
.Shop_category span {  
  width: 60%; 
}
.vartical_inspaction{
  padding-bottom:0
}
.strongly .row:first-child {
  margin-bottom: 0;
  
}
 }


@media (max-width: 991.98px) {
	.top_header .logo img {
	    margin-right: 15px;
	}
  .daily_offer.sec_space{
    padding-bottom:0
  }
  .sec_counter.sec_space{
    padding-top:0
  }
  .live_port.sec_space, .logistics.sec_space,.why_koytola{
    padding-top:0;
  }
	.dropdown .dropdown-toggle {
		padding-left: 0;
    width: 30px;
	}
  .selling.more span,.selling.more h3 {
    width: 100%;
    max-width: none;
}
.why_koytola .inner_headi h5 {
   margin-bottom: 0;
}
.why_koytola .inner_headi h2 { 
  font-size: 26px;
  line-height: 42px; 
}
.micro_trade{
  margin:30px 0;
  margin-bottom: 100px;
}
 .welcome_koytola {
    margin-top: 0px;
  }
  
  .profile.float-right .become_supplier {
    font-size: 11px;
  }
  .inline_tags_aria .MuiGrid-item {
    max-width: 50%;
    flex-basis: 50%;
  }
	.products_search:before {
		top: 10px;
	}
	.products_search span {
		padding-left: 15px;
	}
	.products_search input {
		margin-right: 15px;
	}

	.dropdown-toggle::after {
		right: -10px;
    font-size: 16px;
	}
	.home_main_banner .banner_contant {
	    padding: 0px;
	}
	.home_main_slder .owl-dots:before, .home_main_slder .owl-dots:after {
		top: 6px;
		font-size: 14px;
	}
	.home_main_slder .owl-dots button span {
    	width: 35px !important;
    }
    .selling h3 {
	    font-size: 30px;
	}
	.selling span {
		font-size: 14px;
		max-width: 58%;
	}
	.products_amount h1 {
		font-size: 50px;
	}
	.offer_box_heading h2 {
		font-size: 20px;
		width: 45%;
	}
  .news_koytola .col-md-8 {
      width: 100%;
  }
	.duble_box .col-md-6 {
	    max-width: 100%;
	    flex: 0 0 100%;
	}
	.counter_heading h3 {
	    font-size: 28px;
	    line-height: 40px;
	    margin-bottom: 25px;
	}
	.counter_box h1 {
	    font-size: 41px;
	}
	.why_koytola .heading h3 {
		font-size: 32px;
		font-weight: 400;
		line-height: 48px;
	}
	.why_koytola .support a span {
	    padding-right: 8px;
	    font-size: 14px;
	}
	.why_koytola .support a {
		display: flex;
	}
	.barriers_heading h2 {
	    width: 100%;
	}
	.success_heading h2 {
	    font-size: 34px;
	}
	.success_story .col-md-8 {
	    max-width: 100%;
	    flex: 0 0 100%;
	}
	.success_heading {
	    padding-bottom: 20px;
	}
	.strory_slider .col-md-3 {
	    flex: 0 0 50%;
	    max-width: 50%;
	}
	.dashboard_conatnt.seo {
	    margin-left: 0px !important;
	} 
	.dashboard_conatnt p {
		width: 100% !important;
		font-size: 14px;
	}
	.dashboard_conatnt h4 {
		margin-bottom: 15px;
	}
	.strongly_heading h4 {
	    line-height: 29px;
	    font-size: 17px;
	    width: 100%;
	}
	.news_contant h4 {
	    font-size: 16px;
	    font-weight: 600;
	    margin-top: 10px;
	    margin-bottom: 10px;
	    line-height: 24px;
	}
	.news_tabs .nav-tabs .nav-link {
	    padding-right: 15px !important;
	    font-size: 16px;
      margin-right: 30px;
	}
	.news_more_inner .row {
	    margin-bottom: 70px;
	}
	.footer_contant {
	    max-width: 60%;
	}
	.product_slider {
		margin-right: 0;
	}
	.sub_categiory .item .row {
	    height: 150px;
	}
	.sub_categiory .item .col-md-3 {
	    max-width: 50%;
	    flex: 0 0 50%;
	}

	.logistics_inner_col {
	    max-width: 100%;
	    flex: 0 0 100%;
	}

	.track_box:before {
	    top: 35px;
	}

	.logistics_inner_box {
		padding: 35px;
	}

	.logistics_inner_col:last-child {
	    margin-top: 40px;
	}
  .listing_img_box .MuiCardMedia-root {    
    background: #ddd;
}
  .product_contant {   
    border-radius: 0 0 10px 10px;   
    border: solid 1px #e8e6e6;
    margin-bottom: 30px;
}
	.live_port_table {
	    width: 100%;
	    overflow: scroll;
	}

	.sustainability {
	    padding: 60px 0;
	}

	.sustainability h3 {
	    font-size: 22px;
      margin-top: 0px;
	}

  .footer_copyright {
    margin-top: 50px;
  }

	.list_menu ul li a {
		font-size: 30px;
	}

	.interested {
	    padding: 0;
	}
  .vartical_inspaction{
    padding-bottom:0;
  }
	.footer_colum.information {
	    padding-top: 40px;
	}

  .dropdown-content.category li {
    width: calc(16% - 20px);
  }

  .top_header .col-lg-8.col-md-10 {
    width: 90%;
    float: left;
}

.top_header .col-lg-4.col-md-2 {
    width: 10%;
    float: left;
}

.loqTTm .column {
  width: 20%;
}

.inline_tags_aria .MuiGrid-grid-xs-2 {
  max-width: 50%;
  padding: 0 20px !important;
  flex-basis: 50%;
}

.banner_contant {
  padding-top: 0;
  padding-bottom: 0px;
}

.welcome_koytola .col-md-4 {
  width: 33.33%;
  float: left;
}

.section_heading {
  padding-bottom: 20px;
}

.products_amount {
  padding-bottom: 30px;
}

.counter_inner .col-md-4 {
  width: 33.33%;
  float: left;
}

.counter_box span {
  min-height: 42px;
}

.deals_search {
  width: 100%;
}

.quantity .col-md-6 {
  width: 50%;
  float: left;
}

.checkboxouter {
  width: 50%;
  float: left;
}

.insustnt {
  width: 50%;
  float: left;
}

.insustnt .col-md-6 {
  width: 100%;
}

.shipment.mt-5 {
  margin-top: 50px;
}

.barriers_contant {
  margin-left: 0;
  margin-top: 40px;
}

.barriers_heading h2 {
  text-align: right;
  font-size: 22px;
}

.barriers_inner .row {
  margin-bottom: 60px;
}

.marketing_support .col-md-6 {
  width: 50%;
  float: left;
}

.strory_slider .carousel-item.col-md-3 {
  width: 50%;
  float: left;
}

.strory_slider .carousel-item.active .inner {
  transform: scale(0.85);
}

.agriculture.sec_space {
  padding-top: 20px;
}

.changing p {
  margin-top: 40px;
}

.read_more_story {
  padding-top: 0;
}

.dashboard_conatnt {
  margin-bottom: 40px;
}

.dashboard_img {
  margin-bottom: 40px;
}

.dashboard .row:first-child {
  margin-bottom: 40px;
}

.strongly .row:first-child {
  margin-bottom: 40px;
}

.strongly {
  padding: 0;
}

.news_koytola .col-md-8 .col-md-6 {
  width: 50%;
  float: left;
}

.news_tabs {
  margin-top: 40px;
}

.more_img img {
  min-width: 170px;
  object-fit: cover;
}

.footer_contant h2 {
  margin-top: 0px;
}

.footer_colum .col-md-4 {
  width: 50%;
  float: left;
  margin-bottom: 50px;
}

.MuiTabs-scroller.MuiTabs-fixed .MuiTabs-indicator {
  display: none;
}

.MuiTabs-scroller.MuiTabs-fixed button {
  min-width: 140px;
}

.selected_fillter_outer {
  width: 100%;
  margin-bottom:0;
}

.fillter_outer_container {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.fillter_inner {
  padding-left: 30px;
  padding-right: 30px;
}

.MuiTabs-scroller.MuiTabs-fixed .MuiTabs-flexContainer.MuiTabs-centered {
  flex-wrap: wrap;
}

.MuiTabs-scroller.MuiTabs-fixed button {
  min-width: 100px;
  width: 100px;
  float: left;
}

.fillter_main {
  padding-top: 0px;
}

.latest_plat_main .col-md-4 {
  width: 33.33%;
  float: left;
}  

.company_top_space .border_left_right {
  border-left: 0;
  border-right: 0;
  margin-bottom: 20px;
}

.detailProdheader {
  width: 100%;
  justify-content: center;
}

.profile_tag_main .col-md-6 {
  width: 50%;
  float: left;
}

.profile_tag_main .col-md-5 {
  width: 50%;
  float: left;
}

.mission_inner_contant h3 {
  font-size: 34px;
  margin-top: 40px;
}

.product_grid_list {
  max-width: 50% !important;
  flex-basis: 50% !important;
}

.zkitProductaria_list {
  padding: 30px 15px;
}

.cont_space_remove {
  padding-left: 0;
  padding-right: 0;
}

.banner_contant {
  padding-left: 0px;
}

.banner_contant h2 {  
  font-size: 18px;
  line-height: 26px;
}
.banner_contant_type{
  margin-top:30px
}
.home_slider_main .owl-dots:before, .home_slider_main .owl-dots:after {
  top: -2px;
  font-size: 16px;
}

.owl-dots span {
  width: 42px !important; 
}

.banner_contant h1 {
  font-size: 34px;
}

.products_search .MuiAutocomplete-hasClearIcon {
  min-width: 160px;
}

.products_search {
  margin-left: 10px; 
}

.top_header .logo h5 {
  font-size: 24px;
  margin-right: 10px;
}

.loqTTm .dropdwn .dropbtn {
  margin-left: 10px;
}

.products_search span {
  margin-left: 10px;
}

.home_slider_main .row {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.hourly {
  margin-right: 40px;
}

.selling.text-writer {
  width: 100%;
  padding-bottom: 20px;
  text-align:center;
}

.selling.text-writer span {
    font-size: 30px;
}

.micro_trade_contnt h3 {
  font-size: 24px;
}

.micro_trade_contnt p {
  width: 70%;
}

.dream_box.m-0 {
  margin-bottom: 30px;
}

.barriers_heading {
  width: 50%;
}

.barriers_contant {
  width: 50%;
}

.Shop_category span {
  font-size: 20px;
  width: auto;
}

.producty_list_border {
  display: none;
}

// .zkitProductbox {
//   min-height: 600px;
// } 

.company_top_space .row {
    display: flex;
    flex-wrap: wrap;
}

.products_gallery .row {
  display: flex;
  flex-wrap: wrap;
}

.products_gallery .col-md-4 {
  margin-bottom: 30px;
  width: 50%;
}

.regions_contery {
  margin-top: 40px;
}

.query_summery_box {
    margin-top: 40px;
}

.social_activity .col-md-6 {
  width: 50%;
  float: left;
}

.social_activity .col-md-4 {
  width: 50%;
}

.social_activity .col-md-2 {
  display: none;
}

.social_activity .row {
  display: flex;
  flex-wrap: wrap;
}

.responsibilities .contant_box h4 {
  font-size: 16px;
  margin-bottom: 10px;
}

.responsibilities .contant_box p {
  font-size: 14px;
}

.company_profile_head h2 {
  font-size: 24px;
}
.industry {
  margin-bottom: 50px;
}

.product_gallery_image img {
  height: 270px;
}

.strongly_heading h4 span {
  font-size: 18px;
}

.koytola_family .account {
  display: inline-block;
  width: 100%;
}

.selling_fast.border_lr:before, .selling_fast.border_lr:after {
  left: -35px;
}

.selling_fast.border_lr:after {
  right: -5px;
  left: auto;
}

.koytola_family .top_heading {
  width: 100%;
}

.koytola_family .top_heading h3 {
  font-size: 22px;
}

.gallery_slider img, .gallery_slider video {
  width: 100%;
}

.product_dtl_main {
  margin-top: 30px;
}

.profile_mission {
  margin-top: 20px;
}
.mission_inner_contant span{
  width:100%;
}
.product_rosettes {
  margin-top: 15px;
}
.detail_main_heading{
  margin-bottom:10px;
}
.detail_delivery .col-md-6 {
  margin-top: 20px;
}
.resource_btns_main {
  padding-bottom: 0;
}
.detail_delivery {
  padding: 30px 15px;
}
.js-image-zoom__zoomed-image {
  transform: translateY(100%) !important; 
}

.home_products .col-md-3 {
  width: 50%;
  float: left;
}
.daily_offer .dealy_offter_marquee:before, 
.daily_offer .dealy_offter_marquee:after {    
    background: #ddd;
    padding: 0 10px;
    height: 100%;    
    line-height: 210%;
    top:0;
  }
  .top_products.sec_space{
    padding:0
  }
  .product_align_slider .coming_soon{
    margin:0
  }
  .product_align_slider .coming_soon ul{
    padding-left:0
  }
 
  .home_products {
    padding-top: 0;
}
.company_detail_hover{
  display:block;
}
.zkitcard_footer {
  display: block;
}
.latest_plat_main {
  padding: 20px 0;
}
.profile_tag_main {
  padding: 30px 0 0 0;
}
.blog_detail_inner img.blog_detail_inner-img {  
  height: 450px;  
}
.row.video_slider_inner {
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;  
  flex-wrap: nowrap;
  display: flex;
}
.row.video_slider_inner .col-xs-4 {
  padding: 0 8px;  
  display: flex; 
  flex-direction: column;
  width: 100%;
}
.video_slider_mview {
  width: 180px;
    border-radius: 25px;
    overflow: hidden;
    box-shadow: 0px 4px 4px 0 rgb(0 0 0 / 25%);
    margin-bottom: 15px;
}
.row.video_slider_inner::-webkit-scrollbar{display:none}
} 

@media (max-width: 767.98px) {
	.products_search {
		margin-top: 20px;
	}
  .banner_contant_type .products_search {    
    margin: 0;
    flex-direction: row;
    border-radius: 20px;
  }
  .profile_tag_main .col-md-6 {
    width: 100%;
  }
  .welcome_koytola {
    margin-top: 30px;
  }
  .top_header {
    padding:0 20px 15px;
  }
  .banner_contant_type .ko_banner_btn{
    border-radius:20px;
    margin-right:4px;
  }
  .profile_tag_main .col-md-5 {
    width: 100%;
  }

	.top_header .left_colum {
    width: 100%;
    align-items: center;
    justify-content: center;
	}
	.top_header .logo {
		// justify-content: center;
    width: 40%;
    float: left;
    margin-bottom: 0px;
    margin-right:20px;
	}
  
	.products_search.ps_wrapper_DVIEW {
		justify-content: flex-start;
		margin-left: 0;
    width: 60%;
    margin-top:0
	}
	.profile.float-right {
		margin-top: 35px;
	}
	.home_main_banner .banner_contant {
	    padding: 10px;
    	padding-bottom: 50px;;
	}
	.home_main_banner .banner_contant .products_search {
	    margin-top: 35px;
	}
	.logo_slider .item img {
	    max-width: 70px;
	}
	.logo_slider .owl-nav, .logo_slider .owl-dots {
		display: none;
	}
	.welcome_box {
	    margin-bottom: 40px;
	} 
	.welcome_koytola.sec_space {
		padding: 0;
		text-align: center;
	}
	.strory_slider .col-md-3 {
	    flex: 0 0 100%;
	    max-width: 100%;
	}
	.strory_slider .carousel-inner {
	    height: auto;
	}
	.daily_offer .nav-tabs .owl-carousel .owl-nav.disabled {
		display: none;
	}
	.sec_space {
    padding: 0px;
}
	.selling h3 {
	    font-size: 24px;
	    line-height: 60px;
	}
	
	.daily_offer .tab-content {
	    padding: 30px 0;
	}
	.counter_heading h3 {
	    font-size: 22px;
	    line-height: 34px;
	}
	.counter_heading p {
		width: 90%;
	}
	.Shop_category_contant .card-header {
		padding-left: 0;
	}
	.Shop_category_contant .accordion {
	    width: 100%;
	    margin-bottom: 50px;
	}
	.Shop_category {
		margin-bottom: 25px;
	}
	.why_koytola .row.mt-5{
		margin-top: 0px !important;
	}
	.why_koytola .heading h3 {
	    font-size: 24px;
	    line-height: 40px;
	}
	.why_koytola .support {
		margin-bottom: 15px;
    	margin-top: 15px;
	}
	.why_koytola .support a {
		justify-content: space-between;
	}
	.barriers_heading {
	    margin-right: 0;
	}
	.barriers_heading h2 {
	    text-align: right;
	    font-size: 22px;
	}
	.barriers_contant {
	    margin-left: 0;
	}
	.barriers_inner .row {
	    margin-bottom: 50px;
	}
	.agriculture.sec_space {
	    padding-top: 0;
	}
	.agriculture span {
		width: 100%;
	}
	.changing h2 {
		line-height: 48px;
		font-size: 30px;
	}
	.changing p {
		margin-top: 50px;
	}
	.dashboard_img img {
	    margin-bottom: 40px;
	}
	.news_more .more_btn {
		text-align: left;
		padding-left: 15px;
	}
	.more_contant {
	    margin: 15px 0;
	}
	.footer_contant {
	    max-width: 100%;
	    text-align: center;
	} 
	.footer_contant h2 {
		font-size: 32px;
	}
	.footer_contant .account {
	    margin-top: 25px;
	}
	.counter_box {
		padding-bottom:15px
	}
  .live_port.sec_space {
    margin-top: 20px;
  }
	.news_tabs .nav-tabs .nav-link {
	    padding-right: 40px !important;
	    font-size: 14px;
	}
	.news_tabs {
		margin: 30px;
	}
  .news_letter {    
    padding-top: 10px;    
  }
  .news_letter p {    
    font-size: 14px;    
  }
  .news_letter input {    
    font-size: 12px;
  }
  .news_letter a, .news_letter span {    
    top: 43px;    
}
	.strongly .col-md-4:last-child .strongly_box {
	    margin-bottom: 0;
	}
	.dashboard .row:first-child {
	    margin-bottom: 20px;
	    flex-direction: column-reverse;
      display: flex;
	}
	.sub_categiory .item .row {
	    height: 220px;
	}
	.furniture {
	    padding: 0;
	}
	.view_more {
	    margin-bottom: 40px;
	}
	.sub_categiory_slider .item h5 {
		margin-bottom: 50px;
	}
	.duble_box {
		margin-top: 20px;
	}
	.daily_offer.sec_space {
	    padding-top: 0;
	}
	.selling span {
	    max-width: 100%;
	    margin-bottom: 50px;
	}
	.products_amount h1 {
    font-size: 35px;
    font-weight: 300;
   
	}
  .live_port .col-md-4{
    margin-top: 30px;
  }
  .live_port .float-right {
    text-align: left;
}
.list_menu ul li:not(:last-child) {
  padding-bottom: 0;
}
.interested {
  padding: 30px 0;
}
	.home_main_banner .banner_contant .products_search {
	    justify-content: space-between;
	}
	.home_main_banner .banner_contant .products_search input {
	    width: 200px;
	}
	.home_main_banner .banner_contant .dropdown {
		margin-right: 15px;
	}
	.sub_categiory.sec_space {
	    padding-bottom: 0;
      padding-top:15px;
	}
	footer {
		padding: 0px 0;
	}
	.news_more_inner .row:last-child {
	    margin-bottom: 0;
	}
	.news_more_inner .row {
	    margin-bottom: 40px;
	}

	.live_port .furniture input {
		width: 70%;
	}

	.colum_inner {
	    margin-bottom: 40px;
	}

	.footer_colum {
	    padding-top: 0px;
	}

	.list_menu ul {
    margin-bottom: 40px;
    justify-content: center;
    margin: 0 auto;
    text-align: center;
	}
  .list_menu ul li {
    display: inline-block;
    margin: 0 10px;
}
  .list_menu ul li a {
    font-size: 22px;
}
	.sustainability h3 {
		line-height: 40px;
	}

  .top_header .col-lg-8.col-md-10 {
    width: 100%;
    float: left;
}

.top_header .col-lg-4.col-md-2 {
  width: 100%;
}

.banner_contant h2 {
  width: 100%;
}

.banner_contant {
  padding-top: 60px; 
}

.loqTTm .column {
  width: 100%;
}

.dropdown-content.category li {
  width: 32%;
  margin: 0 0 10px 0;
}
.dropdown-content.category .column {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.dropdown-content.category li img {
  height: 80px;
}
.products_search span{
  font-size: 12px;;
}
.conmplet_outer {
  width: 100%;
  padding: 0 15px;
}
.conmplet_outer h1 {
  font-size: 20px;
  line-height: 24px;
  width: 100%;
  margin-bottom: 10px;
}
.conmplet_outer .conetct_form p {
  font-size: 14px;
  margin-bottom: 5px;
  width: 100%;
}
.conetct_form .input_box {
  width: 100%;
  margin-bottom: 10px;
}
.conetct_form input,.input_box.quantity_sap select {
  border-radius: 3px;
  padding: 10px 14px;
  font-size: 14px;
}
.conmplet_outer .right_btn {
  width: 100%;
  margin: 7px 0 15px 0;
}
.conmplet_outer .right_btn button {
  color: #fff;
  background-color: #7324d4;
  padding: 10px 30px;
  border-radius: 40px;
  width: 100%;
}
.dropdown-content.category .row {
  margin: 0;
  padding-top: 15px;
  height: 50vh;
}
.creating_sour {
  font-size: 13px;
  padding-left: 0;
  text-align: center;
  margin-bottom: 15px;
  list-style: none;
}
.banner_contant_type {
  display: block;
}

.banner_contant_type .MuiAutocomplete-hasPopupIcon {
  width: 100%;
}

.home_slider_main .selectEmpty {
  margin-left: 0px !important;
  margin-top: 30px;
  width: 100%;
}

.welcome_koytola .col-md-4 {
  width: 100%;
  float: left;
}

.top_products .section_heading {
  padding-bottom: 0;
}

.selling span {
  margin-bottom: 0;
}

.counter_inner .col-md-4 {
  width: 100%;
}

.counter_box h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-size:32px;
  float:right;
  display:inline-block;
}

.counter_box span {
  min-height: auto;
}

.Shop_category span {
  font-size: 20px;
  width: 65%;
}

.top_products.category {
  padding-top :40px;
}

.insustnt {
  width: 100%;
  margin-bottom: 20px;
}

.quantity .col-md-6 {
  width: 100%;
}

.logistics_inner_col:last-child {
  margin-top: 0px;
}

.checkboxouter {
  width: 100%;
  float: left;
}

.why_koytola.sec_space {
  padding-bottom: 0;
  margin-bottom:30px;
}

.barriers_contant {
  margin-top: 20px;
}

.marketing_support .col-md-6 {
  width: 100%;
  margin-bottom: 10px;
}

.read_more_story {
  padding-top:20px;
}
.koytola_family .account a{
  justify-content:flex-start;
}
.dashboard_img {
  margin-bottom: 0;
}

.news_tabs .nav-tabs .nav-link {
  padding-right: 5px !important;
}

.news_more_inner .row {
  display: inline-block;
}

.koytola_family {
  padding: 50px 0;
}

.sustainability {
  padding: 30px 0 0;
}

.footer_colum .col-md-4 {
  width: 100%;
  margin-bottom: 0px;
}

.deals_table_main .MuiPaper-rounded {
  box-shadow: none;
  width: 345px;
  border: 0;
  margin-bottom: 50px;
}

.products_search input {
  width: 50%;
}


.home_slider_main {
  padding: 30px 0 0;
}

.selling.more span {
  max-width: 100%;
  width:100%;
}
.why_heading{
  margin-top:20px;
}
.selling {
  padding-bottom: 30px;
}

.inline_tags_aria .MuiGrid-grid-xs-2 {
  width: 33.33%;
  float: left;
  padding: 0px 10px !important;
}

.inline_tags_aria {
  display: inline-block;
  margin-top: 0px;
}

.fillter_inner {
  padding-left: 20px;
  padding-right: 20px;
}

.fillter_inner label, .selected_fillter_inner label {
  margin-right: 20px;
  white-space:nowrap;
  min-width:auto;
}
.MuiCard-root{
  padding:0 20px;
}
._3uApM {
  width: 100%;
  float: initial;
}

.company_top_space {
  margin-top: 20px !important;
  display: inline-block !important;
  margin-bottom: 0 !important;
}   

.company_top_space .border_left_right {
  border-left: 0;
  border-right: 0;
  margin-bottom: 20px;
}

.latest_plat_box {
  padding: 20px 0;
  text-align: center;
  border-bottom: solid 1px #ddd;
}

.latest_plat_main .col-md-4 {
  width: 100%;
  float: left;
}
.latest_plat_main .col-xs-6{
width:50%
}
.step-progress-bar{
  text-align:center;
}
.select_box_inner{
  margin:0 -15px;
}
.query_summery_box {
  margin-top: 15px;
}
.desc{
  font-size: 14px;
}

.translation-icons.lengvage {
  margin-right: 0px !important;
}

.dropdwn.discover_main_menu .dropbtn {
  margin-left: 50px;
}

.top_header .col-lg-3.col-xs-2 {
  width: 100%;
}

.products_search .MuiAutocomplete-hasClearIcon {
  min-width: 180px;
  width:100%;
}
.products_search span {
  display:none;
}
.category-mobile{
  margin-top: 0px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.top_header .col-lg-9.col-xs-10 {
  width: 100%;
}

.profile .dropdown-toggle, .main_header .dropdown-toggle {
  width: 25px;
}

.dropdown-toggle::after {
  right: 10px;
}

.discover_main_menu .dropdown-content {
  left: 15px;
  z-index: 999;
  width: 346px;
  top: 8px;
}

.cont_space_remove {
  margin-top: 0;
}

.company_top_space .col-md-5,
.company_top_space .col-md-4 {
  width: 100%;
}

.profile_right .flex-row {
  justify-content: space-between;
  margin-top: 30px;
}

.latest_plat_box .offer_over {
  justify-content: space-around;
}

.latest_plat_box h2 {
  font-size: 22px;
  margin: 10px 0 0 0;
}
.list_item_outer .list_cheked {
  justify-content: center;
}
.saprat_sec .kargo_hespla_outer {
  width: 100%;
}
.kargo_hespla:after {
  content:inherit;
  position: inherit;
}
.saprat_sec .kargo_hespla {
  padding: 35px;
  border-radius: 10px;
}
.saprat_sec .kargo_hespla h1 {
  font-size: 22px;
  margin-bottom: 25px;
  line-height: 28px;
}
.saprat_sec .kargo_input {
  margin-bottom: 20px;
}
.saprat_sec .kargo_miktr {
  margin: 25px 0;
}
.kargo_input.miktr {
  margin-bottom: 0;
}
.kargo_input.miktr input {
  font-size: 26px !important;
  line-height: 26px;
}
.lowest_btn ul {
  width: 100%;
}
.krgo_inner_cnt p {
  padding-left: 0;
}
.certificates_outerm {
  width: 100%;
  margin-bottom: 50px;
}
.krgo_inner_cnt h3 {
  font-size: 20px;
  line-height: 24px;
  padding: 0 15px;
  margin: 0 0 10px 0;
}
.products_search.ps_wrapper_DVIEW {
  position: static;
}
.gallery_slider_main {
  padding-top: 0;
}
.products_gallery_tag {
  justify-content: flex-start;
  margin-top: 30px;
}

.products_gallery_tag .tag {
  margin-left: 0;
}

.product_gallery_image img {
  height: 140px;
}

.create_query h3 {
  font-size: 18px;
  line-height: 26px;
}

.progressbar .row {
  flex-wrap: wrap;
}

.progressbar .col-xs-10 {
  width: 100%;
}

.progressbar .col-xs-2 {
  width: 100%;
  margin-bottom: 20px;
  text-align: center;
}
.create_query {  
  padding-bottom: 20px;  
}
.progressbar .CircularProgressbar {
  width: 100px;
}

.profile_heading_maltipal.make_ober h3 {
  font-size: 22px;
  margin: 10px 0 0 0;
}
.profile_tab_panel {
  padding: 20px 0;
}
.kilogram_box {
  width: 100%;
}
.kilogram_box .kilogram_input {
  height: 50px;
  min-height: 175px;
  font-size: 42px;
}
.select_country_inner {
  min-height: 175px;
  width: 100%;
}
.select_country_inner p {
  font-size: 32px;
}
.submit_filds .input_fild {
  margin-bottom: 15px;
}
.list_item_outer .list_item .list_img {
  width: 40%;
}

.list_item_outer .list_item .list_img img {
  height: 55px;
}

.list_item_outer .list_item .list_contant {
  width: 55%;
}

.list_item_outer .list_item .list_contant span {
  font-size: 14px; 
}

.select_box_uli .selected_contant {
  width: 75%;
}

.massage_inner .massage_box {
  width: 100%;
  min-height: 200px; 
}

.query_summery_box .col-xs-6 {
  width: 100%;
}

.makeover_thanks h2 {
  font-size: 38px;
}

.makeover_thanks h4 {
  font-size: 22px;
  line-height: 28px; 
}

.social_activity .profile_heading_maltipal h2 {
  width: 100%;
}

.social_activity .col-md-6 {
  width: 100%;
  float: left;
  margin-bottom: 70px;
}

.social_activity .col-md-4 {
  width: 100%;
}

.footer_colum .col-xs-3 {
  width: 50%;
}

.information_bottom {
  background: #333232;
    margin: 0 -15px;
    padding: 13px 30px;
}
.information_bottom p {
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  color: #fff;
}
.footer_rumi { 
  font-size: 11px; 
  color: #fff;
}
.footer_copyright {
  margin-top: 0;
  text-align: center;
  background: #333232;
  padding-bottom: 5px;
}
.footer_copyright p {
  margin-bottom: 0;
  font-size: 10px;
  font-weight: 300;
  color: #FAFAFA;
}
.footer_copyright p a {
  color: #FAFAFA;
}

.company_profile_head .company_logo img {
  height: 45px;
  width: 45px;
}

.welcome_koytola .welcome_box {
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
}

.welcome_koytola .col-md-4:last-child .welcome_box{
  margin-bottom: 0px;
}

.crnc_houry {
  margin-left: 0px;
}

.profile.float-right .become_supplier {
  padding: 12px 8px;
    font-size: 13px;
    width: 90%;
}
.banner_contant h1 {
  font-size:20px;
  line-height:36px;
}
.banner_contant h1 span{
  font-size:36px;
}
.home_slider_main .banner_contant {
  padding-top: 0;
  width: 100%;
  margin-bottom: 40px;
  text-align:center;
}
.banner_contant p {  
  font-size: 16px;
  line-height: 24px;
  color: #707070;
  margin-top: 0;
  opacity: 0.7;
}
.home_slider_main .col-xs-6 {
  width: 100%;
}

.home_slider_main .banner_contant .MuiAutocomplete-hasClearIcon {
  width: 100%;
}

.home_slider_main .banner_contant .banner_contant_type {
  width: 90%;
  margin: 20px auto;
}

.selling.text-writer span {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 0px;
}
.selling.text-writer span .d-xs-block {
  display: block;
  padding:0
}
.product_align_slider .coming_soon ul {
  padding-left: 0px;
}
.product_align_slider .coming_soon li {  
  margin:5px;
  margin-bottom: 0;
}
.product_align_slider .coming_soon li a {
  font-size: 11px;  
  padding-left: 25px;
  white-space:nowrap;
}
.product_align_slider .coming_soon li a.active{
    color: #7604CD;
    position: relative;
}
.product_align_slider .coming_soon li a.active:before {
  content: "";
  width: 20px;
  height: 1px;
  background: #7604CD;
  position: absolute;
  top: 8px;
  left: 0;
}
.topproduct_inner-imgwrap {
  border-radius: 30px !important;
  margin: 7px;
}
.products_amount span{
  font-weight: 400;
}
.product_align_slider .coming_soon {
  margin-top: 0;
}

.products_amount {
  padding-bottom: 0px;
  padding-top: 30px;
}

.selling.more h3 {
  line-height: 34px;
  font-size: 24px;
  width:100%
}

.section_heading h2 {
  font-size: 16px;
}

.multi_textbtn {
  padding: 6px 20px;
  width: 215px;
}

.inner_headi h5 {
  margin-bottom: 20px;
  text-align:left;
}

.inner_headi h2 {
  font-size: 24px;
  line-height: 42px;
  font-weight: 500;
} 

.trky_koytola {
 display:none;
}

.dream_box {
  width: 100%;
  margin: auto !important;  
}

.why_koytola.sec_space .col-md-4 {
  width: 100%;
}

.micro_trade_contnt h3 {
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 20px;
  
}

.micro_trade_contnt p {
  width: 80%; 
  font-size: 10px;
  font-weight: 400;
  line-height: 18px;
}

.micro_trade:before {
  display:none
}

.micro_trade {
  margin-bottom: 40px;
  margin-top:50px;
  background:none;
  padding:0;
}
.why_koytola .row.align-items-center {
  margin-top: 0;
  background: #F5F5F5;
}

.guarantee_outer {
  flex-direction: column;
}

.barriers_heading h2 {
  text-align: center;
  font-size: 22px;
  width: 100%;
  line-height: 32px;
}

.barriers_heading {
  display: inline-block;
  margin-right: 0;
  width: 100%;
}

.barriers_contant {
  width: 90%;
  text-align: center;
  margin: auto;
  margin-top: 10px;
}

.swiper-container {
  height: 240px;
  margin-top: 0px;
}
.strongly .row:first-child{
  margin-top:0
}
.ins_heading h5{
  text-align:center;
}
.strongly_heading span {  
  text-align: center;
  display: block;
}
.news_contant a, .news_box a {
  font-size: 14px;  
  margin-bottom: 0; 
}
.vartical_inspaction {
  padding: 20px 0; 
}

.strongly_heading h4 span {
  font-size: 17px;
}

.koytola_family .top_heading {
  width: 100%;
}

.koytola_family .top_heading h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}

.selling_fast h5 {
  margin-bottom: 10px;
  font-size: 18px;
}

.selling_fast {
  margin-bottom: 40px;
}

.selling_fast p {
  margin-bottom: 10px;
}

.selling_fast p:last-child {
  margin-bottom: 0px;
}

.selling_fast.border_lr:before, .selling_fast.border_lr:after {
  display: none;
}

.footer_colum.information {
  padding-top: 0px;
}
.footer_information_heading h4{
  margin-bottom:0;
  font-size: 14px;
  font-weight:400;
}
.footer_colum .colum_inner h6 {
  min-height: auto;
  font-size: 13px;
  font-weight: 500;
  color: #000;
  margin-bottom: 4px;
}
.information .colum_inner span {
  font-size: 13px;
 margin-bottom:15px;
  font-weight: 400;
}
.footer_colum .colum_inner ul li {
  margin-bottom: 0;
}
.footer_colum .colum_inner ul li a {
  font-size: 12px;
  font-weight: 400;  
}
.footer_colum .colum_inner {
  margin-bottom: 0;
}

.interested_contant {
  margin-top: 40px;
}

.top_header .col-xs-9 {
  width: 100%;
}

.top_header .col-xs-3 {
  width: 100%;
}

.koytola_family .col-xs-4 {
  width: 100%;
}

.interested .col-xs-6 {
  width: 100%;
}

.footer_colum.information .col-xs-5 {
  width: 100%;
  margin-bottom: 30px;
}

.footer_colum.information .col-md-6 {
  margin-bottom: 30px;
}

.dashboard .col-xs-6 {
  width: 100%;
}

.inline_tags_aria .MuiGrid-item .MuiGrid-grid-xs-4 {
  width: 100%;
  max-width: 50%;
}

.inline_tags_aria .MuiGrid-item {
  max-width: 65%;
}

.dropdown-content.category {
  left: 0;
  box-shadow: 0 10px 10px rgb(0 0 0 / 18%);
  border-radius: 7px;
  padding: 0;
  overflow: hidden;
  top: 105%;
  border: solid 1px #ddd;
}
.dropdown-content.category .container {
  padding: 0;
}
.product_grid_list {
  max-width: 100% !important;
  flex-basis: 100% !important;
}

.border_after_before:before, .border_after_before:after {
  display: none;
}

.latest_plat_main {
  padding: 0;
}

main {
  width: 100%;
  overflow: hidden;
}

.logo_slider_home {
  padding: 0px 0 20px 0px;
}

.product_align_slider .owl-dots {
  display: none;
}

.top_products .owl-carousel {
  margin-top: 10px;
}

.top_products.sec_space {
  padding: 30px 0;
}

.top_products.category {
  padding: 40px 0px !important;
}

.news_koytola .col-md-8 .col-md-6 {
  width: 100%;
  float: left;
}

.product_inner img {
  padding-left: 0px;
  padding-right: 0px;
  height: 180px;
}
.trendingproduct_inner img{
  height: 120px;
}

.product_detail_heading {
  width: 60%;
}

.ViewCompanyouter {
  width: 40%;
}

.product_image_outer img {
  height: 270px !important;
} 

.priceShow {
  width: 50%;
  margin-bottom: 10px;
  min-height: 42px;
  padding: 0px 10px 0 0;
} 
.priceShow .Product_data_show,.priceShow .count {
  font-size: 14px;
}
.showDetaile  {
  padding-top: 15px;
}
.profile_mission {
  padding:0
}

.mission_inner_contant span:first-line {
  font-size: 24px;
  line-height: 40px;
}

.mission_inner_contant span {
  margin-top: 15px;
}

.detail_main_heading {
  font-size: 20px;
  margin-bottom: 15px;
}
.specification_colum {
  margin-bottom: 0 !important;
  padding: 10px 0;
  width: 45%;
}
.specification_colums.row {
  display: flex;
  justify-content: space-between;
}
.detail_delivery {
  padding:0 0 30px;
}

.detail_review .review_count {
  font-size: 50px;
}

.write_review_btn {
  justify-content: flex-start;
  margin-top: 30px;
}

.you_might_like .owl-carousel {
  margin-top: 20px;
}

.you_might_like {
  padding: 30px 0;
}

.detail_review {
  padding: 40px 0px;
}
.resource_btns_main {
  padding-bottom: 30px;
}

.specifications_main {
  padding: 20px 0px;
}

.gallery_slider img, .gallery_slider video {
  width: 100% !important;
}

.work_proses {
  height: 140px;
  width: 100%;
}

.specification_colum_main {
  margin-top: 0 !important;
}

.strory_slider .carousel-item .inner {
  transform: initial;
  margin-left: 30px;
}

.sub_categiory_item {
  padding-right: 0;
}

.home_products .col-md-3 {
  width: 100%;
  float: initial;
}

.top_header .logo h5 {
  font-size: 30px;
  margin-right: 20px;
}
.daily_offer .section_heading {
  padding-bottom: 0;
}
.dream_box h5 {
  font-size: 10px;
  line-height: 17px;
}
.home_products {
  padding-top: 0;
  margin:0 -15px;
}
.product_list_box {
  min-height: auto;
  margin-bottom: 20px;
  box-shadow: 0px 3px 6px 0 rgba(0,0,0,0.16);
  border-radius: 20px;
  padding: 20px 20px 0;
}
.product_list_box .top_bar{
  height:50px;
  overflow:hidden;
}
.product_list_box .product_image{
  box-shadow:none !important;
  border-radius: 10px !important;
}
.product_list_box .product_image img { 
  height: 90px; 
}
.product_list_box .product_cont {  
  padding: 10px;
  padding-bottom: 20px;
  background-color: #fff;
  border-radius: 20px;  
  margin-top: 0px;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
  margin: 0 -20px;
}
.product_list_box .product_cont h4 { 
  font-size: 12px;
  line-height: 18px; 
  margin: 0;
  text-align: center;
}
.product_cont h4,
.product_cont h4 font{ 
  -webkit-line-clamp: 1;  
}
.product_list_box .top_bar .location h4 {
  margin: 0;
  font-size: 16px;
  margin-bottom: 0;
}
.product_list_box .product_cont .price h3 {
  margin: 0;
  margin-top: 8px; 
  font-size: 13px;
  margin-right: 8px;
}
.product_list_box .company_detail_hover {  
  margin-top: 10px; 
}
.company_detail_hover .supplier_infoaria2 img {  
  height: 30px; 
  border-radius: 30px; 
}
.product_list_box:hover .product_cont {  
  transform: translateX(0px) translateY(0px);  
}
.MuiTabs-scroller.MuiTabs-fixed .MuiTabs-flexContainer.MuiTabs-centered, .MuiCard-root{
  flex-wrap: nowrap;
    overflow-x: auto !important;   
    overflow-y: hidden;
    justify-content:flex-start;
    -ms-overflow-style: -ms-autohiding-scrollbar;   
    -webkit-overflow-scrolling: touch;    
    white-space: nowrap;
}
.MuiTabs-scroller.MuiTabs-fixed button {
  min-width: auto;
  width: auto;
  float: left;
}
.MuiTabs-scroller.MuiTabs-fixed .MuiTabs-flexContainer.MuiTabs-centered::-webkit-scrollbar, .MuiCard-root::-webkit-scrollbar {
  display: none;
}
.blog_detail_inner img.blog_detail_inner-img {
  width: 100%; 
  height: auto;  
}
.logo_img_center{
 flex-wrap:wrap; 
 justify-content:center;
}
.logo_img_center img {
  width: 75px; 
  margin: 10px;
}
.products_gallery {
  padding: 30px 0;
}
.products_gallery .row.align-items-center{
  margin-bottom: 30px;
}
.gallery_btns {
  margin-top: 15px;
}
}


@media (max-width: 374.98px) {
	.products_search input {
	    width: 130px;
	    margin-right: 10px;
	}
}
.contactus-heading h5{
  margin:50px 0 30px;
}
.contact-form .MuiOutlinedInput-notchedOutline{
  border-color: #7A38DADE;
  border-radius: 22px;
}
.contact-form .MuiFormControl-fullWidth{
  margin-bottom:30px
}
.contact-form .send-btn {
  color: #fff;
  background: linear-gradient(271.02deg, #B785FF 5.34%, rgba(133, 185, 255, 0.528412) 51.53%, rgba(100, 194, 172, 0.154116) 88.2%, rgba(255, 255, 255, 0) 103.3%), #7A38DA;
  font-size: 14px;
  padding: 3px 12px;
  text-align: center;
  border-radius: 20px;
  float:right;
}
.contact-map {
  border-radius: 25px;
  overflow: hidden;
  margin-bottom:25px;
  box-shadow:0 3px 3px 0 rgb(0 0 0 / 25%);
}
.searchproduct_MVIEW, .d-sm-none{
  display:none;
}
.product_name  {
  width: 158px;
  text-transform: uppercase;
  height: 33px;
  font-Family:Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height:33px;
  color: #444444;
}
@media(max-width:767px){
  .contact-map{
    margin-top:30px;
  }
  .contactus-heading h5 {
    margin: 30px 0 20px;
  }
  .contact-rightpannel{
    margin-bottom:30px;
  }
  .topbar-header-mobile{
    padding:10px;
    height: 75px;
    margin-bottom: 60px;
  }
  .topbar-header-mobile .topline-mobile p{
    font-size:12px;
  }
  .topbar-header-mobile .topline-mobile p span{
    display:block;
  }
  .searchproduct_DVIEW{
    display:none;
  }
  .searchproduct_MVIEW{
    display: block;
    margin-top: 5px;
    width: 75%;
    margin: 5px auto;
  }
  .searchproduct_MVIEW.products_search:before {
    top: 15px;
    right: 20px;
    left: auto;
    color: #7A38DA;
  }
  .searchproduct_MVIEW.products_search .MuiAutocomplete-hasClearIcon .MuiInput-underline {   
    border: 1px solid #7A38DA;
    border-radius: 50px;
    padding-right: 40px !important;
    background: #fff;
    padding: 5px 40px 5px 15px;
  }
  .searchproduct_MVIEW.products_search .MuiAutocomplete-hasClearIcon .MuiInput-underline #combo-box-demo::placeholder {    
    font-size: 12px;    
  } 
  .ps_wrapper_DVIEW:before{
    display:none;
  }
  .category-mobile .categiory_btn:before, .dropdown.header_user span{
    display:none;
  }
  .word_header_p {    
    margin-left: 0;
    margin-top: 5px;     
    text-align: center;
    width: 90%;
  }
  .loqTTm .dropdwn .dropbtn{
    font-size:12px !important;
  }
  .currencyStrip {
    border-top: 1px solid #ddd;
    padding-top: 2px;
  }
.currencyStrip_inner { 
  margin: 0 -15px;  
}
.currencyStrip_inner .marquee-container.horizontal {  
  min-height: 30px !important;
}
.home_top_slider {    
  box-shadow: none;
  padding: 0;
  border-radius: 0;
}
.home_slider_main .home_top_slider .owl-carousel img {
  height: 52px;
}
.d-xs-none{
   display:none !important;
}
.d-xs-block{
  display:block;
} 
.daily_offer .dealy_offter_marquee:before, .daily_offer .dealy_offter_marquee:after{
  display:none;
}
.daily_offer .marquee-container.horizontal {
  min-height: 30px !important;  
  margin-top: 20px;
  margin-bottom: 20px;
  border: none;
}
.daily_offer .marquee-item {
  margin: 0 5px;
}
.daily_offer .dealy_offter_marquee {  
  margin: 0 -15px;
}
.daily_offer .marquee-item button {
  font-size: 10px; 
  padding: 4px 12px;
}
.video_slider_main {
  padding: 30px 0;
}
.videoslider_bottom-row .col-xs-9{
  padding-right:0
}
.videoslider_bottom-row .sldier_side_btn{
  display:flex;
}
.videoslider_bottom-row .sldier_side_btn button {  
  margin: 5px;  
  min-width: auto;  
  font-size: 8px;
  width: 30%;
}
.slider_side_contant h3 { 
  font-size: 10px;  
  text-align: center;
}
.slider_side_contant h3 svg {  
  font-size: 14px; 
}
.selling text-writer{
  text-align:center;
}
.shipping-calculatoin-wrapper{
  background: linear-gradient(225deg, #7A38DA 0%, #9C80C6 50.5%, #7949BF 100%);
  margin: 0 -15px;
  box-shadow: none;
  border: none;
  border-radius: 0 35px 0 35px;
  padding:50px 25px 25px;
  text-align:center;
}
.shipping-calculatoin-wrapper .col-xs-6{
  padding:0 5px;
}
.shipping-calculatoin-wrapper:after{
  display:none;
}
.shipping-calculatoin-wrapper h1 {    
  margin-bottom: 40px;
  font-size:14px;
}
.shipping-calculatoin-wrapper .kargo_input input {  
  font-size: 13px !important;
  padding: 10px !important;
  border-radius: 10px;
}
.shipping-calculatoin-wrapper .kargo_input input::placeholder {  
  opacity: 0.5;
}
.shipping-calculatoin-wrapper .kargo_miktr h5 {  
  font-size: 12px;
  line-height: 20px;
  margin-right: 10px;
}
.shipping-calculatoin-wrapper .kargo_miktr{
  margin:80px 0 50px;
}
.shipping-calculatoin-wrapper .kargo_input.miktr .quantity { 
  height: 40px;  
  width: 60px;  
}
.shipping-calculatoin-wrapper .kargo_input.miktr input {  
  font-size: 18px !important;
  line-height: 30px;  
}
.shipping-calculatoin-wrapper .kargo_input.miktr .quantity span { 
  right: 3px;
  font-size: 10px;  
  bottom: 2px;  
}
.shipping-calculatoin-wrapper .lowest_btn button {
  font-size: 15px;
  color: #000;  
  padding: 8px 40px;  
  margin-bottom: 25px;
  border-radius: 5px;
  border: none;
}
.shipping-calculatoin-wrapper .lowest_btn button span {
  display: block;
  font-size: 11px;
  color: #676767;
}
.shipping-calculatoin-wrapper .lowest_btn ul {
  padding: 0;
  text-align: center;
  width: 100%;  
}
.shipping-calculatoin-wrapper .lowest_btn ul li {  
  font-weight: 400;
  font-size: 11px;  
  line-height: 17px;
}
.why_koytola .inner_headi h2 {
  font-size: 16px;
  line-height: 36px;
}
.news_box {
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
  padding: 15px;
  border-radius: 0 30px 0 30px;
  margin: 10px 0;
}
.news_box .images_hadoweffect {
  border-radius: 0;
  box-shadow: none; 
  margin-bottom:10px !important;
}
.news_box img {
  height: 200px;  
}
.news_box .news_box_contant h4 {
  margin: 0;
  margin-bottom: 15px;
   cursor:pointer;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 10px;
  font-weight: 700;
}
.news_box .news_box_contant span {
  font-size: 12px !important; 
}
.MuiTabs-scroller.MuiTabs-fixed button span {
  font-size: 14px;
}
.selected_fillter_outer {
  margin: 20px auto 0 auto;
}
.sx-p0 {
  padding: 0 !important;
}
.fillter_inner label .MuiIconButton-colorSecondary, .selected_fillter_inner label .MuiIconButton-colorSecondary {
  height: 20px;
  width: 20px;
}
.fillter_inner label .MuiFormControlLabel-label {
  font-size: 12px !important;
}
.subImage img {
  width: 100px;
  height: 100px;
}
.products_sub_image {
  margin-top: 15px;
}
.zkitProductbox {
  padding: 0;
}
.tags_outer {
  padding-top: 8px;
}
.top_bar_nav {
  margin-bottom: 15px;
  margin-top: 15px;
}
.product_name_header {
  margin-bottom: 25px;
}
.product_name {
  font-size: 18px;
  line-height: 24px;
}
.company_rosettes_box_outer .commpany_rosettes_box {
  width: 48%;
  margin-right: 0;
  border: solid 1px #ddd;
  padding: 2px 8px 2px 2px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.company_rosettes_box_outer {
  justify-content: space-between;
}
}
@media(max-width:380px){
  .top_header {
    padding: 0 10px 15px;
  }
  .profile.float-right .become_supplier {    
    font-size: 11px;   
  }
  .dropdwn.discover_main_menu .dropbtn {
    margin-left: 10px;
  }
  .searchproduct_MVIEW{
    width:90%;
  } 
  .video_slider_main {
    padding: 30px 0;
  }
  .trendingproduct_inner img {
    height: 90px;
}
}

@media (min-width: 768px) and (max-width: 991.98px) { 
  
  







 }





















}
`;
