import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
const SupplierSourcing = gql`
mutation complimentarySupplierSourcing(
    $productType:String!
    $priceRange:String!
    $privateLabel:String
    $name:String!
    $notes:String
    $destination:String!
    $email:String!
    $quantity:Int!
    $unit:ProductUnitsEnum
) {
    complimentarySupplierSourcingCreate(
        input :{
      productType:$productType
      priceRange: $priceRange
      privateLabel: $privateLabel
      name: $name
      notes: $notes
      destination: $destination
      email: $email
      quantity: $quantity
      unit:$unit
    }){
      message
      complimentarySupplierSourcingErrors{
        message
        code
        field
      }
    }
  }
`;
const SellerReq = () => {
  document.title="Supplier Sourcing";
  window.scrollTo(0, 0)
  const history = useHistory();
  const [SupplierSourcingdata] = useMutation(SupplierSourcing);
  const req = "This field required";
  const blank = "";
  const invalidemail = "Invalid email address";
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const [send, setSend] = useState(false)
  const [formdata, setData] = useState({
    type: '',
    quantity: '',
    destination: '',
    range: '',
    label: '',
    notes: '',
    name: '',
    email: '',
    unit: ''
  })
  const [dataerror, setError] = useState({
    type: '',
    quantity: '',
    destination: '',
    range: '',
    label: '',
    notes: '',
    name: '',
    email: '',
    unit: ''
  })
  const [label, setLebel] = useState({
    type: 'Product Type',
    quantity: 'Quantity',
    destination: 'Destination',
    range: 'Price Range',
    label: 'Do you want Private Label ?',
    notes: 'Notes',
    name: 'Your name',
    email: 'Your e-mail',
    button: "Send"
  })
  const UnitType = [
    {
      value: "",
      name: "unit"
    },
    {
      value: "PIECE",
      name: " ADET / PIECE"
    },
    {
      value: "CENTIMETER",
      name: "CENTIMETER"
    },
    {
      value: "CENTIMETER_SQUARE",
      name: "CENTIMETER-SQUARE"
    },
    {
      value: "CENTIMETER_CUBE",
      name: "CENTIMETER-CUBE"
    },
    {
      value: "GALLON",
      name: "GALLON"
    },
    {
      value: "GRAM",
      name: "GRAM"
    },
    {
      value: "ITEM",
      name: "ITEM"
    },
    {
      value: "KILOGRAM",
      name: "KILOGRAM"
    },
    {
      value: "LBM",
      name: "LBM"
    },
    {
      value: "LITER",
      name: "LITER"
    },
    {
      value: "MILLIGRAM",
      name: "MILLIGRAM"
    },
    {
      value: "MILLIMETER",
      name: "MILLIMETER"
    },
    {
      value: "MILLIMETER_SQUARE",
      name: "MILLIMETER-SQUARE"
    },
    {
      value: "MILLIMETER_CUBE",
      name: "MILLIMETER-CUBE"
    },
    {
      value: "METER",
      name: "METER"
    },
    {
      value: "METER_SQUARE",
      name: "METER-SQUARE"
    },
    {
      value: "METER_CUBE",
      name: "METER-CUBE"
    },
    {
      value: "OUNCE",
      name: "OUNCE"
    },
    {
      value: "TON",
      name: "TON"
    },
  ];
  const success = () => {
    window.scroll(0, 0)
    setSend(true)
  }
  const respone = () => {
    const handleSubmit = async () => {
      const response = await SupplierSourcingdata({
        variables: {
          productType: formdata.type,
          priceRange: formdata.range,
          privateLabel: formdata.label,
          name: formdata.name,
          notes: formdata.notes,
          destination: formdata.destination,
          email: formdata.email,
          quantity: formdata.quantity,
          unit: formdata.unit,
        }
      });
      response && response?.data?.complimentarySupplierSourcingCreate?.complimentarySupplierSourcingErrors?.length === 0 && success()
    }
    handleSubmit()
  }
  const submit = () => {
    setError({
      ...dataerror,
      type: formdata.type.length <= 0 ? req : blank,
      quantity: parseInt(formdata.quantity) <= 0 ? "Greater than 0 allowed" : formdata.quantity.length <= 0 ? req : blank,
      destination: formdata.destination.length <= 0 ? req : blank,
      range: formdata.range.length <= 0 ? req : blank,
      label: formdata.label.length <= 0 ? req : blank,
      notes: formdata.notes.length <= 0 ? req : blank,
      unit: formdata.unit.length <= 0 ? req : blank,
      name: formdata.name.length <= 0 ? req : blank,
      email: formdata.email.length <= 0 ? req : (formdata.email.match(mailformat) ? blank : invalidemail)
    })
    formdata.type.length > 0 && parseInt(formdata.quantity) > 0 && formdata.destination.length > 0 && formdata.range.length > 0 && formdata.name.length > 0 && formdata.unit.length > 0 && formdata.email.length > 0 && formdata.email.match(mailformat) && respone()
  }
  return (
    <div className="container">
      <div className="conmplet_outer">
        <h1>Complimentary
          supplier sourcing
        </h1>
        <p>We are here to research locally in Turkey
          and share the top 5 best-fit suppliers based on your product type.
        </p>
        {send ?
          <div className="we_recievid">
            <h1>We received.</h1>
            <h2>Thank you</h2>
            <p>
              <button onClick={()=>history.push("/")}>Main page </button>
            </p>
          </div> :
          <div className="conetct_form">
            <p>{label.type}</p>
            <div className="input_box">
              <input type="text"
                onChange={(e) => {
                  setData({
                    ...formdata,
                    type: e.target.value
                  })
                  setError({
                    ...dataerror,
                    type: e.target.value.length <= 0 ? req : blank
                  })
                }}
              />
              <span>{dataerror.type}</span>
            </div>
            <p>{label.quantity}</p>
            <div className="input_box quantity_sap">
              <input type="number" value={formdata.quantity}
                onChange={(e) => {
                  setData({
                    ...formdata,
                    quantity: e.target.value.toString().split(".").map((el, i) => i ? el.split("").slice(0, 2).join("") : el).join(".")
                  })
                  setError({
                    ...dataerror,
                    quantity: parseInt(e.target.value) <= 0 ? "Greater than 0 allowed" : e.target.value.length <= 0 ? req : blank
                  })
                }}
              />
              <select
                name="unit"
                onChange={(e) => {
                  setData({
                    ...formdata,
                    unit: e.target.value
                  })
                  setError({
                    ...dataerror,
                    unit: e.target.value.length <= 0 ? req : blank
                  })
                }}
              >
                {UnitType.map((res, index) =>
                  <option value={res?.value}
                  >{res.name}</option>
                )}
              </select>
              <div className="error_f">
                <span>{dataerror.quantity}</span>
                <span>{dataerror.unit}</span>
              </div>
            </div>
            <p>{label.destination}</p>
            <div className="input_box">
              <input type="text"
                onChange={(e) => {
                  setData({
                    ...formdata,
                    destination: e.target.value
                  })
                  setError({
                    ...dataerror,
                    destination: e.target.value.length <= 0 ? req : blank
                  })
                }}
              />
              <span>{dataerror.destination}</span>
            </div>
            <p>{label.range}</p>
            <div className="input_box">
              <input type="text"
                onChange={(e) => {
                  setData({
                    ...formdata,
                    range: e.target.value
                  })
                  setError({
                    ...dataerror,
                    range: e.target.value.length <= 0 ? req : blank
                  })
                }}
              />
              <span>{dataerror.range}</span>
            </div>
            <p>{label.label}</p>
            <div className="input_box">
              <input type="text"
                onChange={(e) => {
                  setData({
                    ...formdata,
                    label: e.target.value
                  })
                  setError({
                    ...dataerror,
                    label: e.target.value.length <= 0 ? req : blank
                  })
                }}
              />
            </div>
            <p>{label.notes}</p>
            <div className="input_box">
              <input type="text"
                onChange={(e) => {
                  setData({
                    ...formdata,
                    notes: e.target.value
                  })
                  setError({
                    ...dataerror,
                    notes: e.target.value.length <= 0 ? req : blank
                  })
                }}
              />
            </div>
            <p>{label.name}</p>
            <div className="input_box">
              <input type="text"
                onChange={(e) => {
                  setData({
                    ...formdata,
                    name: e.target.value
                  })
                  setError({
                    ...dataerror,
                    name: e.target.value.length <= 0 ? req : blank
                  })
                }}
              />
              <span>{dataerror.name}</span>
            </div>
            <p>{label.email}</p>
            <div className="input_box">
              <input type="text"
                onChange={(e) => {
                  setData({
                    ...formdata,
                    email: e.target.value
                  })
                  setError({
                    ...dataerror,
                    email: e.target.value.length <= 0 ? req : (e.target.value.match(mailformat) ? blank : invalidemail)
                  })
                }}
              />
              <span>{dataerror.email}</span>
            </div>
            <div className="right_btn">
              <button onClick={submit}>{label.button}</button>
            </div>
          </div>
        }
      </div>
      <p className="creating_sour"><li>Creating a sourcing report takes 5-7 days.</li></p>
    </div>
  )
}
export default SellerReq;