import styled from "styled-components";


type FlexProps = {
    grow?: number,
    direction?: 'row' | 'column',
    justifyContent?: 'space-between' | 'space-around' | 'space-evenly'| 'flex-end' | 'flex-start' | 'center',
    alignItems?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly',
    wrap?: 'wrap' | 'wrap-reverse' | 'nowrap',
    width?: string,
    height?: string,
  }

  interface FlexboxPrors extends  FlexProps{
    mt?: string,
    mb?: string
  }

export const Flex = styled.div<FlexProps>`
    display: flex;
    position: relative;
    flex-direction: ${props =>  props.direction ? props.direction : 'row'};
    justify-content: ${props =>  props.justifyContent ? props.justifyContent : 'start'};
    align-items: ${props =>  props.alignItems ? props.alignItems : 'center'};
    flex-grow: ${props =>  props.grow ? props.grow : null};
    flex-wrap: ${props => props.wrap ? props.wrap : 'nowrap'};
    height: ${props =>  props.height ? props.height : 'auto'};
    width:  ${props =>  props.width ? props.width : 'auto'};
`

export const FlexBox = styled.div<FlexboxPrors>`

  margin-top: ${props => props.mt ? props.mt : '0'};
  margin-bottom: ${props => props.mb ? props.mb : '0'};
`