import React, { useState, useEffect } from "react";
import { gql, useQuery } from '@apollo/client';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { searchdata } from "./pages/home/BannerSection";
const SEARCHPRODUCT = gql`
query($key:String){
    searchProduct(key:$key, first:40){
      edges{
        node{
          id
          name
          slug
          hsCode
          category{
            id
            name
            slug
            children{
              id
              name
              slug
            }
            parent{
              id
              name
              slug
            }
          }
        }
      }
    }
  }
  `;
const SearchProduct: React.FC = () => {
  const [HsCodeKey, setHsCodeKey] = useState('');
  const [searchVal, setSearchVal] = useState('');
  const [IntVal, setIntVal] = useState("alpha");
  const [old, setOld] = useState('');
  const history = useHistory();
  const { data, loading, refetch } = useQuery(SEARCHPRODUCT, {
    variables: {
      key: HsCodeKey
    }
  });
  const goToProductPage = (value, sarch) => {
    history.replace("/product/" + value + "/" + sarch);
  }
  useEffect(() => {
    old && goToProductPage("search", old)
    old && setHsCodeKey(old)
  }, [old]);
  searchdata.key=HsCodeKey;
  useEffect(() => { refetch() }, [HsCodeKey]);
  return (
    <>
      <Autocomplete
        aria-label="Product Name"
        id="combo-box-demo"
        options={data?.searchProduct?.edges ? data?.searchProduct?.edges : []}
        getOptionLabel={(option) =>
          IntVal == "num" ? (option?.node?.hsCode ? option?.node?.hsCode : searchVal) : IntVal == "alpha" ? (option?.node?.name.toLowerCase() ? option?.node?.name.toLowerCase() : searchVal) : ""
        }
        freeSolo={true}
        renderOption={(option) =>
          IntVal == "num" ? option?.node?.hsCode?.concat(" (").concat(option?.node?.name.toLowerCase()).concat(")") : IntVal == "alpha" ? option?.node?.name.toLowerCase() : ''
        }
        onInputChange={(e) => {
          setSearchVal("");
          if (e) {
            if (e.target) {
              if ((e.target as HTMLInputElement)?.value?.length > 0) {
                if (/^[0-9\s]*$/.test((e.target as HTMLInputElement).value)) {
                  setIntVal("num")
                }
                else {
                  setIntVal("alpha")
                }
                setHsCodeKey((e.target as HTMLInputElement).value)
              }
            }
          }
        }}
        onChange={(event: object, values: any, reason: string) => {
          setSearchVal("");
          if (values?.node?.name) {
            setOld(IntVal == "num" ? values?.node?.hsCode : values?.node?.name)
          }
        }}
        onClose={() => {
          old && goToProductPage('search', old)
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            if ((e.target as HTMLInputElement).value.length > 0) {
              setSearchVal((e.target as HTMLInputElement).value)
              goToProductPage('search', (e.target as HTMLInputElement).value)
            }
          }
        }}
        renderInput={(params) =>
          <TextField {...params}
            placeholder="Search product by name or HS code"
          />
        }
      />
    </>
  )
};

export default SearchProduct;