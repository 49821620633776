import React from 'react'
const LogoSlider = () => {
    return (
        <div className="logo_slider_home">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="d-sm-none d-xs-block">
                            <h4 style={{ fontSize: 14, fontWeight: 600, padding: "0 20px" }}>Sponsor</h4>
                        </div>
                        <div className="logo_img_center d-xs-none">
                            <img src={require('../../../assets/images/logo/newlogo04.png')} />
                            <img src={require('../../../assets/images/logo/newlogo03.png')} />
                            <img src={require('../../../assets/images/logo/newlogo05.png')} />
                            <img className="larg" src={require('../../../assets/images/logo/newlogo01.png')} />
                        </div>
                        <div className="logo_img_center d-xs-block d-sm-none" style={{ width: "70%", margin: "0 auto" }}>
                            <div className='row'>
                                <div className="col-xs-6">
                                    <img src={require('../../../assets/images/logo/newlogo04.png')} />
                                </div>
                                <div className="col-xs-6">
                                    <img src={require('../../../assets/images/logo/newlogo03.png')} />
                                </div>
                                <div className="col-xs-6">
                                    <img src={require('../../../assets/images/logo/newlogo05.png')} />
                                </div>
                                <div className="col-xs-6">
                                    <img src={require('../../../assets/images/logo/newlogo01.png')} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default LogoSlider;


