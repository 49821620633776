import styled from "styled-components";
export const Wrapper = styled.div`
    background:  ${props => props.theme.colors.background.nav};
    height: 90px;
    display: flex;
    flex-direction: row;

    ul{
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        list-style: none;
        width: 450px;
        position: relative;
        li{
            display: flex;
            align-items: center;
            
        }
    }

    a{
        color: ${props => props.theme.colors.text.nav};
        text-decoration: none;
    }

    .auth__item {
        display: flex;
        align-self: center;
    }
    .navbar {
        overflow: hidden;
        background-color: #333;
        font-family: Arial, Helvetica, sans-serif;
      }
      
      .navbar a {
        float: left;
        font-size: 16px;
        color: white;
        text-align: center;
        padding: 14px 16px;
        text-decoration: none;
      }
      
      .dropdwn {
        float: left;
        overflow: hidden;
      }
      
      .dropdwn .dropbtn {
        font-size: 16px;  
        border: none;
        outline: none;
        padding: 14px 16px;
        background-color: white;
        font: inherit;
        margin: 0;
      }
      
      .navbar a:hover, .dropdwn:hover .dropbtn {
        background-color: white;
      }
      
      .dropdown-content {
        display: none;
        position: absolute;
        background-color: #f9f9f9;
        width: 100%;
        left: 0;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
      }
      
      .dropdown-content .header {
        background: red;
        padding: 16px;
        color: white;
      }
      
      .dropdwn:hover .dropdown-content {
        display: block;
      }
      
        .column {
        float: left;
        width: 33.33%;
        padding: 10px;
        background-color: #ccc;
        height: 250px;
      }
      
      .column a {
        float: none;
        color: black;
        padding: 16px;
        text-decoration: none;
        display: block;
        text-align: left;
      }
      
      .column a:hover {
        background-color: #ddd;
      }
 
      .row:after {
        content: "";
        display: table;
        clear: both;
      }
      
      /* Responsive layout - makes the three columns stack on top of each other instead of next to each other */
      @media screen and (max-width: 600px) {
        .column {
          width: 100%;
          height: auto;
        }
      }
`