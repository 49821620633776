import { TextField, Button } from '@material-ui/core';
import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
const SupplierSourcing = gql`
mutation contactUsCreate(
    $name:String!
    $email:String!
    $comment:String!
) {
    contactUsCreate(
        input :{
      name: $name
      comment: $comment
      email: $email
    }){
        contactUs{
            name
            comment
            email
          }
          contactUsErrors{
            message
            field
            code
          }
    }
  }
`;
const Contact = () => {
    window.scroll(0, 0)
    document.title = "Contact-Us";
    const req = "This field required";
    const blank = "";
    const invalidemail = "Invalid email address";
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const [formdata, setData] = useState({
        name: '',
        email: '',
        Comment: ''
    })
    const [dataerror, setError] = useState({
        name: '',
        email: '',
        Comment: ''
    })
    const [send, setSend] = useState(false)
    const success = () => {
        window.scroll(0, 0)
        setSend(true)
    }
    const [SupplierSourcingdata] = useMutation(SupplierSourcing);
    const respone = () => {
        const handleSubmit = async () => {
            const response = await SupplierSourcingdata({
                variables: {
                    name: formdata.name,
                    email: formdata.email,
                    comment: formdata.Comment,
                }
            });
            response && response?.data?.contactUsCreate?.contactUsErrors?.length === 0 && success()
        }
        handleSubmit()
    }
    const submit = () => {
        setError({
            ...dataerror,
            Comment: formdata.Comment.length <= 0 ? req : blank,
            name: formdata.name.length <= 0 ? req : blank,
            email: formdata.email.length <= 0 ? req : (formdata.email.match(mailformat) ? blank : invalidemail)
        })
        formdata.name.length > 0 && formdata.Comment.length > 0 && formdata.email.length > 0 && formdata.email.match(mailformat) && respone()
    }
    return (
        <div className="container">
            <div className='row'>
                <div className='col-lg-4 col-sm-6 col-xs-12'>
                    <div className='contactus-form'>
                        <div className='contactus-heading'>
                            <h2 style={{ color: "#7A38DA", fontWeight: 600 }}>koytola</h2>
                            <h3 style={{ margin: 0 }}>‘Wholesale Marketplace’</h3>
                            <h5>Contact-us</h5>
                        </div>
                        {send ?
                            <div style={{ borderRadius: "15px", border: "1px solid #7A38DADE", padding: '15px 25px', color: '#000', fontWeight: 600, fontSize: 13 }}>
                                thank you!
                                <br />
                                we’ll be contacting you soon.
                            </div> : <div className='contact-form'>
                                <div style={{ position: 'relative' }}>
                                    <TextField
                                        onChange={(e) => {
                                            setData({
                                                ...formdata,
                                                name: e.target.value
                                            })
                                            setError({
                                                ...dataerror,
                                                name: e.target.value.length <= 0 ? req : blank
                                            })
                                        }}
                                        fullWidth
                                        type="text"
                                        placeholder='Name '
                                        error={dataerror.name ? true : false}
                                        variant="outlined"
                                    />
                                    <span style={{ position: "absolute", left: 20, bottom: 10, color: "red" }}>{dataerror.name}</span>
                                </div>
                                <div style={{ position: 'relative' }}>
                                    <TextField
                                        onChange={(e) => {
                                            setData({
                                                ...formdata,
                                                email: e.target.value
                                            })
                                            setError({
                                                ...dataerror,
                                                email: e.target.value.length <= 0 ? req : (e.target.value.match(mailformat) ? blank : invalidemail)
                                            })
                                        }}
                                        fullWidth
                                        type="text"
                                        placeholder='Email '
                                        error={dataerror.email ? true : false}

                                        variant="outlined"
                                    />
                                    <span style={{ position: "absolute", left: 20, bottom: 10, color: "red" }}>{dataerror.email}</span>
                                </div>
                                <div style={{ position: 'relative' }}>
                                    <TextField
                                        onChange={(e) => {
                                            setData({
                                                ...formdata,
                                                Comment: e.target.value
                                            })
                                            setError({
                                                ...dataerror,
                                                Comment: e.target.value.length <= 0 ? req : blank
                                            })
                                        }}
                                        fullWidth
                                        rows={4}
                                        placeholder='Comment '
                                        multiline
                                        error={dataerror.Comment ? true : false}

                                        variant="outlined"
                                    />
                                    <span style={{ position: "absolute", left: 20, bottom: 10, color: "red" }}>{dataerror.Comment}</span>
                                </div>
                                <Button variant="outlined" className='send-btn' onClick={submit}>
                                    send
                                </Button></div>}
                    </div>
                </div>
                <div className='col-lg-offset-2 col-sm-offset-1 col-lg-6 col-sm-5 col-xs-12'>
                    <div className='contact-rightpannel'>
                        <div className='contact-map'>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3011.1850004068574!2d29.094397014914065!3d40.99932382792556!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cac9673b8c4013%3A0x56aaa5919c18edf6!2sKolektif%20House%20Ata%C5%9Fehir!5e0!3m2!1sen!2sin!4v1639561967738!5m2!1sen!2sin" width="100%" height="300" style={{ border: "none" }} ></iframe>
                        </div>
                        <p style={{ margin: 0, fontSize: 14 }}>Address :</p>
                        <h5 style={{ marginTop: 0, marginBottom: 20, fontSize: 16, color: '#000' }}>
                            Kollektif House Atasehir-Istanbul 34750
                        </h5>
                        <p style={{ margin: 0, fontSize: 14 }}>Contact us :</p>
                        <h5 style={{ margin: 0, fontSize: 16, color: '#000' }}>export@koytola.com</h5>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Contact;