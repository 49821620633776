import React, { useState } from 'react'
import { Card, CircularProgress,  Grid, makeStyles } from '@material-ui/core'
import { Maincategoryvalue } from '../Header';
import { gql, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { hideshowvalue } from '../Header';
const SubcategoriesData = gql`
query category(
  $categoryId:ID!
  ){
    category(categoryId:$categoryId) {
        id
        name
        slug
        productCount
        backgroundImage {
            url
          }
        children {
          id
          name
          slug
          backgroundImage {
            url
          }
        productCount
        }
  }
}
`;
export interface categorydata {
    id: string,
}
export var categoryvalue: categorydata = {
    id: '',
};
export interface blankhscode {
    hscodekey: string,
  }
  export var hscodeblankvalue: blankhscode = {
    hscodekey: '',
  };
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    inline_tags_aria: {
        border: "solid 1px #ddd",
        padding: "50px",
        textAlign: "center",
        boxShadow: "none"
    },
    listItems: {
        border: "solid 1px #ddd",
        borderRadius: '50px',
        color: '#7A38DA'
    }
}))
export const SubCategory = () => {
    var id = Maincategoryvalue.id;
    const [List, setList] = useState(true);
    const [HideData, setHideData] = useState(false);
    const history = useHistory();
    const { loading, error, data, refetch } = useQuery(SubcategoriesData, {
        variables: {
            categoryId: id
        }
    });
    if (loading) return <div className="loading_seccestion"><CircularProgress /></div>
    if (id && List) {
        refetch();
        setList(false);
    }
    const onclick = (value: any) => {
        categoryvalue.id = value;
        history.push("/product/" + value);
        setHideData(true)
        hideshowvalue.show = true;
    }
    return (
        <>
            {id && !hideshowvalue.show &&
                <Card className="inline_tags_aria container">
                    <Grid item xs={6}>
                    </Grid>
                    <Grid style={{ padding: "0px 35px" }} item xs={2}>
                        <img src={data?.category?.backgroundImage?.url} /><p onClick={(e) => (
                            onclick(data?.category?.slug)
                        )}>{data?.category?.name}</p>
                    </Grid>
                    <Grid item xs={6} container spacing={1}>
                    {data?.category?.children?.map((res: any, index: any) => (
                       res?.productCount > 0 &&
                        <Grid item xs={4}>
                            <p className="listItems" onClick={(e) => (
                                onclick(res?.slug)
                            )}>{res?.name}</p>
                        </Grid> 
                    ))}</Grid>
                </Card>
            }
        </>
    )
}
export default SubCategory;


