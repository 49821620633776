import React, { useEffect } from 'react'
import { BannerSection } from '../components/pages/home/BannerSection';
import LogoSlider from '../components/pages/home/LogoSlider';
import Videoslider from '../components/pages/home/Videoslider';
import WelcomeKoytola from '../components/pages/home/WelcomeKoytola';
import TopProduct from '../components/pages/home/TopProducts';
import { DialogTitle } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MoreProduct from '../components/pages/home/Moreproduct';
import LatestPlateform from '../components/pages/home/LatestPlateform';
import NewsSection from '../components/pages/home/NewsSection';
import Logistics from '../components/pages/home/Logistics';
import WhyKoytola from '../components/pages/home/WhyKoytola';
import TrackShippment from '../components/pages/home/TrackShippment';
import Whoweare from '../components/pages/home/Whoweare';
import JoinKoytola from '../components/pages/home/JoinKoytola';
import { Container } from '../ui/container';
import WhatElse from '../components/pages/home/WhatElse';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { popdata } from '../App';
export const Home = () => {
    window.scrollTo(0, 0)
    document.title = "Koytola -  Wholesale Marketplace";
    const [demo, setDemo] = React.useState(true);
    const handleClose = () => {
        popdata.open = false;
        setDemo(res => !res);
    };
    useEffect(() => {
        loadImage()
    }, [])
    const loadImage = () => {
        if (!popdata.open && !popdata.click) {
            setTimeout(() => {
                popdata.open = true;
                setDemo(res => true)
                console.log("ok")
            }, 240000);
        }
    }
    !popdata.open && !popdata.click && loadImage()
    const opendashboardd = () => {
        popdata.click = true;
        popdata.open = false;
        setDemo(res => false)
        window.open(
            'https://panel.koytola.com',
            '_blank'
        );
    }
    return (
        <>
            <Container height="100%" width="100%">
                <BannerSection />
                <div className='d-xs-block d-sm-none'>
                    <MoreProduct />
                </div>
                {/* <Videoslider /> */}
                <div className='d-xs-none'>
                    <WelcomeKoytola />
                </div>
                <TopProduct />
                <div className='d-xs-none'>
                    <MoreProduct />
                    <LatestPlateform />
                </div>
                <Logistics />
                <WhyKoytola />
                <div className='d-xs-none'>
                    <TrackShippment />
                    <WhatElse />
                </div>
                <Whoweare />
                <NewsSection />
                <div className='d-xs-none'>
                    <JoinKoytola />
                </div>
                <LogoSlider />
            </Container>
            <Dialog
                open={popdata.open}
                onClose={handleClose}
            >
                <DialogTitle style={{ textAlign: "right", }}><CloseIcon style={{ cursor: "pointer" }} fontSize='large' onClick={() => {
                    handleClose()
                }} /> </DialogTitle>
                <DialogContent>
                    <img style={{ cursor: "pointer" }} src={require('../assets/images/ihracatabasla.jpg')} onClick={() => {
                        opendashboardd()
                    }} />
                </DialogContent>
            </Dialog>
        </>
    )
}
