import React, { useState } from 'react';
import Collapse from '@material-ui/core/Collapse';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import IconButton from '@material-ui/core/IconButton';
const Faq = () => {
    window.scrollTo(0, 0)
  document.title="Frequently Asked Questions";
    const [expanded, setExpanded] = useState(1);
    return (
        <div className="container">
            <div className="faq_contant">
                <div className="accordian_header">
                    <h5 >What is an online wholesale market?</h5>
                    {expanded === 1 ? <IconButton onClick={() => setExpanded(0)} ><RemoveIcon /> </IconButton> : <IconButton onClick={() => setExpanded(1)}><AddIcon /> </IconButton>}
                </div>
                <div className="accordian_contant">
                    <Collapse in={expanded === 1}>
                        The online wholesale market means Multiple suppliers and manufacturers create their product profiles on an online platform. Buyers who want to buy wholesale can directly connect and buy from listed companies. Earlier people had to travel to different countries. Nowadays you can find a reliable supplier that can ship you products.
                    </Collapse>
                </div>
                <div className="accordian_header">
                    <h5 >What are the advantages of the B2B wholesale marketplace?</h5>
                    {expanded === 2 ? <IconButton onClick={() => setExpanded(0)} ><RemoveIcon /> </IconButton> : <IconButton onClick={() => setExpanded(2)}><AddIcon /> </IconButton>}
                </div>
                <div className="accordian_contant">
                    <Collapse in={expanded === 2}>
                        Imagine that you want to buy a wholesale pencil for selling at your shop. For that, you were supposed to check with different sellers to get the best price and see the variety of pencils. But now, many manufacturers and suppliers have online stores under one roof that is called the wholesale marketplace.
                    </Collapse>
                </div>
                <div className="accordian_header">
                    <h5 >Which platforms sell wholesale to the USA?</h5>
                    {expanded === 3 ? <IconButton onClick={() => setExpanded(0)} ><RemoveIcon /> </IconButton> : <IconButton onClick={() => setExpanded(3)}><AddIcon /> </IconButton>}
                </div>
                <div className="accordian_contant">
                    <Collapse in={expanded === 3}>
                        Koytola is a wholesale platform for made in Turkey products.
                        Manufacturers or suppliers can ship the order to their b2b buyers in the USA. Wholesale buying from Turkey is very cost-efficient.
                    </Collapse>
                </div>
                <div className="accordian_header">
                    <h5 >How to find a Private Label Manufacturer? </h5>
                    {expanded === 4 ? <IconButton onClick={() => setExpanded(0)} ><RemoveIcon /> </IconButton> : <IconButton onClick={() => setExpanded(4)}><AddIcon /> </IconButton>}
                </div>
                <div className="accordian_contant">
                    <Collapse in={expanded === 4}>
                        Koytola is a wholesale platform for made in Turkey products.
                        Manufacturers or suppliers offer private labels for your brand.
                        Minimum Order Quantity (MOQ) is the main factor for creating private labels. Manufacturers can ask you to order Min 100-1000 pieces if you want your own label on the products
                    </Collapse>
                </div>
            </div>
        </div>
    )
}
export default Faq;