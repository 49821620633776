import React, { useState } from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Button, Card, CardContent, Checkbox, Container,  FormControlLabel, Grid, Link } from '@material-ui/core';
import { useParams } from 'react-router'
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Avatar from '@material-ui/core/Avatar';
import { red } from '@material-ui/core/colors';
import Rating from '@material-ui/lab/Rating';
import { gql, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import ShouldBuy from './ShouldBuy';
interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: any;
    value: any;
}
export interface ProductFilterInput {
    search?: string | null;
    rosetter: string | null;
}
const SubcategoriesData = gql`
query category(
  $slug:String
  $before: String
  $after: String
  $after2: String
  $first: Int
 $filter: ProductFilterInput
 $filter2:ProductFilterInput
  ){
    category(slug:$slug) {
        id
        name
        backgroundImage {
            url
          }
          parent {
            id
            name
          }
        products(after: $after
            before: $before
            first: $first
            filter: $filter
           ) {
            edges {
              node {
                id
                slug
                name
                tags
                category{
                    name
                  }
                hsCode
                currency
                unitNumber
                unit
                totalReview
                totalRatting
                unitPrice
                rosetter{
                    name
                    id
                  }
                certificateType{
                    id
                    name
                }
                company{
                  name
                  id
                  slug
                  type
                  logo{
                    url
                  }
                  city
                  address{
                    city
                    country{
                      code
                      country
                    }
                    cityArea
                  }
                  representativeName
                  photo{
                    url
                  }
                  representative{
                     name
                     photo{
                      url
                    }
                  }
                }
                images{
                  image{
                    url
                  }
                }
              }
            }
            pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
              }
          }    
        productCount
  }
  products(after: $after2
    before: $before
    first: $first
    filter: $filter2
   ) {
    edges {
      node {
        id
        slug
        name
        tags
        category{
            name
          }
        hsCode
        currency
        unitNumber
        unit
        totalReview
        totalRatting
        unitPrice
        rosetter{
            name
            id
          }
        certificateType{
            id
            name
        }
        company{
          name
          id
          slug
          type
          logo{
            url
          }
          city
          address{
            city
            country{
              code
              country
            }
            cityArea
          }
          representativeName
          photo{
            url
          }
          representative{
             name
             photo{
              url
            }
          }
        }
        images{
          image{
            url
          }
        }
      }
    }
    pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
  }
}
`;
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={5}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
function a11yProps(index: any) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
    },
    avatar: {
        backgroundColor: red[500],
    },
    media: {
        height: 400,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    rating: {
        marginTop: '20px'
    },
    FavoriteIconClass: {
        position: "relative",
    },
    Favorite_icon: {
        position: "absolute",
        right: "10px",
        top: "10px",
    },
    Save_icon: {
        position: "absolute",
        right: "10px",
        top: "40px",
    },
    tab_header: {
        backgroundColor: "transparent",
        boxShadow: "none",
        borderTop: "solid 1px #ddd",
        borderBottom: "solid 1px #ddd"
    },
    tabText: {
        padding: "15px",
        fontWeight: 700
    },
    inline_tags_aria: {
        border: "solid 1px #ddd",
        padding: "50px",
        textAlign: "center",
        boxShadow: "none"
    },
    tag_label: {
        border: "0.6px solid #bfbfbf",
        borderRadius: "50px",
        minWidth: "150px",
        textTransform: "capitalize",
        marginRight: "30px",
        padding: "0 15px 0 4px",
        marginBottom: "20px"
    },
    mr30: {
        marginRight: "30px",
        display: "inline-block"
    },
    zkitProductaria: {
        padding: "30px 0"
    },
}));
export default function Product() {
    document.title="Products";
    let ProductInfo: any = useParams();
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(1);
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };
    const Prices = [
        { name: "0-5000", id: { gte: '0', lte: '5000' } }, { name: "5000-10000", id: { gte: '5000', lte: '10000' } }, { name: "10000-15000", id: { gte: '10000', lte: '15000' } }, { name: "above 15000", id: { gte: '15000', lte: '0' } }
    ];
    const Shimpent = [
        { name: "needs time to ship" }, { name: "ready to ship" }
    ];
    const [CheckedArray, setCheckedArray] = useState([] as any);
    var CheckedArray1: any = [];
    const [CertificateArray, setCertificateArray] = useState([] as any);
    var CertificateArray1: any = [];
    const [PricesValue, setPricesValue] = useState({} as any);
    const [PricesName, setPricesName] = useState('');
    const [ShimpentArray, setShimpentArray] = useState([] as any);
    const [CertifiList, setCertifiList] = useState([] as any);
    const [RosterList, setRosterList] = useState([] as any);
    const [RatingValue, setRatingValue] = useState(null);
    const [OldId, setOldId] = React.useState('' as any);
    const [Oldsearch, setOldsearch] = React.useState('' as any);
    const [OldAfterId, setOldAfterId] = React.useState(null as any);
    const [OldAfterId2, setOldAfterId2] = React.useState(null as any);
    const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();
    var filteredData: any = [];
    const [after2, setAfterId2] = useState(null);
    const [AfterId, setAfterId] = useState(null);
    const history = useHistory();
    const goToCompanyPage = (value: any) => {
        history.push("/company-profile/" + value);
    }
    const goToProductDetailPage = (value: any) => {
        history.push("/product-details/" + value);
    }
    const [List, setList] = useState(true);
    const { loading, error, data, refetch } = useQuery(SubcategoriesData, {
        variables: {
            first: 6,
            after: AfterId,
            after2,
            slug: ProductInfo.id,
            filter: {
                rosetter: CheckedArray,
                certificateType: CertificateArray,
                price: PricesValue,
                deliveryTimeOption: ShimpentArray,
                rating: RatingValue,
            },
            filter2: {
                rosetter: CheckedArray,
                certificateType: CertificateArray,
                price: PricesValue,
                rating: RatingValue,
                deliveryTimeOption: ShimpentArray,
                search: ProductInfo.search ? ProductInfo.search : ''
            }
        }
    });
    filteredData = data?.category?.products?.edges;
    var [ProductList, setProductList] = React.useState([] as any);
    function firstcall() {
        var rList: any = [];
        var cList: any = [];
        const looprun = async () => {
            if (ProductInfo.search) {
                setProductList(data?.products?.edges)
                for (var i = 0; i < data?.products?.edges?.length; i++) {
                    for (var j = 0; j < data?.products?.edges[i]?.node?.certificateType?.length; j++) {
                        cList.push(data?.products?.edges[i]?.node?.certificateType[j]?.name)
                    }
                    for (var j = 0; j < data?.products?.edges[i]?.node?.rosetter?.length; j++) {
                        rList.push(data?.products?.edges[i]?.node?.rosetter[j]?.name)
                    }
                }
            }
            if (!ProductInfo.search) {
                if (data?.category?.products?.edges) {
                    setProductList(data?.category?.products?.edges)
                    for (var i = 0; i < data?.category?.products?.edges?.length; i++) {
                        for (var j = 0; j < data?.category?.products?.edges[i]?.node?.certificateType?.length; j++) {
                            cList.push(data?.category?.products?.edges[i]?.node?.certificateType[j]?.name)
                        }
                        for (var j = 0; j < data?.category?.products?.edges[i]?.node?.rosetter?.length; j++) {
                            rList.push(data?.category?.products?.edges[i]?.node?.rosetter[j]?.name)
                        }
                    }
                }
            }
            setCertifiList(cList.filter((c, index) => cList.indexOf(c) === index))
            setRosterList(rList.filter((c, index) => rList.indexOf(c) === index))
        }
        looprun()
        setList(false)
    }
    function resetdata(datalist) {
        setProductList(datalist)
        var rList: any = [];
        var cList: any = [];
        for (var i = 0; i < datalist?.length; i++) {
            for (var j = 0; j < datalist[i]?.node?.certificateType?.length; j++) {
                cList.push(datalist[i]?.node?.certificateType[j]?.name)
            }
            for (var j = 0; j < datalist[i]?.node?.rosetter?.length; j++) {
                rList.push(datalist[i]?.node?.rosetter[j]?.name)
            }
        }
        setCertifiList(cList.filter((c, index) => cList.indexOf(c) === index))
        setRosterList(rList.filter((c, index) => rList.indexOf(c) === index))
    }
    const loaddata = async (datalist) => {
        await setProductList([...ProductList, ...datalist])
        var rList: any = RosterList;
        var cList: any = CertifiList;
        for (var i = 0; i < datalist?.length; i++) {
            for (var j = 0; j < datalist[i]?.node?.certificateType?.length; j++) {
                cList.push(datalist[i]?.node?.certificateType[j]?.name)
            }
            for (var j = 0; j < datalist[i]?.node?.rosetter?.length; j++) {
                rList.push(datalist[i]?.node?.rosetter[j]?.name)
            }
        }
        await setCertifiList(cList.filter((c, index) => cList.indexOf(c) === index))
        await setRosterList(rList.filter((c, index) => rList.indexOf(c) === index))
    }
    if (error) return <p className="errorlo">Error: Something is wrong! </p>
    const reset = async () => {
        await setAfterId(null)
        await setAfterId2(null)
        await setProductList([])
        await setCertifiList([])
        await setRosterList([])
        const rrr = await refetch()
        !ProductInfo.search && rrr && rrr?.data?.category?.products?.edges && resetdata(rrr?.data?.category?.products?.edges)
        ProductInfo.search && rrr && rrr?.data?.products?.edges && resetdata(rrr?.data?.products?.edges)
    }
    List && data && firstcall()
    const reset2 = async () => {
        await setOldId(ProductInfo.id)
        await setCheckedArray([]);
        await setCertificateArray([]);
        await setPricesName('');
        await setPricesValue({});
        await setShimpentArray([]);
        await setRatingValue(null);
        reset()
    }
    const reset3 = async () => {
        await setOldsearch(ProductInfo.search)
        await setCheckedArray([]);
        await setCertificateArray([]);
        await setPricesName('');
        await setPricesValue({});
        await setShimpentArray([]);
        await setRatingValue(null);
        reset()
    }
    const onload = async () => {
        await setOldAfterId(AfterId)
        const rrr = await refetch()
        !ProductInfo.search && rrr && rrr?.data?.category?.products?.edges && loaddata(rrr?.data?.category?.products?.edges)
        ProductInfo.search && rrr && rrr?.data?.products?.edges && loaddata(rrr?.data?.products?.edges)
    }
    const onload2 = async () => {
        await setOldAfterId2(after2)
        const rrr = await refetch()
        !ProductInfo.search && rrr && rrr?.data?.category?.products?.edges && loaddata(rrr?.data?.category?.products?.edges)
        ProductInfo.search && rrr && rrr?.data?.products?.edges && loaddata(rrr?.data?.products?.edges)
    }
    OldAfterId != AfterId && onload()
    OldAfterId2 != after2 && onload2()
    OldId != ProductInfo.id && reset2()
    Oldsearch != ProductInfo.search && reset3()
    const clearFilters = async () => {
        await setCheckedArray([]);
        await setCertificateArray([]);
        await setPricesName('');
        await setPricesValue({});
        await setShimpentArray([]);
        await setRatingValue(null);
        reset()
    }
    return (
        <Container className="fillter_outer_container">
            <div className="fillter_main">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <AppBar position="static" className={classes.tab_header}>
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    centered
                                >
                                    <Tab className={classes.tabText} label="Rosettes" {...a11yProps(0)} />
                                    <Tab className={classes.tabText} label="Price" {...a11yProps(1)} />
                                    <Tab className={classes.tabText} label="Shipment" {...a11yProps(2)} />
                                    <Tab className={classes.tabText} label="Rating" {...a11yProps(3)} />
                                </Tabs>
                            </AppBar>
                            <TabPanel value={value} index={0} dir={theme.direction}>
                                <Card className="fillter_inner">
                                    {RosterList?.map((res, i) =>
                                        <FormControlLabel className={classes.tag_label}
                                            control={
                                                <Checkbox onChange={(e) => {
                                                    if (e.target.checked) {
                                                        CheckedArray1 = [...CheckedArray];
                                                        CheckedArray1.push(res);
                                                        const setfilter1 = async () => {
                                                            await setCheckedArray(CheckedArray1)
                                                            reset()
                                                        }
                                                        setfilter1()
                                                    }
                                                    else if (!e.target.checked) {
                                                        CheckedArray1 = [...CheckedArray];
                                                        var a = CheckedArray1.indexOf(res)
                                                        CheckedArray1.splice(a, 1)
                                                        const setfilter1 = async () => {
                                                            await setCheckedArray(CheckedArray1)
                                                            reset()
                                                        }
                                                        setfilter1()
                                                    }
                                                }}
                                                    checked={CheckedArray.indexOf(res) > -1}
                                                />
                                            }
                                            label={res?.toLowerCase()}
                                        />
                                    )}
                                </Card>
                            </TabPanel>
                            <TabPanel value={value} index={1} dir={theme.direction}>
                                <Card className="fillter_inner">
                                    {Prices?.map((res, i) =>
                                        <FormControlLabel className={classes.tag_label}
                                            control={
                                                <Checkbox onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setPricesName(res.name)
                                                        const setfilter1 = async () => {
                                                            await setPricesValue(res.id)
                                                            reset()
                                                        }
                                                        setfilter1()
                                                    } else if (!e.target.checked) {
                                                        setPricesName('')
                                                        const setfilter1 = async () => {
                                                            await setPricesValue({})
                                                            reset()
                                                        }
                                                        setfilter1()
                                                    }
                                                }}
                                                    checked={res.name === PricesName}
                                                />
                                            }
                                            label={res?.name?.toLowerCase()}
                                        />
                                    )}
                                </Card>
                            </TabPanel>
                            <TabPanel value={value} index={2} dir={theme.direction}>
                                <Card className="fillter_inner">
                                    {Shimpent?.map((res, i) =>
                                        <FormControlLabel className={classes.tag_label}
                                            control={
                                                <Checkbox onChange={(e) => {
                                                    if (e.target.checked) {
                                                        const setfilter1 = async () => {
                                                            await setShimpentArray([res.name])
                                                            reset()
                                                        }
                                                        setfilter1()
                                                    } else if (!e.target.checked) {
                                                        const setfilter1 = async () => {
                                                            await setShimpentArray([])
                                                            reset()
                                                        }
                                                        setfilter1()
                                                    }
                                                }}
                                                    checked={ShimpentArray.indexOf(res.name) > -1}
                                                />
                                            }
                                            label={res?.name?.toLowerCase()}
                                        />
                                    )}
                                </Card>
                            </TabPanel>
                            <TabPanel value={value} index={3} dir={theme.direction}>
                                <Card className="fillter_inner ratting">
                                    <Rating name="simple-controlled" value={RatingValue} precision={1} size="large"
                                        onChange={(e, newValue) => {
                                            var a: any = newValue;
                                            const setfilter1 = async () => {
                                                await setRatingValue(a)
                                                reset()
                                            }
                                            setfilter1()
                                        }} />
                                </Card>
                            </TabPanel>
                            <Card className="selected_fillter_outer">
                                <div className="slect_fillter_header">
                                    <p>selected filters</p>
                                    <button onClick={clearFilters}>clear filters</button>
                                </div>
                                <CardContent className="sx-p0">
                                    <div className="selected_fillter_inner">
                                        {CheckedArray.map(res =>
                                            <FormControlLabel className={classes.tag_label}
                                                control={
                                                    <Checkbox
                                                        checked={CheckedArray.indexOf(res) > -1}
                                                        onChange={(e) => {
                                                            if (!e.target.checked) {
                                                                var ca = [...CheckedArray];
                                                                var a = ca.indexOf(res)
                                                                ca.splice(a, 1)
                                                                const setfilter1 = async () => {
                                                                    await setCheckedArray(ca)
                                                                    reset()
                                                                }
                                                                setfilter1()
                                                            }
                                                        }}
                                                    />
                                                }
                                                label={res}
                                            />
                                        )}
                                        {PricesName && <FormControlLabel className={classes.tag_label}
                                            control={
                                                <Checkbox
                                                    checked
                                                    onChange={(e) => {
                                                        if (!e.target.checked) {
                                                            const setfilter1 = async () => {
                                                                await setPricesName('')
                                                                await setPricesValue({})
                                                                reset()
                                                            }
                                                            setfilter1()
                                                        }
                                                    }}
                                                />
                                            }
                                            label={PricesName}
                                        />}
                                        {CertificateArray.map(res =>
                                            <FormControlLabel className={classes.tag_label}
                                                control={
                                                    <Checkbox checked={CertificateArray.indexOf(res) > -1}
                                                        onChange={(e) => {
                                                            if (!e.target.checked) {
                                                                var ca = [...CertificateArray];
                                                                var a = ca.indexOf(res)
                                                                ca.splice(a, 1)
                                                                const setfilter1 = async () => {
                                                                    await setCertificateArray(ca)
                                                                    reset()
                                                                }
                                                                setfilter1()
                                                            }
                                                        }} />
                                                }
                                                label={res}
                                            />)}
                                        {ShimpentArray.map(res =>
                                            <FormControlLabel className={classes.tag_label}
                                                control={
                                                    <Checkbox checked={ShimpentArray.indexOf(res) > -1}
                                                        onChange={(e) => {
                                                            if (!e.target.checked) {
                                                                const setfilter1 = async () => {
                                                                    await setShimpentArray([])
                                                                    reset()
                                                                }
                                                                setfilter1()
                                                            }
                                                        }} />
                                                }
                                                label={res}
                                            />)}
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.root}>
                <div className="zkitProductaria_list"
                >
                    <Grid container spacing={2}>
                        {ProductList?.map((res: any, index) => (
                            <>
                                <Grid className="product_grid_list" item xs={4}>
                                    <Card className="zkitProductbox">
                                        <CardHeader className="zkitProdheader"
                                            avatar={
                                                <Avatar aria-label="recipe" className="avatar"><img src={res?.node?.company?.logo?.url} /></Avatar>
                                            }
                                            action={<>
                                                <Button className="btn-follow">View Company</Button>
                                            </>
                                            }
                                            title={res?.node?.company?.name}
                                            onClick={(e) => goToCompanyPage(res?.node?.company?.slug)}
                                            subheader={<>
                                                <LocationOnIcon fontSize="small" />
                                                {res?.node?.company?.city}</>
                                            }
                                        />
                                        <div>
                                            <Grid className="FavoriteIconClass">
                                                <div className="listing_img_box" onClick={(e) => goToProductDetailPage(res?.node?.slug)}>
                                                    <CardMedia
                                                        className={classes.media}
                                                        image={res?.node?.images[0]?.image?.url}
                                                        title={res?.node?.name}
                                                    />
                                                </div>
                                            </Grid>
                                            <div className="product_contant">
                                                <CardContent>
                                                    <Typography>
                                                        <Link className="cat_title" onClick={(e) => goToProductDetailPage(res?.node?.slug)}>
                                                            {res?.node?.name}
                                                        </Link>
                                                    </Typography>
                                                    <h3 className="pro_title">
                                                    {res?.node?.category?.name}
                                                    </h3>
                                                    <div className="ratting_warp">
                                                    </div>
                                                    <div className="pro_price"><b>{res?.node?.unitPrice}</b><span>{res?.node?.currency} / {res?.node?.unit?.toLowerCase()}</span></div>
                                                    <div className="tags_outer">
                                                        {res?.node?.tags?.map((tag: any) => (
                                                            <div className="tags"><span>{tag}</span></div>
                                                        ))}
                                                    </div>
                                                    <div className="zkitcard_footer">
                                                        <h5>Seller</h5>
                                                        <div className="flex-row">
                                                            <div className="supplier_infoaria">
                                                                <div className="supplier_pic">
                                                                    <img src={res?.node?.company?.photo?.url} />
                                                                </div>
                                                                <div className="supplier_info">
                                                                    <h5>{res?.node?.company?.representativeName}</h5>
                                                                    <span>{res?.node?.company?.city}</span>
                                                                </div>
                                                            </div>
                                                            <div className="zkitcard_foright">
                                                                <a className="btn-follow" onClick={() => goToCompanyPage(res?.node?.company?.slug +"#companycontact")}>Message</a>
                                                                <a className="btn-viewstore" onClick={(e) => goToCompanyPage(res?.node?.company?.slug)}>View Store &gt; </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </CardContent>
                                            </div>
                                        </div>
                                    </Card>
                                </Grid>
                                {(index + 1) % 3 === 0 &&
                                    <div className="producty_list_border"></div>
                                }
                            </>
                        ))}
                    </Grid>
                    {ProductList.length === 0 &&
                        <div className="product_not_found">
                            No products were found matching your selection
                        </div>
                    }
                    <div className="listing_more_btn">
                        {!ProductInfo.search && data?.category?.products?.pageInfo?.hasNextPage &&
                            <Button className="listing_view_btn" onClick={() => {
                                !ProductInfo.search &&
                                    setAfterId(data?.category?.products?.pageInfo?.endCursor)
                                ProductInfo.search &&
                                    setAfterId2(data?.products?.pageInfo?.endCursor)
                            }} variant="outlined" color="primary"> View More</Button>
                        }
                        {ProductInfo.search && data?.products?.pageInfo?.hasNextPage &&
                            <Button className="listing_view_btn" onClick={() => {
                                !ProductInfo.search &&
                                    setAfterId(data?.category?.products?.pageInfo?.endCursor)
                                ProductInfo.search &&
                                    setAfterId2(data?.products?.pageInfo?.endCursor)
                            }} variant="outlined" color="primary"> View More</Button>
                        }
                    </div>
                </div>
            </div>
            <ShouldBuy name={ProductInfo.search?ProductInfo.search:ProductInfo.id}/>
        </Container>
    );
}





