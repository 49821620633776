import { RoutesUrls } from "../utils/routeUrls";

export const NavLinks = [
    {
        name: 'Home',
        path: RoutesUrls.HOME,
    },
    {
        name: 'Companies',
        path: RoutesUrls.COMPANIES,
    },
    {
        name: 'Products',
        path: RoutesUrls.PRODUCTS,
    },
    {
        name: 'Contact',
        path: RoutesUrls.CONTACT,
    },

]