import React from 'react';
const PageNOtFound = () => {
  document.title="404 Not Found";
  window.scrollTo(0, 0)
  return (
    <>
        <div className="page_not_found">
            <p>404 Page not found</p>
        </div>
    </>
  )
}
export default PageNOtFound;