import React, { useState, useEffect } from 'react';
interface CategoryId {
    name: string;
}
const ShouldBuy: React.FC<CategoryId> = ({
    name
}) => {
    const [show, setShow] = useState(false)
    const [Whatshow, setWhatShow] = useState(0)
    const [oldname, setOldname] = useState('')
    const namedata = ['lavender', 'apricot', 'tshirt', 't-shirt', 't shirt', 't_shirt', 'turkish coffee', 'turkish-coffee', 'turkish_coffee', 'turkishcoffee', 'coffee', 'coffees'];
    oldname != name && setOldname(name)
    useEffect(() => {
        for (var i = 0; i < namedata.length; i++) {
            if (name.toLowerCase().search(namedata[i]) >= 0) {
                if (namedata[i] === "lavender") {
                    setWhatShow(1)
                } else if (namedata[i] === "apricot") {
                    setWhatShow(2)
                } else if (namedata[i] === "tshirt" || namedata[i] === "t-shirt" || namedata[i] === "t_shirt" || namedata[i] === "t shirt") {
                    setWhatShow(3)
                } else if (namedata[i] === "turkish coffee" || namedata[i] === "turkish-coffee" || namedata[i] === "turkish_coffee" || namedata[i] === "turkishcoffee" || namedata[i] === "coffee" || namedata[i] === "coffees") {
                    setWhatShow(4)
                }
                setShow(true);
                break;
            } else {
                setShow(false);
            }
        }
    }, [oldname])
    return (
        <>
            {show &&
                <div className="products_maching">
                    <div className="container">
                        {Whatshow === 3 ?
                            <div className="row bulk_buy">
                                <div className="col-md-6">
                                    <h1>WHY & HOW</h1>
                                    <div className="contant_oi">
                                        <h2>
                                            Why Buy Bulk T-Shirts Online
                                        </h2>
                                        <p>
                                            The increasing popularity of e-commerce and the fact that it has become an income-generating sector has also increased the number of people who want to do e-commerce. Considering that the clothing industry is a never-ending industry, it is possible to say that the number of people who want to operate in this area is high. It is always more advantageous to do wholesale shopping online, both in terms of price and not dealing with the transportation of the products. That is true for almost every product, not just t-shirts. Buying wholesale products top products to sell online is one of the most basic conveniences we encounter today.
                                        </p>
                                    </div>
                                    <div className="contant_oi">
                                        <h2>
                                            Bulk Wholesale T-shirt
                                        </h2>
                                        <p>
                                            Wholesale t-shirt models appear in hundreds of different types in daily life. T-shirts are among the top products to sell online, and these are also sold wholesale and used for promotional purposes. Companies print their logos and designs on cotton t-shirts and offer them for sale. Koytola is a product sourcing platform / marketplace established to help you buy and sell wholesale printed t-shirts. You can use koytola.com for all your wholesale printed t-shirt needs.
                                        </p>
                                    </div>
                                    <div className="contant_oi">
                                        <h2>
                                            High-Quality T-Shirts
                                        </h2>
                                        <p>
                                            T-shirts are one of the most preferred and used clothes in our daily life. These clothes, which have various models for all ages and styles, offer you many alternatives with their different designs. T-shirt models, which have a wide field of use in all seasons, are indispensable clothes, especially in the summer season. The existence of variations according to the models and fabrics of these clothing, which is one of the savior parts of daily life produced by various brands, brings along many features that should be considered when purchasing. One of the most significant points to consider when buying a t-shirt is whether the fabric is suitable for the season. Cotton products, which are generally preferred in the summer months and come to the fore with their comfort, are often fancied with their sweat-proof and iron-free structure. In addition, cotton products, which can be used easily in cold seasons, find a wide area of use thanks to their warmth and comfort. For this reason, it is essential to pay attention to the material from which the product is produced when choosing, and the longevity of the fabric is a substantial factor in the models produced as a mixture of polyester and cotton.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="contant_oi">
                                        <h2>
                                            What is Considered a Bulk Order T-shirt?
                                        </h2>
                                        <p>
                                            Dozen pieces or mutliples of 10 units are considered bulk order. T-shirts, sweatshirts, hoodies, hats, berets, cloth bags are products sold on the internet. Customers who want to make wholesale purchases can find the approximate costs, select the quality and features they want, find them with the wholesale price finder on this page, and get in touch with suppliers and manufacturers.
                                        </p>
                                    </div>
                                    <div className="contant_oi">
                                        <h2>
                                            Wholesale T-shirts Online Suppliers
                                        </h2>
                                        <p>
                                            Wholesale T-shirts online suppliers present their products through Koytola. You can work with many product suppliers on our website. Many suppliers present their lines here for online wholesale buyers.
                                        </p>
                                    </div>
                                    <div className="contant_oi">
                                        <h2>
                                            How To Buy Wholesale T-Shirts Online
                                        </h2>
                                        <p>
                                            Many items are available for sale on Koytola platform.You can purchase quality products at affordable prices by making agreements with product suppliers. If you don’t see the item you want, just contact us. We will direct the right suppliers to you.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            :
                            Whatshow === 2 ?
                                <div className="row bulk_buy">
                                    <div className="col-md-6">
                                        <h1>WHY & HOW</h1>
                                        <div className="contant_oi">
                                            <h2>
                                                How to Store Dried Turkish Apricots
                                            </h2>
                                            <p>
                                                Each product has certain storage conditions, and this also applies to the apricots you buy in bulk from Turkey. After buying bulk turkish apricots, storage conditions are very important. If a few points are not taken into consideration for the bulk apricots we want to store, the collected apricots may not preserve the freshness and flavor of the first day. These points are temperature, humidity, sunlight, and foreign odors. The concept of temperature is a point we pay attention to when storing many products. When we buying bulk turkish apricots, these apricots can stay at normal room temperature for a while. However, it will be healthier to keep it in the refrigerator for longer storage. Because apricots can harden, darken in color and lose their nutritional value. Generally, sealed bags can be stored for more than about 1 month at room temperature and up to 6 months in the refrigerator. You should avoid humid environments when storing apricots. Excessive humidity in the environment is not suitable for storing apricots. So, make sure the area where you store it is a ventilated environment. Another important factor is sunlight. You should not expose your stored apricots to direct sunlight. The reason for this is that the quality of apricots exposed to sunlight is adversely affected. Sunlight allows the dried apricots to warm up and dry even more. Staying in a humid environment while being exposed to sunlight may cause the formation of microorganisms. No matter how much you pay attention to these points, if you do not pay attention to foreign smells, the quality of your apricots will not be the same as before. Foreign odors can ruin the charming smell of apricot, negatively affect its quality. To avoid this, avoid storing apricots near foreign and unpleasant odors such as chemicals, exhaust.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="contant_oi">
                                            <h2>
                                                Health and Nutrition Benefits of Apricots
                                            </h2>
                                            <p>
                                                The benefits of apricot are quite high thanks to the flavonoids, vitamins, and potassium it contains. Flavonoids contained in apricots, the inflammation in the body may decrease, and thanks to the flavonoids, your veins become stronger and protected. Thanks to the potassium it contains, your nervous and muscular system is regulated, your blood pressure and heart health are protected. The skin protection feature of apricot is a well-known fact, especially by women. Thanks to the vitamins C and E and other antioxidants it contains, it beautifies and strengthens the skin layer. In addition, apricots have a high amount of water, so if you want to moisturize your skin, you can eat apricot. The eyes are one of the most important organs in our body. We can say that the organ that needs the most care and attention is our eyes. Apricot improves eye health thanks to the carotene it contains and vitamins A and E. You want to support your retina, lens health, and overall vision, you can consume apricot. Everybody knows that the nutrients in apricot reduce the risk of cataracts. The best friend of the digestive system is undoubtedly the apricot. Apricots are a portion of food with plenty of fiber while helping your digestive system. Thanks to its fibrous structure, it plays an active role in helping your digestive system retain enough water, the development of beneficial bacteria, and determining the level of healthy intestinal bacteria.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                :
                                Whatshow === 4 ?
                                    <div className="row bulk_buy">
                                        <div className="col-md-6">
                                            <h1>WHY & HOW</h1>
                                            <div className="contant_oi">
                                                <h2>
                                                    Why is Turkish coffee so special
                                                </h2>
                                                <p>
                                                    According to Turkish coffee manufacturers, Turkish coffee is a special kind of coffee, not only for those in Turkey but also for the whole world. Whether it is foam or taste, it is loved and drunk all over the world. One of the reasons for this is that Turks have special methods and containers that enable them to brew high-quality coffee. When you go to a cafe, they can prepare your Turkish coffee in different ways. Note that the way it is prepared affects the taste. The most common type of Turkish coffee is the one that is prepared on the stove over the fire, and another method is coffee in the sand. The fire brings the sand to a high temperature, and they place coffees in the sand container. They serve Turkish coffee in the coffee pots when it is ready. Since coffee in the sand is seen as a special way of serving many people, it attracts people's attention, and its authentic taste makes people feel that it is a special coffee. Although it is an intense coffee variety, it has a rich caramel flavor.
                                                </p>
                                            </div>
                                            <div className="contant_oi">
                                                <h2>
                                                    Why do they call it Turkish coffee?
                                                </h2>
                                                <p>
                                                    When it comes to Turkish coffee, a cultural feast comes alive in our eyes with its rituals, traditions, presentation, and unique taste. Turkish coffee takes place in every part of the life of Turks. While having fun, there are plenty of examples such as girl begging ceremonies. Turkish coffee manufacturers thinks that there is a general misconception about the name of Turkish coffee. It is not the cultivation of coffee in Turkey that gives Turkish coffee its name, but the way it is cooked and presented, which is unique to Turkey. We can say that Turkish coffee is a historical richness that has been carried from centuries to the present. In ancient times, coffee beans were brewed and prepared and consumed differently from today's preparation. In the 1500s, when the beans began to be roasted and ground in Arabia, coffee took the form we consume today. The geographies where coffee was grown and drunk in this new form were within the borders of the Ottoman Empire. That's why the Western world was learning about the coffee culture in the East from the Ottomans. This delicious coffee made and consumed in the Ottoman Empire has been consumed since then.
                                                </p>
                                            </div>
                                            <div className="contant_oi">
                                                <h2>
                                                    Potential Benefits of Turkish Coffee

                                                </h2>
                                                <p>
                                                    Consuming Turkish coffee is beneficial in many ways. For example, we know that Turkish coffee balances cholesterol in the blood. According to another saying, we know that it increases the effects of painkillers so that the pain goes away faster, and the effect of Turkish coffee, which has been popular recently, on the skin is fascinating. When you massage your skin with Turkish coffee, you will see that your skin softens and is good for your skin problems. One of the most common benefits is that prevents most heart diseases and has a great effect on the digestive system. The biggest benefit of the digestive system is that it balances the conditions in the intestines, and it is beneficial against diseases such as cancer due to its antioxidant content. We know that there is a decrease in pain after Turkish coffee is consumed in bone pain and headaches.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="contant_oi">
                                                <h2>
                                                    The Difference between Turkish Coffee and Others
                                                </h2>
                                                <p>
                                                    There are many different types of coffee in the world. We can count a lot of differences between these types of coffee to the way they are made. However, no other coffee can match the authenticity of Turkish coffee. Let's take a look at the differences in Turkish coffee, which attracts attention all over the world. While we made American coffee and many other coffees by methods such as dilution, Turkish coffee is never filtered. It is still on the grounds when drinking, which intensifies the taste. In the same way, we brew American coffee with a filter, while Turkish coffee is slowly boiled in a hot spring.
                                                </p>
                                            </div>
                                            <div className="contant_oi">
                                                <h2>
                                                    How to store Turkish Coffee
                                                </h2>
                                                <p>
                                                    When we all buy Turkish coffee, we want it to stay fresh for as long as possible and to preserve its fascinating taste and smell. Turkish coffee manufacturers store Turkish coffee using different methods. When you buy coffee from a Turkish coffee manufacturer, there are a few points you should pay attention to to preserve the freshness of this Turkish coffee. While storing Turkish coffee, it is necessary to pay attention to humidity, light, and temperature. The oils in the beans of Turkish coffee, which are stored in a place where the temperature is high, begin to oxidize. Because of this oxidation, your coffee will taste bitter. At the same time, storing Turkish coffee in the refrigerator harms Turkish coffee. This is due to the damage that moisture can cause to coffee beans. For this reason, it would be best to store Turkish coffee in a place out of direct light, on dry ground, and at average temperatures. Real Turkish coffee does not expire, but if it is not stored properly, its taste may change. Therefore, the best option is to buy as much Turkish coffee as you can finish in a short time and buy a new one as you consume it. In this way, you can always enjoy the quality, smell, and taste of your coffee.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h2>WHY & HOW
                                                <span>you should buy T-shirts from Turkey</span>
                                            </h2>
                                            <p>Turkey is a well-known textile manufacturing gem. From the most well-known luxury brands to fast-fashion brands are manufacturing their products in Turkey. You can find amazing patterns, designs, top quality materials here in Turkey.  If you are planning to create your own t-shirt line, just connect one of the suppliers on Koytola and ask them to show their product and materials catalogs. You will be impressed in seconds. </p>
                                        </div>
                                        <div className="col-md-6">
                                            <p className="second">Turkey is a well-known textile manufacturing gem. From the most well-known luxury brands to fast-fashion brands are manufacturing their products in Turkey. You can find amazing patterns, designs, top quality materials here in Turkey.  If you are planning to create your own t-shirt line, just connect one of the suppliers on Koytola and ask them to show their product and materials catalogs. You will be impressed in seconds. </p>
                                        </div>
                                    </div>
                        }
                    </div>
                </div>
            }
        </>
    );
};
ShouldBuy.displayName = "ShouldBuy";
export default ShouldBuy;