import {  TextField,  CircularProgress } from '@material-ui/core';
import React, {  useState } from 'react';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { gql, useQuery } from '@apollo/client';
import { shipdata } from './Logistics';
const ShipOrderdata = gql`
query(
    $first:Int,
    $country:String,
    $weight:Float
){site {
    countries {
      country
      code
    }
  }
    shipping(first:$first, country: $country, weight: $weight){
      edges{
        node{
          id
          company
          category
          countries{
            code
            country
          }
          price
          deliveryDays
          minWeight
          unit
          currency
          maxWeight
        }
      }
    }
  }
    `;
const ShipOrder = () => {
    document.title="Cargo Result";
    window.scrollTo(0, 0)
    const [Economy, setEconomy] = useState(false);
    const [Express, setExpress] = useState(false);
    const [shipto, setShipto] = useState('');
    const [shipkg, setShipkg] = useState(shipdata.shipkg);
    const [formdata, setFormdata] = useState({
        shipfrom: 'Turkey',
        shipto: shipdata.shipto,
        shipcountry: '',
        shipkg: shipdata.shipkg,
    })
    const { loading, data } = useQuery(ShipOrderdata, {
        variables: {
            first: 100,
            country: shipto ? shipto : shipdata.shipto,
            weight: shipkg ? shipkg : shipdata.shipkg
        }
    });
    const [formerror, setFormerror] = useState({
        shipto: '',
        shipkg: '',
    })
    const click = () => {
        setFormerror({
            ...formerror,
            shipto: formdata.shipto ? "" : "This field required",
            shipkg: formdata.shipkg ? (formdata.shipkg > 300 ? "Maximum 300 kg allowed" : (!(formdata.shipkg > 0) ? "Greater than 0 kg allowed" : "")) : formdata.shipkg === 0 ? "Greater than 0 kg allowed" : "This field required",
        })
        if (formdata.shipto && formdata.shipkg && !(formdata.shipkg > 300) && formdata.shipkg > 0) {
            setShipkg(formdata.shipkg)
            setShipto(formdata.shipto)
            setEconomy(false)
            setExpress(false)
        }
    }
    return (
        <>
            {loading && <div className="loading_seccestion"><CircularProgress /></div>}
            {data &&
                <div className="hesapla_outer">
                    <div className="container">
                        <div className="hesapla_heading">
                            <h2> Kargo Hesapla <span>Get Shipping Rates</span></h2>
                        </div>
                        <div className="hesapla_top_bar">
                            <div className="text">
                                <h4> Başlangıç <span>Starting from</span></h4>
                            </div>
                            <div className="h_buttons">
                                <button>{formdata.shipfrom}</button> <span>TO</span>
                                <div className="countryouter">
                                    <Autocomplete
                                        fullWidth
                                        className="contry_selct"
                                        freeSolo={true}
                                        options={data?.site?.countries ? data?.site?.countries : []}
                                        getOptionLabel={(option: any) => option?.country}
                                        defaultValue={data?.site?.countries.find(v => v.code === formdata.shipto)}
                                        onChange={(event: object, values: any, reason: string) => {
                                            if (values?.country) {
                                                setFormdata({
                                                    ...formdata,
                                                    shipto: values?.code,
                                                    shipcountry: values?.country
                                                })
                                            } else {
                                                setFormdata({
                                                    ...formdata,
                                                    shipto: '',
                                                    shipcountry: ''
                                                })
                                            }
                                        }}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                error={formerror.shipto ? true : false}
                                                variant="outlined"
                                                color="primary"
                                                name="shipto"
                                                placeholder="Nereye / Drop Off"
                                            />
                                        }
                                    />
                                    {formerror.shipto && <p>{formerror.shipto}</p>}
                                </div>
                                <span>KG</span>
                                <div className="kgouter">
                                    <input
                                        type="number"
                                        className="kilogram_int"
                                        value={formdata.shipkg}
                                        onChange={(e: any) => {
                                            setFormdata({
                                                ...formdata,
                                                shipkg: e.target.value.toString().split(".").map((el, i) => i ? el.split("").slice(0, 2).join("") : el).join(".")
                                            })
                                        }}
                                    />
                                    {formerror.shipkg && <p>{formerror.shipkg}</p>}
                                </div>
                                <button onClick={click} className="change_btn">
                                    degiştir <br />
                                    change
                                </button>
                            </div>
                        </div>
                        {Economy && <h4 className="hed_ecom">economy</h4>}
                        {data?.shipping?.edges?.map((res, index) => (<>{res?.node?.category === "ECONOMY" && <>
                            {!Economy && setEconomy(true)}
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="economy_left_box">
                                        <div className="box_inner">
                                            <p><span>{res?.node?.currency.toLowerCase()}</span>Total </p>
                                            <h3>{res?.node?.unit.toLowerCase() === "per kg" ? (res?.node?.price * shipkg).toFixed(2) : res?.node?.price.toFixed(2)}</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="economy_right_box">
                                        <div className="img_box_inner">
                                            {res?.node?.company.toLowerCase() === "horoz logistics" &&
                                                <img src={require('../../../assets/images/logo/shiporder1.png')} />
                                            }
                                            {res?.node?.company.toLowerCase() === "kolay kargom" &&
                                                <img src={require('../../../assets/images/logo/shiporder2.png')} />
                                            }
                                            {res?.node?.company.toLowerCase() === "ship entegra" &&
                                                <img src={require('../../../assets/images/logo/shiporder3.png')} />
                                            }
                                            <p>{(res?.node?.deliveryDays && res?.node?.deliveryDays.search("Gün") < 0) ? res?.node?.deliveryDays + " Gün" : res?.node?.deliveryDays}</p>
                                            <button>
                                                {res?.node?.company.toLowerCase() === "horoz logistics" && <a href="https://www.horoz.com.tr/tr/Home" target="_blank"> Devam <span>Select</span></a>
                                                }
                                                {res?.node?.company.toLowerCase() === "ship entegra" && <a href="https://www.shipentegra.com/" target="_blank"> Devam <span>Select</span></a>
                                                }
                                                {res?.node?.company.toLowerCase() === "kolay kargom" && <a href="https://www.kargomkolay.com/" target="_blank"> Devam <span>Select</span></a>
                                                }

                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>}
                        </>))}
                        {Express && <h4 className="hed_ecom exps">express</h4>}
                        {data?.shipping?.edges?.map((res, index) => (<>{res?.node?.category === "EXPRESS" && <>
                            {!Express && setExpress(true)}
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="economy_left_box">
                                        <div className="box_inner yellow">
                                            <p><span>{res?.node?.currency.toLowerCase()}</span>Total </p>
                                            <h3>{res?.node?.unit.toLowerCase() === "per kg" ? (res?.node?.price * shipkg).toFixed(2) : res?.node?.price.toFixed(2)}</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="economy_right_box">
                                        <div className="img_box_inner">
                                            {res?.node?.company.toLowerCase() === "horoz logistics" &&
                                                <img src={require('../../../assets/images/logo/shiporder1.png')} />
                                            }
                                            {res?.node?.company.toLowerCase() === "kolay kargom" &&
                                                <img src={require('../../../assets/images/logo/shiporder2.png')} />
                                            }
                                            {res?.node?.company.toLowerCase() === "ship entegra" &&
                                                <img src={require('../../../assets/images/logo/shiporder3.png')} />
                                            }
                                            <p>{(res?.node?.deliveryDays && res?.node?.deliveryDays.search("Gün") < 0) ? res?.node?.deliveryDays + " Gün" : res?.node?.deliveryDays}</p>
                                            <button>
                                                {res?.node?.company.toLowerCase() === "horoz logistics" && <a href="https://www.horoz.com.tr/tr/Home" target="_blank"> Devam <span>Select</span></a>
                                                }
                                                {res?.node?.company.toLowerCase() === "ship entegra" && <a href="https://www.shipentegra.com/" target="_blank"> Devam <span>Select</span></a>
                                                }
                                                {res?.node?.company.toLowerCase() === "kolay kargom" && <a href="https://www.kargomkolay.com/" target="_blank"> Devam <span>Select</span></a>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>} </>))}
                    </div>
                </div>
            }
            <div className="container">
            <div className="row krgo_inner_cnt top_space">
            <h3>En ucuz kargo nasil bulunur ?</h3>
                <div className="col-md-6">
                    <p>Turkey is a well-known textile manufacturing gem. From the most well-known luxury brands to fast-fashion brands are manufacturing their products in Turkey. You can find amazing patterns, designs, top quality materials here in Turkey.  If you are planning to create your own t-shirt line, just connect one of the suppliers on Koytola and ask them to show their product and materials catalogs. You will be impressed in seconds. </p>
                </div>
                <div className="col-md-6">
                    <p>Turkey is a well-known textile manufacturing gem. From the most well-known luxury brands to fast-fashion brands are manufacturing their products in Turkey. You can find amazing patterns, designs, top quality materials here in Turkey.  If you are planning to create your own t-shirt line, just connect one of the suppliers on Koytola and ask them to show their product and materials catalogs. You will be impressed in seconds. </p>
                </div>
            </div>
            </div>
        </>
    )
}
export default ShipOrder;
